import axios from 'axios';
import { format } from 'date-fns';
import React from 'react';
import AxiosPromotion from '.';
import config from '../../config';
import { getFacadeAccessToken, getAccessToken } from './auth.service';
import { commonConstant } from '../../common';

export const RULE_BASED = 'RULE_BASED';
export const SOP_BEYOND = 'BEYOND';
export const SOP_BASIC = 'BASIC';

const DELIVERYMODE = {
  HOME: 'HOMEDELIVERY',
};

export const PAYMENT_OPTION = {
  PIF: 'PIF',
  ZIPP: 'ZIPP',
  ZIPP_ABO: 'ZIPP_ABO',
};

export const INSTALLMENT_TYPE = {
  TRUE: 'TRUE',
  FALSE: 'FALSE',
};

export const SMART_REPORT_TASK = {
  VIEW: 'view',
  DOWNLOAD: 'download',
};

export const STOCK_STATUS = {
  inStock: 'inStock',
  backOrder: 'backOrder',
  outOfStock: 'outOfStock',
  notYetAvailable: 'notYetAvailable',
  noLongerAvailable: 'noLongerAvailable',
  temporaryNotAvailable: 'temporaryNotAvailable',
  notAvailableAtWarehouse: 'notAvailableAtWarehouse',
};

export const STOCK_STATUS_MESSAGE = {
  outOfStock: 'สินค้าหมด',
  backOrder: 'สินค้าค้างจ่าย',
  notYetAvailable: 'สินค้ามาใหม่',
  noLongerAvailable: 'เลิกจำหน่าย',
  temporaryNotAvailable: 'หมดชั่วคราว',
  notAvailableAtWarehouse: 'ไม่มีสินค้าที่สาขานี',
};

export const message = {
  error: {
    cart: 'ไม่พบข้อมูลตะกร้าสินค้า',
    somethingWrong: 'ขออภัยเกิดข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง',
    alreadyAddress: 'คุณมีข้อมูลที่อยู่นี้อยู่แล้ว กรุณากรอกข้อมูลใหม่อีกครั้ง',
    phoneNotFound: 'กรุณากรอกเบอร์โทรศัพท์',
    order: 'ขออภัยสินค้าภายในตะกร้าไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง',
    notFound: 'ขออภัยคะ ไม่พบข้อมูลนี้',
  },
};

export const isCartABOMemberType = (accountType) => {
  const isABOMember =
    accountType?.toLowerCase() === 'abo' ||
    accountType?.toLowerCase() === 'member';
  return isABOMember;
};

export const isCartABOType = (accountType) => {
  return accountType?.toLowerCase() === 'abo';
};

export const isCartMemberType = (accountType) => {
  return accountType?.toLowerCase() === 'member';
};

export const isNotValidSessionABOMember = (facadeToken, customer) => {
  return (
    !facadeToken ||
    facadeToken.account.accountType.toLowerCase() !==
      customer.type.toLowerCase()
  );
};

export const getPriceFormat = (priceString) => {
  return parseInt(parseFloat(priceString).toFixed()).toLocaleString();
};

export const getDateFormat = (date) => {
  return format(new Date(date), 'dd/MM/yy');
};

export const getTimeFormat = (date) => {
  return format(new Date(date), 'HH:mm');
};

export const getPhoneFormat = (phone) => {
  const phone1 = phone.substring(0, 3).concat('-');
  const phone2 = phone.substring(3, 6).concat('-');
  const phone3 = phone.substring(6, 12);
  return phone1 + phone2 + phone3;
};

export const getFacadeToken = async () => {
  const facadeToken = localStorage.getItem('facadeToken');
  return facadeToken && facadeToken !== 'undefined'
    ? JSON.parse(facadeToken)
    : null;
};

export const setFacadeToken = async (accessToken) => {
  return localStorage.setItem('facadeToken', JSON.stringify(accessToken));
};

export const clearFacadeToken = () => {
  localStorage.removeItem('facadeToken');
};

export const setLocalStorage = (tokenName, value) => {
  return localStorage.setItem(tokenName, value);
};

export const getLocalStorage = async (tokenName) => {
  return localStorage.getItem(tokenName);
};

export const removeLocalStorage = async (tokenName) => {
  return localStorage.removeItem(tokenName);
};

export const convertBlobToBase64 = (blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
};

export const displayStockLevelStatus = (stock) => {
  if (stock.stockLevelStatus === STOCK_STATUS.inStock) {
    return <></>;
  }
  return (
    <div className={stock.stockLevelStatus}>{`${
      STOCK_STATUS_MESSAGE[stock.stockLevelStatus]
    } ${stock.deliveryDate ? `: ${stock.deliveryDate}` : ''}`}</div>
  );
};

export const getCustomerIdFormFacade = async () => {
  const facadeToken = await getFacadeToken();
  if (!facadeToken) {
    const accessToken = await getFacadeAccessToken();
    setFacadeToken(accessToken);
    if (accessToken) {
      return accessToken.account.primaryParty.uid;
    }
  }
  if (facadeToken) {
    return facadeToken.account.primaryParty.uid;
  }
};

export const getPromotionList = async (id) => {
  const response = await axios.post(
    `${config.API_URL}/promotion/promotionList`,
    {
      userId: id,
      currentPage: '1',
    },
  );
  return response.data.data;
};

export const getPromotionById = async (id) => {
  const response = await AxiosPromotion.get(
    `${config.API_URL}/promotion/detail/${id}`,
  );
  return response.data.data;
};

export const getPublicPromotionById = async (id) => {
  const response = await axios.get(
    `${config.API_URL}/promotion/detail/${id}/public`,
  );
  return response.data.data;
};

export const getCart = async (fields) => {
  const response = await AxiosPromotion.get(
    `${config.API_URL}/promotion/cart`,
    {
      params: {
        fields,
      },
    },
  );
  return response.data.data;
};

export const createCartPublic = async () => {
  const response = await axios.get(`${config.API_URL}/promotion/cart/public`,
    { headers: { 'Authorization': commonConstant.dummyToken } }
  );
  return response.data.data;
};

export const getCartPublic = async (guid) => {
  const response = await axios.get(
    `${config.API_URL}/promotion/cart/public/${guid}`,
    { headers: { 'Authorization': commonConstant.dummyToken } }
  );
  return response.data.data;
};

export const getCartInstallment = async (cartId) => {
  const response = await AxiosPromotion.post(
    `${config.API_URL}/promotion/cart/installment`,
    { cartId },
  );
  return response.data.data;
};

export const addProductsToCart = async (cartId, products) => {
  const orderEntries = [];
  for (const product of products) {
    if (product.quantity !== 0 && product.kitEntries.length !== 0) {
      const data = {
        product: {
          id: product.id,
        },
        quantity: product.quantity,
        alias: product.id,
      };
      orderEntries.push(data);
      for (const kitEntry of product.kitEntries) {
        if (kitEntry.product.baseOptions.length !== 0) {
          const baseOptions = kitEntry.product.baseOptions[0];
          for (const option of baseOptions.options) {
            if (option.quantity !== 0) {
              const data = {
                product: {
                  id: option.id,
                },
                quantity: option.quantity,
                kitEntryCode: kitEntry.kitEntryCode,
              };
              orderEntries.push(data);
            }
          }
        } else {
          const data = {
            product: {
              id: kitEntry.product.id,
            },
            quantity: kitEntry.configuredQty,
            kitEntryCode: kitEntry.kitEntryCode,
          };
          orderEntries.push(data);
        }
      }
    } else {
      if (product.quantity !== 0) {
        const data = {
          product: {
            id: product.id,
          },
          quantity: product.quantity,
          // alias: product.id,
          // kitEntryCode: null,
          // isSelect: false,
          // hasSopEntries: false,
          // isGiveAway: false,
        };
        orderEntries.push(data);
      }
    }
  }

  if (orderEntries.length === 0) {
    return { error: [{ th_message: 'กรุณาเลือกสินค้าอย่างน้อย 1 ชิ้น' }] };
  }

  const response = await AxiosPromotion.post(
    `${config.API_URL}/promotion/cart/item`,
    { cartId, orderEntries },
  );
  return response.data;
};

export const addProductsToCartPublic = async (cartId, products, guid) => {
  const orderEntries = [];
  for (const product of products) {
    if (product.quantity !== 0 && product.kitEntries.length !== 0) {
      const data = {
        product: {
          id: product.id,
        },
        quantity: product.quantity,
        alias: product.id,
      };
      orderEntries.push(data);
      for (const kitEntry of product.kitEntries) {
        if (kitEntry.product.baseOptions.length !== 0) {
          const baseOptions = kitEntry.product.baseOptions[0];
          for (const option of baseOptions.options) {
            if (option.quantity !== 0) {
              const data = {
                product: {
                  id: option.id,
                },
                quantity: option.quantity,
                kitEntryCode: kitEntry.kitEntryCode,
              };
              orderEntries.push(data);
            }
          }
        } else {
          const data = {
            product: {
              id: kitEntry.product.id,
            },
            quantity: kitEntry.configuredQty,
            kitEntryCode: kitEntry.kitEntryCode,
          };
          orderEntries.push(data);
        }
      }
    } else {
      if (product.quantity !== 0) {
        const data = {
          product: {
            id: product.id,
          },
          quantity: product.quantity,
        };
        orderEntries.push(data);
      }
    }
  }

  if (orderEntries.length === 0) {
    return { error: [{ th_message: 'กรุณาเลือกสินค้าอย่างน้อย 1 ชิ้น' }] };
  }

  const response = await AxiosPromotion.post(
    `${config.API_URL}/promotion/cart/item/public/${guid}`,
    { cartId, orderEntries },
  );
  return response.data;
};

export const addPremiumItemToCart = async (
  cartId,
  premiumItem,
  elligibleTargetQty,
) => {
  console.log('elligibleTargetQty', elligibleTargetQty);
  const response = await AxiosPromotion.post(
    `${config.API_URL}/promotion/cart/item`,
    {
      cartId,
      orderEntries: {
        product: {
          id: premiumItem.id,
        },
        quantity: elligibleTargetQty,
      },
    },
  );
  return response.data;
};

export const createCartInstallment = async (uid) => {
  const body = {
    uid,
    buyNow: true,
  };
  const response = await AxiosPromotion.post(
    `${config.API_URL}/promotion/cart`,
    body,
  );
  return response.data.data;
};

export const addProductsInstallmentToCart = async (
  cartId,
  products,
  paymentOption,
) => {
  const orderEntries = [];
  for (const product of products) {
    if (product.quantity !== 0 && product.kitEntries.length !== 0) {
      const payment = product.paymentOptions.find(
        (value) => value.title === paymentOption.title,
      );
      const data = {
        product: {
          id: product.id,
          alias: payment.alias,
        },
        quantity: product.quantity,
        alias: product.id,
      };
      orderEntries.push(data);
      for (const kitEntry of product.kitEntries) {
        if (kitEntry.product.baseOptions.length !== 0) {
          const baseOptions = kitEntry.product.baseOptions[0];
          for (const option of baseOptions.options) {
            if (option.quantity !== 0) {
              const data = {
                product: {
                  id: option.id,
                },
                quantity: option.quantity,
                kitEntryCode: kitEntry.kitEntryCode,
              };
              orderEntries.push(data);
            }
          }
        } else {
          const data = {
            product: {
              id: kitEntry.product.id,
            },
            quantity: kitEntry.configuredQty,
            kitEntryCode: kitEntry.kitEntryCode,
          };
          orderEntries.push(data);
        }
      }
    } else {
      if (product.quantity !== 0) {
        const productSelectedPaymentOption = product.paymentOptions.find(
          (prodPayOption) => {
            return (
              prodPayOption.paymentType &&
              prodPayOption.paymentType.key &&
              prodPayOption.paymentType.key === paymentOption.paymentType.key
            );
          },
        );

        const data = {
          product: {
            id: product.id,
            alias:
              productSelectedPaymentOption && productSelectedPaymentOption.alias
                ? productSelectedPaymentOption.alias
                : paymentOption.alias,
          },
          quantity: product.quantity,
        };
        orderEntries.push(data);
      }
    }
  }

  if (orderEntries.length === 0) {
    return { error: [{ th_message: 'กรุณาเลือกสินค้าอย่างน้อย 1 ชิ้น' }] };
  }

  const response = await AxiosPromotion.post(
    `${config.API_URL}/promotion/cart/item`,
    { cartId, orderEntries },
  );
  return response.data;
};

export const updateCart = async (cartId, itemId, quantity) => {
  const response = await AxiosPromotion.put(
    `${config.API_URL}/promotion/cart/item`,
    { cartId, itemId, quantity },
  );
  return response.data.data;
};

export const updateCartPublic = async (cartId, itemId, quantity, guid) => {
  const response = await AxiosPromotion.put(
    `${config.API_URL}/promotion/cart/item/public/${guid}`,
    { cartId, itemId, quantity },
  );
  return response.data.data;
};

export const deleteCartItems = async (
  cartId,
  itemIds,
  isInstallment,
  userId,
  product,
) => {
  const response = await AxiosPromotion.put(
    `${config.API_URL}/promotion/cart/item/delete`,
    { cartId, itemIds, isInstallment, userId, product },
  );
  return response.data.data;
};

export const deleteCartItemsPublic = async (
  cartId,
  itemIds,
  isInstallment,
  userId,
  product,
  guid,
) => {
  const response = await axios.put(
    `${config.API_URL}/promotion/cart/item/delete/public/${guid}`,
    { cartId, itemIds, isInstallment, userId, product },
    { headers: { 'Authorization': commonConstant.dummyToken } }
  );
  return response.data.data;
};

export const updateDeliveryCart = async (
  userId,
  addressId,
  phone,
  cartId,
  isInstallment,
) => {
  let onlyUpdateCartPhone = false;

  if ((!addressId && phone) || (addressId && phone)) {
    onlyUpdateCartPhone = true;
  }

  const response = await AxiosPromotion.put(
    `${config.API_URL}/promotion/cart/delivery`,
    {
      addressId,
      selectedId: addressId,
      phone,
      deliveryMode: DELIVERYMODE.HOME,
      cartId,
      isInstallment,
      userId,
      onlyUpdateCartPhone,
    },
  );
  return response.data;
};

export const updateDeliveryCartPublic = async (
  userId,
  addressId,
  phone,
  cartId,
  isInstallment,
  address,
  guid,
) => {
  const response = await AxiosPromotion.put(
    `${config.API_URL}/promotion/cart/delivery/public/${guid}`,
    {
      addressId,
      selectedId: addressId,
      phone,
      deliveryMode: DELIVERYMODE.HOME,
      cartId,
      isInstallment,
      userId,
      address,
    },
  );
  return response.data;
};

// export const getAddressList = async (userId) => {
//   const response = await axios.get(
//     `${config.API_URL}/promotion/address?uid=${userId}&currentPage=1&perPage=100`
//   );
//   return response.data
// }

//==================COUPONS====================
export const getCoupons = async () => {
  const customerId = await getCustomerIdFormFacade();
  const response = await AxiosPromotion.get(
    `${config.API_URL}/promotion/me/coupons`,
    { params: { customerId } },
  );
  return response.data.data;
};

export const addCouponToCart = async (code, cartId) => {
  const response = await AxiosPromotion.post(
    `${config.API_URL}/promotion/cart/coupons`,
    { code, cartId },
  );
  return response.data;
};

export const deleteCouponToCart = async (code, cartId) => {
  const response = await AxiosPromotion.delete(
    `${config.API_URL}/promotion/cart/${cartId}/coupons/${code}`,
  );
  return response.data;
};

//==================SMART-REPORT====================

export const fetchSmartReport = async (keyword) => {
  try {
    const facadeToken = await getFacadeAccessToken(); // Fetching token via API and not from localstorage
    const accessToken = await getAccessToken();
    const profile = sessionStorage.getItem("Profile");
    setFacadeToken(facadeToken); // Updated latest token to localstorage
    if (facadeToken && accessToken) {
      const { data } = await axios.get(`${config.API_URL}/smart-report`, {
        headers: {
          'x-req-token': facadeToken?.access_token,
          account: `${facadeToken?.account?.accountId}`,
          customer: `${facadeToken?.account?.primaryParty?.uid}`,
          Authorization: `Bearer ${accessToken}`,
          'x-api-key' : profile.userId
        },
        params: { keyword },
      });
      return data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};
