import { commonConstant } from '../common';
import config from '../config';
import axios from 'axios'

export const addressService = {
    getProvinces,
    getDistricts,
    getSubDistricts,
    getPostalCode,
};

async function getProvinces() {
    const response = await axios.get(`${config.API_URL}/address/provinces`)
    return response.data
}

async function getDistricts({ provinceCode }) {
    const response = await axios.get(`${config.API_URL}/address/districts?provinceCode=${provinceCode}`,
        { headers: { 'Authorization': commonConstant.dummyToken } }
    )
    return response.data
}

async function getSubDistricts({ provinceCode, district }) {
    const response = await axios.get(`${config.API_URL}/address/subdistricts?provinceCode=${provinceCode}&district=${district}`,
        { headers: { 'Authorization': commonConstant.dummyToken } }
    )
    return response.data
}

async function getPostalCode({ provinceCode, district, subDistrict }) {
    const response = await axios.get(`${config.API_URL}/address/postalcode?provinceCode=${provinceCode}&district=${district}&subDistrict=${subDistrict}`,
        { headers: { 'Authorization': commonConstant.dummyToken } }
    )
    return response.data
}