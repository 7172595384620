import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import LoadingOverlay from 'react-loading-overlay';
import { ChallengeDetailSlider, ChallengeNavButton, ChallengeShare, commonConstant, MapGoalCard } from '../../../../common';
import { BADGE_MAPPING, CHALLENGE, CHALLENGE_MAP_DETAIL, CHALLENGE_MAP_DETAIL_MAPPING, CHALLENGE_MAP_PULLUP, CHALLENGE_MILESTONE, CHALLENGE_MILESTONE_PERU, CHALLENGE_UNLOCKED } from '../../../../common/challengeConstants';
import { formatNumberWithCommas, getDataFromSession, isValidDateTime, thaiDateWOTime } from '../../../../utill.func';
import { fetchChallengeTeamDetail, fetchUserDetailsData } from '../../../../actions';
import variables from '../../../../common/commonConstant.scss';
import './ChallengeMapDetail.scss'


const ChallengeMapDetail = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imgOffsetPercent, setImgOffsetPercent] = useState(30);
  const { teamData, userDetail } = useSelector((state) => state.challengeTeamDashboard);
  const params = queryString.parse(location.search);
  const milestone = params[CHALLENGE_MILESTONE] || CHALLENGE_MILESTONE_PERU;
  const allUsersData = teamData?.stepsBadgeData?.[milestone]?.allUsersData;
  const challengeId = params['challengeId'] || teamData?.eventId;
  const eventId = params['eventId'];
  const { partyId, amwayNumber } = getDataFromSession('customer');
  const totalSteps = teamData?.stepsBadgeData?.[milestone]?.currentUserData?.total_steps;
  const totalBadges = teamData?.stepsBadgeData?.[milestone]?.currentUserData?.total_badge;
  const unlock_date = teamData?.stepsBadgeData?.[milestone]?.currentUserData?.receivedAt;

  const handleTabButtonChange = (index, value) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const didMount = () => {
      if (!teamData?.challengePartyIds && !teamData?.stepsBadgeData) {
        getChallengeTeamDashboardData();
      }
    }
    didMount();
  }, []);

  const getChallengeTeamDashboardData = async () => {
    try {
      setLoading(true);
      await dispatch(fetchChallengeTeamDetail({ amwayNumber, partyId, challengeId }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }

  useEffect(() => {
    const didMount = () => {
      if (teamData?.challengePartyIds && userDetail.length == 0) {
        getChallengeTeamDashboardData();
      }
      getChallengeUserProfileData();
    }
    didMount();
  }, [teamData?.challengePartyIds]);

  const getChallengeUserProfileData = async () => {
    if (teamData?.challengePartyIds) {
      await dispatch(fetchUserDetailsData(teamData?.challengePartyIds));
    }
  }

  const onLoadImage = () => setImageLoaded(true);

  const handleUsersTab = () => {
    return (
      <div className='challenge-map-detail-list'>
        {allUsersData?.map((item, index) => isValidDateTime(item?.receivedAt) && (
          <div className='challenge-map-detail-list-users' key={index}>
            <div className='challenge-map-detail-list-users-profile'>
              <img src={item?.pictureUrl} className='profile-circle-img' />
              <div className="dashboard-slider-leader-img">
                {item.isLeader && <>
                  <img className='profile-img' src={'/images/challenge/icons/team-dashboard-circle.svg'} />
                  <div className="btn-icon-crown">
                    <img src="/images/challenge/icons/icon-challenge-crown.svg" />
                  </div>
                </>}
              </div>
            </div>
            <div className='challenge-map-detail-list-users-name'>{item?.userName}</div>
            <div className='challenge-map-detail-list-users-date'>{thaiDateWOTime(item?.receivedAt, 'dd MMM yy')}</div>
          </div>
        ))}
      </div>
    )

  }

  const handleLocationTab = () => {
    const unlockedBadgeDate = isValidDateTime(unlock_date) ? thaiDateWOTime(new Date(unlock_date), 'dd MMM yy') : unlock_date;
    const unlockedBadgeImage = `/images/challenge/badges/steps-${milestone}-badge@5x.png`;

    return (
      <>
        <div className="challenge-map-detail-unlock">
          <MapGoalCard>
            <div className="map-goal-card-left">
              <div className="map-goal-card-left-wrapper">
                <span className="map-goal-card-left-header">{CHALLENGE.teamDashboard.mapLocationTitle}</span>
                <span className="map-goal-card-left-date">{unlockedBadgeDate}</span>
              </div>
              <ChallengeShare imgPath={`${process.env.REACT_APP_REDIRECT_URL}${unlockedBadgeImage}`}
                message={`${CHALLENGE_UNLOCKED.UNLOCK_MESSAGE_DATE_PREFIX} ${unlockedBadgeDate}`} />
            </div>
            <div className="map-goal-card-right">
              <img src="/images/challenge/badges/bg-dark-badge@5x.png" alt="" className="badge-img-bg" />
              <img src={unlockedBadgeImage} />
            </div>
          </MapGoalCard>
        </div>
        {totalBadges !== BADGE_MAPPING.length && <div className="challenge-map-detail-goal">
          <MapGoalCard>
            <div className="map-goal-card-left">
              <div className="map-goal-card-left-title">
                <span>{CHALLENGE.teamDashboard.mapGoalTitle}</span>
                <span className="map-goal-card-left-title-steps">{formatNumberWithCommas(CHALLENGE_MAP_PULLUP.STEPS_INTERVAL - (totalSteps % CHALLENGE_MAP_PULLUP.STEPS_INTERVAL))}</span>
                <span>{CHALLENGE.teamDashboard.mapGoalTitleSteps}</span>
              </div>
              <span>{CHALLENGE.teamDashboard.mapGoalSubtitle}</span>
            </div>
            <div className="map-goal-card-right">
              <img src={`${BADGE_MAPPING?.[totalBadges - 1]?.goalCard}`} />
            </div>
          </MapGoalCard>
        </div>}
      </>
    )

  }
  const handleBackButton = () => {
    const params = `challengeId=${challengeId}&eventId=${eventId}`;
    history.push(`${commonConstant.pathChallengeMap}?${params}`);
  }

  const handleDrag = (contentHeight, clientY) => {
    const totalHeight = window.innerHeight;
    const availableSpacePercent = ((totalHeight - contentHeight) / totalHeight) * 100;
    setImgOffsetPercent(Math.min(100 - availableSpacePercent, 50));
  }

  const SliderContent = useMemo((() => <div className="challenge-map-detail">
    <div className="challenge-map-detail-title">
      {activeIndex == 0 ? `${CHALLENGE_MAP_DETAIL_MAPPING[milestone]?.title}` : CHALLENGE.teamDashboard.mapUsersTab}
    </div>
    <div className="challenge-map-detail-tabs">
      <ChallengeNavButton
        tabList={CHALLENGE_MAP_DETAIL.HOME_TABS_LIST}
        onTabButtonChange={handleTabButtonChange}
      />
    </div>
    {activeIndex == 0 ? handleLocationTab() : handleUsersTab()}
  </div>), [activeIndex, teamData])

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner
        text="Loading"
        styles={{ wrapper: { width: '100%', minHeight: '100%', position: "absolute", pointerEvents: "none" } }}
      />
      <div className="challenge-map-detail-wrapper">
        <div className="challenge-map-main">
          <div className="challenge-map-main-header" onClick={handleBackButton}>
            <img src="/images/challenge/map-detail-nav.svg" alt="" />
          </div>
          {imageLoaded ? <TransformWrapper
            initialScale={1}
            minScale={1}
            maxScale={4}
            disablePadding={true}
            centerOnInit
            centerZoomedOut
          >
            {() => (
              <div className="challenge-map-main-image lg-container" style={{ transform: `translateY(-${imgOffsetPercent}%)` }}>
                <TransformComponent>
                  <img src={CHALLENGE_MAP_DETAIL_MAPPING[milestone].bg_image} className="lg-image zoom-view-lg-image" />
                </TransformComponent>
              </div>
            )}
          </TransformWrapper> : <img src={CHALLENGE_MAP_DETAIL_MAPPING[milestone].bg_image}
            className="lg-image zoom-view-lg-image --no-image" onLoad={onLoadImage} />}
        </div>
        <ChallengeDetailSlider styleObj={{ backgroundColor: variables.challengeWhiteTranslucent }}
          autoHeight
          onDrag={handleDrag}>
          {SliderContent}
        </ChallengeDetailSlider>
      </div>
    </>
  )
}

export default ChallengeMapDetail