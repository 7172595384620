import React, { useEffect, useState } from 'react';
import Numpad from '../hnWNumpadModal/Numpad';
import HnWPicker from '../hnwPicker/HnWPicker';
import HnWNumpadModal from '../hnWNumpadModal/HnWNumpadModal';
import { ERROR_TYPE, HNW_LINE_INPUT, SUCCESS_TYPE } from '../commonConstant';
import variables from '../commonConstant.scss';

export default function HnWLineInput({
  id,
  className,
  name,
  type = 'text',
  value = '',
  onChange,
  customKeypad,
  validationStatus,
  handleKeypadOpen,
  handleScrollBottom
}) {
  const pickerHeader = '';
  const defaultValues = {
    [HNW_LINE_INPUT.TYPES.TEXT]: '',
    [HNW_LINE_INPUT.TYPES.TIME]: {
      hour: '--',
      colon: ':',
      minute: '--',
    },
    [HNW_LINE_INPUT.TYPES.NUMBER]: '-',
  };
  const [pickerValue, setPickerValue] = useState(defaultValues[type]);
  const [numpadValue, setNumpadValue] = useState(defaultValues[type]);

  const [showKeypad, toggleKeypad] = useState(false);

  useEffect(() => {
    if (value && type) {
      switch (type) {
        case HNW_LINE_INPUT.TYPES.TIME:
          if (value.length === 5) {
            setPickerValue({
              hour: value.split(':')[0],
              colon: ':',
              minute: value.split(':')[1],
            });
          }
          break;
        case HNW_LINE_INPUT.TYPES.NUMBER:
          setNumpadValue(value.toString());
          break;
        default:
          break;
      }
    }
  }, [value, type]);

  const onChangeValue = (type, data) => {
    let numberString = '';
    switch (type) {
      case HNW_LINE_INPUT.ON_CHANGE:
        numberString = numpadValue.replace('-', '') + data;
        break;
      case HNW_LINE_INPUT.ON_DELETE:
        numberString = numpadValue.toString().slice(0, -1);
        break;
      default:
        break;
    }
    setNumpadValue(numberString);
    onChange(name, numberString);
  };

  const setPickerChange = (data) => {
    setPickerValue(data);
  };

  const handleOKClick = () => {
    switch (type) {
      case HNW_LINE_INPUT.TYPES.TIME:
        onChange(
          name,
          `${pickerValue.hour.includes('-') ? '00' : pickerValue.hour}:${pickerValue.minute.includes('-') ? '00' : pickerValue.minute
          }`,
        );
        break;
      case HNW_LINE_INPUT.TYPES.NUMBER:
        if (!numpadValue.includes('-')) {
          onChange(name, numpadValue);
        }
        break;
      default:
        break;
    }
    handleKeypadOpen(false);
    toggleKeypad(false);
    handleScrollBottom(true);
  };

  const renderValue = () => {
    switch (type) {
      case HNW_LINE_INPUT.TYPES.TEXT:
        return value;
      case HNW_LINE_INPUT.TYPES.TIME:
        return (
          <span
            style={{
              color:
                {
                  [SUCCESS_TYPE]: variables.themePrimary,
                  [ERROR_TYPE]: variables.themeDanger,
                }[validationStatus] || 'unset',
            }}
          >
            {`${!pickerValue.hour || !pickerValue.minute
              ? '--:--'
              : pickerValue.hour + ':' + pickerValue.minute
              }`}
          </span>
        );
      case HNW_LINE_INPUT.TYPES.NUMBER:
        return (
          <span
            style={{
              color:
                {
                  success: variables.themePrimary,
                  error: variables.themeDanger,
                }[validationStatus] || 'unset',
            }}
          >
            {numpadValue || '-'}
          </span>
        );
      default:
        break;
    }
  };

  const renderKeypad = () => {
    switch (type) {
      case HNW_LINE_INPUT.TYPES.TEXT:
        return <></>;
      case HNW_LINE_INPUT.TYPES.TIME:
        const timePickerObj = {
          hour: Array.from({ length: 24 }, (v, i) =>
            i.toString().padStart(2, '0'),
          ),
          colon: [':'],
          minute: Array.from({ length: 60 }, (v, i) =>
            i.toString().padStart(2, '0'),
          ),
        };
        return (
          <HnWPicker
            pickerHeader={pickerHeader}
            pickerValue={pickerValue}
            setPickerChange={setPickerChange}
            pickerOptionsObj={timePickerObj}
            handleOKClick={handleOKClick}
          />
        );
      case HNW_LINE_INPUT.TYPES.NUMBER:
        return (
          <Numpad
            handleInputChange={(value) =>
              onChangeValue(HNW_LINE_INPUT.ON_CHANGE, value)
            }
            handleDelete={() => onChangeValue(HNW_LINE_INPUT.ON_DELETE)}
            handleOKClick={handleOKClick}
          />
        );
      case 'custom':
        return customKeypad && customKeypad();
      default:
        return <></>;
    }
  };

  return (
    <div className={`line-custom-input ${className || ''}`}>
      <div id={id} className="__field" onClick={() => {
        toggleKeypad(true);
        handleKeypadOpen(true);
      }}>
        {renderValue()}
      </div>
      <div className="__keypad">
        <HnWNumpadModal
          onClose={() => {
            toggleKeypad(false);
            handleKeypadOpen(false);
          }}
          openNumpad={showKeypad}
          onBackgroundClick={handleOKClick}
        >
          {renderKeypad()}
        </HnWNumpadModal>
      </div>
    </div>
  );
}
