import { formatWithOptions } from 'date-fns/fp';
import { format } from 'date-fns';
import { th } from 'date-fns/locale';
import { EStatus } from './components/library/common.library';
import config from './config';
import { round } from './services';
import {
  getCustomerAge,
  getCustomerGender,
} from './services/healthWellness/healthWellness.service';
import { getDefaultValues } from './services/healthWellness/hnwGoals.service';
import { API_DATE_FORMAT, GENDER, REGEX_YOUTUBE_DURATION } from './common/commonConstant';
import { BMI_ICON_MAPPING, ChallengeTeamdashboardStatus, maxFoodVdoCalorieBadges, maxQuizBadges, stepsBadgesRange, WEIGHT_FAT_MUSCLE_MAPPING, teamMaxSize, CHALLENGE, FB_SDK_PARAMS, CHALLENGE_BMI_TEXT } from './common/challengeConstants';
import { isIOS } from 'react-device-detect';
import moment from 'moment';

export const checkIsIOS = () => isIOS;

export const thaiDate = (dateTime, format = 'MMM yyyy') => {
  const newDateTime = new Date(dateTime);
  const englishYear = newDateTime.getFullYear();
  // format search for finding yy format instead of yyyy
  const thaiYear = ~format.search(/\byy\b/g)
    ? `${+englishYear + 543}`.substring(2)
    : `${+englishYear + 543}`;
  const fullYearFormat = format.replace(/\byy\b/g, 'yyyy');

  const thFormat = formatWithOptions({ locale: th }, fullYearFormat);
  return thFormat(newDateTime).replace(englishYear, thaiYear);
};

export const thaiDateWOTime = (dateTime, format = 'MMM yyyy') => {
  const date = moment.utc(dateTime).startOf('day').toDate();
  const thaiYear = (date.getFullYear() + 543).toString();
  const thaiYearTwoDigits = thaiYear.slice(-2);
  const fullYearFormat = format.replace(/\byy\b/g, 'yyyy');
  const formattedDate = formatWithOptions({ locale: th }, fullYearFormat)(date);
  const thaiFormattedDate = formattedDate.replace(/\d{4}/, thaiYearTwoDigits.toString());
  return thaiFormattedDate;
};

export const getTodayFormatDate = (todayDate) => {
  const options = { day: 'numeric', month: 'short' };
  const formattedDate = new Intl.DateTimeFormat('th-TH', options).format(
    todayDate,
  );
  return `วันนี้, ${formattedDate}`;
};

const addZero = (value) => (value < 10 ? `0${value}` : value);

export const getDateForAPI = (date) => {
  return `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(
    date.getDate(),
  )}`;
};

export const countAmwayLibraryBook = (value) => {
  let count;
  if (value) {
    if (value.length > 0) {
      count = value.filter((item) => {
        if (item.status === EStatus.PUBLISH) {
          return true;
        }
        return false;
      }).length;

      return count;
    } else {
      return 0;
    }
  }
};

export const libraryImageUrl = (path) => `${config.S3_URL}${path}`;

export const getDataFromSession = (key) => {
  return JSON.parse(sessionStorage.getItem(key));
};

export const formatNumberWithCommas = (inputVal) => {
  const regex = /\B(?=(\d{3})+(?!\d))/g;
  return inputVal === 0
    ? '0'
    : inputVal
      ? inputVal.toString().replace(regex, ',')
      : '';
};

export const calculateConsumableQuantity = (enteredValue, defaultValue) => {
  return (enteredValue / defaultValue).toFixed(2);
};

export const getParamsString = (paramsObj) => {
  return Object.keys(paramsObj)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(paramsObj[key])}`,
    )
    .join('&');
};

export const getNumberFromString = (stringVal) => {
  let number = parseFloat(stringVal);
  if (isNaN(number)) {
    return 0;
  }
  number = Math.round(number * 100) / 100;
  return number;
};

export const getTotalFoodLogValues = (dataArr) => {
  const totals = dataArr.reduce(
    (totals, item) => {
      totals.totalCalories += getLogValueByWeight(item, 'caloriesPerDay') ?? 0;
      totals.totalCarbs += getLogValueByWeight(item, 'carbohydratePerDay') ?? 0;
      totals.totalProtein += getLogValueByWeight(item, 'proteinPerDay') ?? 0;
      totals.totalFat += getLogValueByWeight(item, 'fatPerDay') ?? 0;
      return totals;
    },
    { totalCalories: 0, totalCarbs: 0, totalProtein: 0, totalFat: 0 },
  );

  return {
    totalCalories: parseFloat(totals.totalCalories.toFixed(1)),
    totalCarbs: parseFloat(totals.totalCarbs.toFixed(1)),
    totalProtein: parseFloat(totals.totalProtein.toFixed(1)),
    totalFat: parseFloat(totals.totalFat.toFixed(1)),
  };
};

export const getSuggestedGoalPayload = (foodGoal) => {
  const newObj = {};
  for (const key in foodGoal) {
    if (foodGoal.hasOwnProperty(key)) {
      let newKey;
      if (key === 'targetCalories') {
        newKey = 'suggestedCalorie';
      } else {
        newKey = key.replace('target', 'suggested');
      }
      newObj[newKey] = foodGoal[key];
    }
  }
  return newObj;
};

export const getLogValueByWeight = (dataObj, itemName) => {
  const { weight, myFoodWeight } = dataObj;
  const value = dataObj[itemName] * (myFoodWeight / weight);
  return parseFloat(value.toFixed(1)) || 0;
};

export const calculateDefaultFoodGoal = (bmiData) => {
  let defaultValues = {};
  const customerGender = Object.keys(GENDER).find(
    (item) => GENDER[item] === getCustomerGender(),
  );
  const customerAge = getCustomerAge();
  defaultValues = getDefaultValues(bmiData, customerAge, customerGender);
  const defaultFoodGoal = {
    targetCalories: round(defaultValues.defaultCalories, 0) || 0,
    targetProtein: round(defaultValues.defaultProtein) || 0,
    targetCarbs: round(defaultValues.defaultCarbs) || 0,
    targetFat: round(defaultValues.defaultFat) || 0,
  };
  return defaultFoodGoal;
};

export const calculateDefaultWaterGoal = (bmiData) => {
  if (bmiData?.weight) {
    return (bmiData.weight / 2) * 2.2 * 30;
  } else {
    return 0;
  }
};

export const calculateCaloriesByWeight = (foodDataObj) => {
  const { calorie, myFoodWeight, weight } = foodDataObj;
  const calories = parseFloat((calorie * (myFoodWeight / weight)).toFixed(2));
  return isNaN(calories) ? 0 : calories;
};

export const formatStringWithDecimal = (stringVal) => {
  if (stringVal == null || isNaN(parseFloat(stringVal))) {
    return '';
  }
  const num = parseFloat(stringVal);
  return num % 1 === 0 ? num.toString() : num.toFixed(2);
};

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const percentCalculation = (usedQty, maxQty) => {
  return (usedQty / maxQty) * 100;
};

export const isEmptyObject = (obj) => {
  return obj && Object.keys(obj).length === 0;
};

export const isValidObject = (obj) => {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    !Array.isArray(obj) &&
    Object.keys(obj).length > 0
  );
};

export const isValidDateTime = (dateTimeString) => !isNaN(new Date(dateTimeString).getTime())

export const checkImageURL = (image) =>
  image?.startsWith('https://') ? image : libraryImageUrl(image);

export const getTotalSteps = (distanceCovered) => {
  if (distanceCovered) {
    return round(distanceCovered / 0.000762, 0);
  }
  return 0;
};

export const getIsoFormattedDate = (dateTime) => format(dateTime ? new Date(dateTime) : new Date(), API_DATE_FORMAT);

export const getChallengeTodayDate = () => getIsoFormattedDate();

export const getBmiRangeUserCount = (userBmiData) => {
  let userCount = 0;
  userBmiData.forEach((bmi) => {
    if (bmi > 18.5 && bmi < 23) {
      userCount++;
    }
  })
  return userCount;
}


export const calculateDaysLeft = (targetDate) => {
  const target = new Date(targetDate);
  target.setHours(0, 0, 0, 0);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const timeDifference = target.getTime() - today.getTime();

  const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  return daysLeft > 0 ? daysLeft : 0;
};

export const checkAfterWeightPeriod = (status) => {
  return (status == ChallengeTeamdashboardStatus.weight_end || status == ChallengeTeamdashboardStatus.end_challenge || status == ChallengeTeamdashboardStatus.reward_period)
}

export const calculateTeamDashboardStatus = (approve_date, team_size, complete60_date, end_challenge, complete_team_in, weightout_date, rewardEndDate) => {
  const weightoutStartDate = weightout_date?.start_date || weightout_date?.startDate;
  const weightoutEndDate = weightout_date?.end_date || weightout_date?.endDate;

  let teamDashboardStatus = ChallengeTeamdashboardStatus.not_yet_started;
  let headerDate = '';
  let headerBadge = false;
  let notifictionBadge = false;
  const todayDate = getChallengeTodayDate();
  if (!approve_date && team_size != teamMaxSize) {
    teamDashboardStatus = ChallengeTeamdashboardStatus.not_yet_started;
    headerDate = `${thaiDate(complete_team_in, 'dd MMM yy')}`;
    notifictionBadge = true;
  } else if (!approve_date && team_size == teamMaxSize) {
    teamDashboardStatus = ChallengeTeamdashboardStatus.ready_to_start;
    headerBadge = true
  } else if (end_challenge) {
    if (team_size != 4) {
      teamDashboardStatus = ChallengeTeamdashboardStatus.remove_team;
      headerBadge = true;
    }
    else {
      if (todayDate >= end_challenge || end_challenge.split('T')[0] === todayDate) {
        if (rewardEndDate && todayDate <= rewardEndDate) {
          teamDashboardStatus = ChallengeTeamdashboardStatus.reward_period;
          headerDate = `${thaiDate(rewardEndDate, 'dd MMM yy')}`;
        } else {
          teamDashboardStatus = ChallengeTeamdashboardStatus.end_challenge;
          headerDate = `${thaiDate(end_challenge, 'dd MMM yy')}`;
        }

      } else if (weightout_date &&
        (weightoutStartDate <= todayDate && weightoutEndDate >= todayDate)) {
        teamDashboardStatus = ChallengeTeamdashboardStatus.weight_end;
        headerDate = `${thaiDate(weightoutStartDate, 'dd MMM yy')} - ${thaiDate(weightoutEndDate, 'dd MMM yy')}`;
        notifictionBadge = true;
      } else {
        teamDashboardStatus = ChallengeTeamdashboardStatus.ongoing;
        headerDate = `${thaiDate(approve_date, 'dd MMM yy')} - ${thaiDate(complete60_date, 'dd MMM yy')}`;
        headerBadge = true;
      }
    }
  }

  return {
    status: teamDashboardStatus,
    header: headerDate,
    notification: notifictionBadge,
    titleBadge: headerBadge
  };
}

export const removeMinusFromString = (value) => {
  if (value < 0) {
    return value?.toString()?.substring(1);
  } else {
    return value;
  }
}

export const handleBmiBadges = (value) => {
  let bmiBadge = '';
  let bmiTitle = '';
  let classname = '';
  if (value < 18.5) {
    bmiBadge = BMI_ICON_MAPPING.low;
    bmiTitle = CHALLENGE_BMI_TEXT.low;
    classname = BMI_ICON_MAPPING.low.classname;

  }
  else if (value >= 18.5 && value < 23) {
    bmiBadge = BMI_ICON_MAPPING.normal;
    bmiTitle = CHALLENGE_BMI_TEXT.normal;
    classname = BMI_ICON_MAPPING.normal.classname;
  }
  else if (value >= 23 && value < 25) {
    bmiBadge = BMI_ICON_MAPPING.firstLevelFat;
    bmiTitle = CHALLENGE_BMI_TEXT.firstLevelFat;
    classname = BMI_ICON_MAPPING.firstLevelFat.classname;
  }
  else if (value >= 25 && value < 30) {
    bmiBadge = BMI_ICON_MAPPING.secondLevelFat;
    bmiTitle = CHALLENGE_BMI_TEXT.secondLevelFat;
    classname = BMI_ICON_MAPPING.secondLevelFat.classname;
  }
  else if (value >= 30) {
    bmiBadge = BMI_ICON_MAPPING.thirdLevelFat;
    bmiTitle = CHALLENGE_BMI_TEXT.thirdLevelFat;
    classname = BMI_ICON_MAPPING.thirdLevelFat.classname;
  }
  return {
    bmiBadge: bmiBadge,
    bmiTitle: bmiTitle,
    classname: classname
  };
}

export const filterRegisteredAndWeightOutData = (registeredTeamData, weightOutTeamData, teamDashboardStatus, registeredData) => {
  if (teamDashboardStatus == ChallengeTeamdashboardStatus.weight_end) {
    return registeredData ?
      registeredTeamData ? formatStringWithDecimal(registeredTeamData) : '-' :
      weightOutTeamData ? formatStringWithDecimal(weightOutTeamData) : '-';
  } else {
    return registeredTeamData ? formatStringWithDecimal(registeredTeamData) : '-'
  }
}

export const handleWeightFatMuscleBadges = (value, muscle) => {
  let weightFatMuscleBadge = (value <= 0 ? WEIGHT_FAT_MUSCLE_MAPPING.decrease : WEIGHT_FAT_MUSCLE_MAPPING.increase)
  if (muscle) {
    weightFatMuscleBadge = (value <= 0 ? WEIGHT_FAT_MUSCLE_MAPPING.muscleDec : WEIGHT_FAT_MUSCLE_MAPPING.muscleInc)
  }
  return weightFatMuscleBadge;
}

export const handleRewardStatus = (status) => {
  let rewardTitle = '';
  let rewardIcon = '';
  let rewardClass = '';

  switch (status) {
    case CHALLENGE.reward.received:
      rewardTitle = CHALLENGE.reward.order_placed;
      rewardIcon = '/images/challenge/icons/challenge-success-tick.svg';
      rewardClass = 'approved';
      break;
    case CHALLENGE.reward.waiting_to_received:
      rewardTitle = CHALLENGE.reward.approved;
      rewardIcon = '/images/challenge/icons/challenge-timer.svg';
      rewardClass = 'pending';
      break;
    case CHALLENGE.reward.not_qualified:
      rewardTitle = CHALLENGE.reward.rejected;
      rewardIcon = '/images/challenge/icons/team-dashboard-caution-weight.svg';
      rewardClass = 'rejected';
      break;
    default:
      rewardTitle = CHALLENGE.reward.pending;
      rewardIcon = '/images/challenge/icons/challenge-timer.svg';
      rewardClass = 'pending';
      break;


  }
  return {
    rewardTitle: rewardTitle,
    rewardIcon: rewardIcon,
    rewardClass: rewardClass
  };
}


export const inactiveClassName = (status, disableClass, activeClass) => {
  return status === ChallengeTeamdashboardStatus.remove_team ? disableClass : activeClass
}

export const updateInactiveBadges = (badgeList, badgeValue) => {
  return badgeList.map((item, index) => {
    if (index + 1 <= badgeValue) {
      return {
        ...item,
        imageUrl: item.imageUrl.replace('_disable', ''),
      };
    }
    return item;
  });
};

export const updateInactiveMissionBadges = (badgeList, badgeValue, type) => {
  return badgeList.map(item => {
    if (item.type === type && badgeValue >= item.maxValueOfBadge) {
      return {
        ...item,
        imageUrl: item.imageUrl.replace('_disable', ''),
      };
    }
    return item;
  });
};

export const filterActiveInactiveImages = (value, image) => {
  return (value) ? `/images/challenge/badges/${image}-badge@5x.png` : `/images/challenge/badges/${image}_disable-badge@5x.png`;
}

export const getStepsBadgesRange = (value, minRange) => {
  const step = stepsBadgesRange;
  const minValue = value - minRange;
  const perc = minValue < 0 ? 0 : ((minValue) / step) * 100;
  return perc > 100 ? 100 : perc;
}

// TODO: need to verify rewards condition from challenge team
//         // } else if (data.user_rewards.claim_date) {
//         //     teamDashboardStatus = 'reward_day';
//         // }

export const getDatetimeStamp = (datetime) => {
  return moment(datetime).utc().toString();
};

export const timeMoment = (time) =>
  time && time?.length === 5 && time?.includes(':')
    ? moment(time?.split(':').map(Number), 'HH:mm')
    : null;

export const calculateDifference = (start, end) => {
  const startTime = timeMoment(start);
  const endTime = timeMoment(end);
  const difference =
    !!endTime && !!startTime ? endTime?.diff(startTime, 'minutes') : 0;
  return parseInt(difference);
};

export const initializeLiff = async () => {
  const liff = window.liff;
  const LIFF_CLIENT_ID = process.env.REACT_APP_LIFF_CLIENT_ID;
  try {
    await liff.init({ liffId: LIFF_CLIENT_ID });
    if (liff.isLoggedIn()) {
      await liff.ready;
      return liff;
    } else {
      liff.login();
    }
  } catch (error) {
    throw error;
  }
}

export const initializeFbSDK = () => {
  window.fbAsyncInit = () => {
    window.FB.init(FB_SDK_PARAMS);
    window.FB.AppEvents.logPageView();
  }

  // used below function as self invoking as per suggested by Facebook Docs 
  (((d, s, id) => {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s); js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk'));
}

export const makeLeaderFirst = (usersList = []) => {
  const leaderIndex = usersList.findIndex(user => user?.isLeader);
  if (leaderIndex > -1) {
    const [leader] = usersList.splice(leaderIndex, 1);
    usersList.unshift(leader);
  }
  return usersList;
}

export const decodeHtml = (html) => {
  const txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

export const convertVideoDurationToSeconds = (duration) => {
  if (!duration) return 0;

  let seconds = 0;
  const match = duration.match(REGEX_YOUTUBE_DURATION);
  if (match) {
    seconds += parseInt(match[1]?.replace("H", "") ?? 0) * 3600;
    seconds += parseInt(match[2]?.replace("M", "") ?? 0) * 60;
    seconds += parseInt(match[3]?.replace("S", "") ?? 0);
  }
  return seconds;
};

export const formatVideoDuration = (duration) => {
  const totalSeconds = convertVideoDurationToSeconds(duration);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${hours > 0 ? hours + ':' : ''}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

export const chunkArray = (array, size) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
}

export const sortLeaderUser = (users) => {
  return users.sort((a, b) => {
    if (a?.is_leader) return -1;
    if (b?.is_leader) return 1;
    return 0;
  });
}
export const getMissionBadgesRange = (value, type) => {
  const badges = type == 'quiz' ? maxQuizBadges : maxFoodVdoCalorieBadges;
  const perc = ((value) / badges) * 100;
  return perc > 100 ? 100 : perc;
}

export const updateStepsBadgeList = (badgeList, badgeValue) => {
  return badgeList.map((badge, index) => {
    if (!badgeValue) { return `${badge}_disable` };
    return (index >= badgeValue) ? `${badge}_disable` : badge;
  });
}

export const updateMissionBadgesList = (badgeList, badgeValue) => {
  if (!badgeValue) {
    return badgeList.map(badge => `${badge}_disable`);
  }
  return badgeList;
};

export const removeDisableForRedirection = (value) => {
  const disable = '_disable';
  return value.includes(disable) ? value.replace(disable, '') : value;
}

export const checkNullDate = (date) => {
  if (date && date != '-') {
    return thaiDateWOTime(date, 'dd MMM yy');
  }
}
export const sortByDateKey = (data, key) => {
  return data.sort((a, b) => {
    return new Date(a[key]) - new Date(b[key]);
  });
};

export const getCurrentDateTimeStamp = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = `${today.getMonth() + 1}`.padStart(2, 0); // Months start at 0!
  const day = today.getDate().toString().padStart(2, 0);
  const hour = today.getHours().toString().padStart(2, 0);
  const minutes = today.getMinutes().toString().padStart(2, 0);
  const seconds = today.getSeconds().toString().padStart(2, 0);
  return `${year}${month}${day}${hour}${minutes}${seconds}`;
};

export const getSplitText = (value, index, separator = "_") => {
  if (value) {
    return value.split(separator)[index]
  }
  return value;
}

export const getOthersBadgesRange = (value, maxValueOfBadge) => {
  const perc = ((value) / maxValueOfBadge) * 100;
  return perc > 100 ? 100 : perc;
}

export const roundOffDecimalVal = (value) => {
  if (value == null || isNaN(parseFloat(value))) {
    return '';
  }
  const num = parseFloat(value);
  const newValue = Math.round((num).toFixed(3) * 100) / 100;
  return newValue.toFixed(2);
};

export const convertSecondsToDecimalMinutes = (seconds) => {
  const secondsToMinutes = seconds / 60;
  const roundedMinutes = Math.round(secondsToMinutes * 100) / 100;
  return roundedMinutes;
}

export const convertMinutesToSeconds = (minutes) => {
  const minutesToSeconds = minutes * 60;
  return minutesToSeconds;
}

export const getUserProfileUrl = (profileUrl, partyId) => {
  const { partyId: loggedInPartyId } = getDataFromSession('customer') || {};
  const loggedInProfile = getDataFromSession('lineProfile')?.pictureUrl;

  return (partyId === loggedInPartyId && profileUrl !== loggedInProfile) 
    ? loggedInProfile 
    : profileUrl;
};

export const sortBadgeUserList = (badgeData, filterKey , dateKey, otherKey, order) => {
  Object.keys(badgeData).forEach(category => {
    const allUsersData = badgeData[category].allUsersData;
    const withDate = allUsersData.filter(user => user[filterKey]);
    const withoutDate = allUsersData.filter(user => !user[filterKey]);
    withDate.sort((a, b) => {
      const dateA = new Date(a[dateKey]);
      const dateB = new Date(b[dateKey]);
      if(dateA < dateB) {
        return -1;
      } else if(dateA == dateB) {
        return 0;
      } else {
        return 1;
      }
    });
    withoutDate.sort((a, b) => {
      const valueA = a[otherKey] || 0;
      const valueB = b[otherKey] || 0;
      return (order = 'desc') ? (valueB - valueA) :  (valueA - valueB);
      
    });
    badgeData[category].allUsersData = [...withDate, ...withoutDate];
  });

  return badgeData;
};

export const sortIntByKey = (array, key) => {
  return array.sort((a, b) => {
    const keyA = parseInt(a[key], 10);
    const keyB = parseInt(b[key], 10);

    return keyA - keyB;
  })
}