import React, { useState, useRef, useEffect } from 'react';
import './ChallengeDetailSlider.scss';
import { CHALLENGE_DETAIL, CHALLENGE_MAP_SLIDER } from '../challengeConstants';

const ChallengeDetailSlider = ({ minHeight = CHALLENGE_MAP_SLIDER.sliderMinHeight,
    maxHeight = CHALLENGE_MAP_SLIDER.sliderMaxHeight,
    defaultHeight = CHALLENGE_MAP_SLIDER.sliderDefaultHeight,
    showGrab = true,
    styleObj,
    children, 
    autoHeight = false,
    onDrag }) => {

    const contentHeight = autoHeight ? CHALLENGE_MAP_SLIDER.siderAutoHeight : `${defaultHeight}px`;
    const sliderRef = useRef(null);
    const isDragging = useRef(false);
    const startY = useRef(0);
    const startHeight = useRef(0);
    const [sliderHeight, setSliderHeight] = useState(contentHeight);

    const handleDrag = (event) => {
        event.stopPropagation();
        if (isDragging.current) {
            const clientY = event.touches ? event.touches[0].clientY : event.clientY;
            const currentHeight = parseInt(sliderHeight.replace('px', ''), 10);
            
            const newHeight = autoHeight
            ? (startHeight.current + startY.current) - clientY
            : Math.min(maxHeight, Math.max(minHeight, currentHeight + (startY.current - clientY)));

            setSliderHeight(`${newHeight}px`);

            if(onDrag) {
                onDrag(newHeight, clientY);
            }
        }
    };

    const handleDragEnd = (event) => {
        event.stopPropagation();
        isDragging.current = false;
        const container = sliderRef.current;
        if (container) {
            CHALLENGE_DETAIL.dragEvents.forEach((event) => {
                container.removeEventListener(event, handleDrag);
            });
            CHALLENGE_DETAIL.dragEndEvents.forEach((event) => {
                container.removeEventListener(event, handleDragEnd);
            });
        }
    };

    const handleDragStart = (event) => {
        event.stopPropagation();
        const clientY = event.touches ? event.touches[0].clientY : event.clientY;
        const container = sliderRef.current;
        const initHeight = container.offsetHeight;

        isDragging.current = true;
        startY.current = clientY;
        startHeight.current = initHeight;
        
        
        if (container) {
            CHALLENGE_DETAIL.dragEvents.forEach((event) => {
                container.addEventListener(event, handleDrag);
            });
            CHALLENGE_DETAIL.dragEndEvents.forEach((event) => {
                container.addEventListener(event, handleDragEnd);
            });
        }
    };

    const containerStyle = {
        ...styleObj,
        height: `${sliderHeight}`,
    };

    useEffect(() => {
        if (autoHeight) {
            setSliderHeight(CHALLENGE_MAP_SLIDER.siderAutoHeight);
        }
    },[children])

    return (
        <div
            className="challenge-slider-container"
            style={containerStyle}
            onMouseDown={handleDragStart}
            onTouchStart={handleDragStart}
            ref={sliderRef}
        >
            {showGrab && (
                <div
                    className="challenge-slider-container-grab"
                >
                    <img src="/images/challenge/challenge-detail-grabber.svg" />
                </div>
            )}
            <div className='challenge-slider-container-content'>
                {children}
            </div>
        </div>
    )
}

export default ChallengeDetailSlider;