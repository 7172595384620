export class ChallengeBodyKeyStatus {
  constructor(data) {
    this.isSuccess = data.IsSuccess || false;
    this.data = new ChallengeBodyKeyStatusData(data.Data);
    this.errorMsg = data.ErrorMsg || null;
  }
}

export class ChallengeBodyKeyRecord {
  constructor(data){
    this.isSuccess = data.IsSuccess || false;
    this.data = data?.Data ? data?.Data?.map(record => new ChallengeBodyKeyRecordData(record)) : [];
    this.errorMsg = data.ErrorMsg || null;
  }
}

class ChallengeBodyKeyStatusData {
  constructor(data) {
    this.autoSync = data.AutoSync || '';
    this.syncStep = data.SyncStep || '';
    this.deviceLanguage = data.DeviceLanguage || '';
    this.appLanguage = data.AppLanguage || '';
    this.foodDatabase = data.FoodDatabase || '';
    this.firmwareVersion = data.FirmwareVersion || '';
  }
}

class ChallengeBodyKeyRecordData {
  constructor(data) {
    this.date = data.Date || '';
    this.stepCount = data.StepCount || '';
    this.stepKcal = data.StepKcal || '';
    this.stepDistance = data.StepDistance || '';
    this.stepTime = data.StepTime || '';
  }
}

