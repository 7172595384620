import React from 'react';
import './HnwFooter.scss';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import commonConstant, { FOOTER_MENU_MAPPING, routePathKey } from '../commonConstant';

const ChallengeFooter = () => {
  const history = useHistory();
  const activeStatus = useSelector(
    (state) => state.footerActiveStatus?.footerActiveState,
  );
  const policyStatus = useSelector((state) => state?.home?.policyStatus);

  const footerImg = FOOTER_MENU_MAPPING.find(
    (obj) => obj.id === activeStatus,
  )?.navBarUrl;

  const handleMenuClick = (url) => history.push(policyStatus ? url : commonConstant.pathPolicy);
  
  return (
    <div className="hnw-footer-main">
      <img src={footerImg} className="hnw-footer-main-img" />
      <div className="hnw-footer-main__bottom-wrapper">
        {FOOTER_MENU_MAPPING.map((menuObj) => {
          const menuClass =
            menuObj.id === activeStatus
              ? 'footer-tab footer-tab-active'
              : 'footer-tab';
          return (
            <label
              key={menuObj.id}
              className={menuClass}
              onClick={() => handleMenuClick(menuObj.routeUrl)}
            >
              <img src={menuObj.iconUrl} />
              <div className="footer-tab-name">{menuObj.label}</div>
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default ChallengeFooter;
