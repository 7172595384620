import React from 'react';
import './Numpad.scss'

const Numpad = ({ handleInputChange, handleDelete, handleOKClick }) => {

    const handleValueChange = (value) => {
        handleInputChange(value);
    };

    return (
        <div className="numpad-container">
            <div className="container-fluid">
                <div className="header-btn">
                    <button className='btnClass' onClick={() => handleOKClick()}>{'บันทึก'}</button>
                </div>
            </div>
            <div className="numpad">
                <div className="numpad-row">
                    {[1, 2, 3].map((number) => (
                        <button key={number} onClick={() => handleValueChange(number.toString())}>{number}</button>
                    ))}
                </div>
                <div className="numpad-row">
                    {[4, 5, 6].map((number) => (
                        <button key={number} onClick={() => handleValueChange(number.toString())}>{number}</button>
                    ))}
                </div>
                <div className="numpad-row">
                    {[7, 8, 9].map((number) => (
                        <button key={number} onClick={() => handleValueChange(number.toString())}>{number}</button>
                    ))}
                </div>
                <div className="numpad-row">
                    <button onClick={() => handleValueChange('.')}>.</button>
                    <button onClick={() => handleValueChange('0')}>0</button>
                    <button onClick={handleDelete}>
                        <img onContextMenu={event => event.preventDefault()} src="/images/healthAndWellness/numpad-union.svg" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Numpad;