import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { osName } from 'react-device-detect';
import LoadingOverlay from 'react-loading-overlay';
import _ from 'lodash';
import { createBrowserHistory } from 'history';
import { connect } from 'react-redux';

import { alertActions } from '../../actions';
import { commonConstant } from '../../common';
import { Modal } from '../../helpers';
import { customerService, activityService, amwayService } from '../../services';
import Condition from '../condition/condition.jsx';

export const history = createBrowserHistory();

class Foa extends Component {
  constructor(props) {
    super(props);

    if (sessionStorage.getItem('customer') === null || sessionStorage.getItem('customer') === 'undefined') {
      window.location.replace(commonConstant.pathHome);
    }

    const customer = JSON.parse(sessionStorage.getItem('customer'));

    if (!customer.type) {
      return window.location.replace(commonConstant.pathHome);
    }

    this.state = {
      customer: customer,
      DistNum: '',
      tel: '',
      loading: false,
      error: false,
      show: false,
      accept: false,
      condition: false,
      isOpenModal: false,
      modalContent: '',
      resMemberNumber: null,
      profile: ''
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (!location.state || !location.state.myMember || location.state.myMember.accountMst.accountTypeCd !== 'Customer') return window.location.replace(commonConstant.pathHome);
    this.setState({ profile: location.state.myMember })
  }

  onPaste = e => {
    e.preventDefault();
    return false;
  };

  handleChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || e.target.value.match(regex)) {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  handleCheckBox = () => this.setState({ accept: !this.state.accept })
  handleCondition = () => this.setState({ condition: !this.state.condition })

  handleModalOk = () => {
    const { modalContent, isOpenModal } = this.state;
    this.toggleModal();
    // switch (modalContent) {
    //   case 'duplicate':
    //     this.setState({ isOpenModal: !isOpenModal });
    //     return this.props.history.push('/changeMID', { myMember: this.props.location.state.myMember, oldLineId: this.state.oldLineId, oldMember: this.state.oldMember })
    //   default:
    //     break;
    // }
  }

  toggleModal = (content, resMemberNumber = null) => {
    this.setState({
      isOpenModal: !this.state.isOpenModal,
      modalContent: content,
      resMemberNumber,
    });
  }



  handleModalContent = () => {
    const { modalContent, profile } = this.state;
    switch (modalContent) {
      case 'duplicate':
        return { title: `รหัส ${profile.accountMst.aboNum} มีการผูกกับ LINE ID อื่น`, detail: 'กรุณาคลิกปุ่มเปลี่ยนบัญชี เพื่อลงทะเบียน\nAmway Social Connect', type: 'warning' };
      case 'card':
        return { title: null, detail: '<img src="/images/id-card-hint.png" alt="" />' };
      case 'wrongCid':
        return { title: 'ข้อมูลนักธุรกิจไม่ถูกต้อง', detail: 'กรุณาตรวจสอบเบอร์โทรศัพท์\nใหม่อีกครั้ง', type: 'warning' }
      default:
        break;
    }
  }
  handleSubmit = async (e) => {
    window.scrollTo(0, 1);
    const { tel, profile } = this.state;
    const amwayNumber = profile.accountMst.aboNum
    e.preventDefault();
    this.setState({ loading: true });

    const accountProfile = _.filter(profile.partyList, function (o) {
      return o.phoneList[0] && o.phoneList[0].phoneLocalNum === tel;
    });

    if (accountProfile.length === 0) {
      this.setState({ isOpenModal: false, loading: false });
      this.toggleModal('wrongCid');
      return;
    }

    //const accountProfile = _.filter(profile.partyList, function (o) { return o.taxList[0] && o.taxList[0].taxId.substr(-4) === cid; });
    const profileSchema = {
      familyName: '',
      givenName: '',
      middleName: '',
      suffix: '',
      title: '',
    };

    if (accountProfile.length > 0) {
      const localNameObj = accountProfile[0].nameList[0].localeName;
      profileSchema.familyName = (localNameObj.familyName !== null) ? localNameObj.familyName : '';
      profileSchema.givenName = (localNameObj.givenName !== null) ? localNameObj.givenName : '';
      profileSchema.middleName = (localNameObj.middleName !== null) ? localNameObj.middleName : '';
      profileSchema.suffix = (localNameObj.suffix !== null) ? localNameObj.suffix : '';
      profileSchema.title = (localNameObj.title !== null) ? localNameObj.title : '';
    }

    const AboName = `${profileSchema.givenName} ${profileSchema.familyName}`;
    
    const customer = {
      name: AboName,
      _id: this.state.customer._id,
      info: osName === 'IOS' ? 'iPhone' : osName,
      amwayNumber: parseInt(amwayNumber),
      partyId: profile.accountMst.primaryPersonPartyId,
      socialType: 'LINE',
    }; 

    const updated = await customerService.update(customer._id, customer);

    activityService.create({
      uid: this.state.customer.uid,
      userId: this.state.customer._id,
      status: 'incomplete',
      action: 'VERIFY_ABO',
    });
    //------- End ABO Verify --------------------------------------------------------------

    return this.props.history.push('/success', { 'user': updated });
  };

  render() {
    const { tel, isOpenModal, accept, condition, modalContent, profile } = this.state;
    let lblHead = "หมายเลขสมาชิกแอมเวย์FOA";
    if(profile && profile.accountMst.aboNum){
        if ((profile.accountMst.aboNum).substring(0, 3)=='999'){
          lblHead = "รหัสพนักงาน";
        }
    }
    const isTrue = tel.length === 10 && accept;
    const content = this.handleModalContent();
    if (condition) { return <Condition fn={this.handleCondition} />; }
    return (
      <div className="regisPage">
        <LoadingOverlay active={this.state.loading} spinner text="Loading" styles={{ wrapper: { width: '100%', minHeight: '100%' } }} />
        {
          isOpenModal ?
            <Modal
              title={content.title}
              detail={content.detail}
              type='warning'
              closeBtn
              fnClose={this.toggleModal}
              fnOk={this.handleModalOk}
              okText={modalContent !== 'duplicate' ? 'ตกลง' : 'เปลื่ยนบัญชี'}
              okBtn={modalContent !== 'card'} /> : null
        }
        <form onSubmit={this.handleSubmit}>
          <div className="logoAmway"><img src="/images/logoAmway-black.svg" alt="" /></div>
          <h2 className="mb-90">กรุณากรอกข้อมูลลงทะเบียน</h2>
          <div className="amwayNumber-container">
            <small>{lblHead}</small>
            <h2 className="mb-32 amwayNumber">{profile && profile.accountMst.aboNum}</h2>
          </div>
          <div className="secForm">
            <img src="/images/IDCard.svg" alt="" />
            <div className="inputForm">
              <label className="abo">เบอร์โทรศัพท์มือถือ
              </label>
              <div className="relative">
                <input
                  value={tel}
                  pattern="[0-9]*"
                  type="tel"
                  maxLength="10"
                  minLength="10"
                  onChange={this.handleChange}
                  name="tel"
                  required
                  phone="true"
                  autoComplete="false"
                  className="center"
                  onPaste={this.onPaste}
                  placeholder=" "
                />
              </div>
            </div>
          </div>

          <div className="text-notice-new">เพื่อตรวจสอบกับฐานข้อมูลที่ท่านทำการสมัครไว้ <br /> และรักษาสิทธิประโยชน์ที่จะได้รับในอนาคต</div>
          <div className="abo-text-center">
            <div className="input-checkbox">
              <span onClick={this.handleCheckBox} className={`_box-check ${accept ? '_active' : ''}`}>
                {
                  accept ?
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.87987 11.8301L13.8799 3.83012L13.1199 3.17012L6.11987 11.1701L6.87987 11.8301ZM2.16987 8.38012L6.16987 11.8801L6.82987 11.1201L2.82987 7.62012L2.16987 8.38012ZM13.8799 3.83012C13.9232 3.78021 13.9563 3.72226 13.9772 3.65958C13.9982 3.59689 14.0066 3.53069 14.0019 3.46476C13.9973 3.39883 13.9797 3.33446 13.9502 3.27533C13.9207 3.21619 13.8798 3.16345 13.8299 3.12012C13.78 3.07678 13.722 3.0437 13.6593 3.02276C13.5966 3.00181 13.5304 2.99343 13.4645 2.99807C13.3986 3.00271 13.3342 3.0203 13.2751 3.04982C13.2159 3.07934 13.1632 3.12021 13.1199 3.17012L13.8799 3.83012ZM6.49987 11.5001L6.16987 11.8801C6.27139 11.966 6.40241 12.0088 6.53504 11.9994C6.66766 11.9901 6.79139 11.9293 6.87987 11.8301L6.49987 11.5001ZM2.82987 7.62012C2.72909 7.53259 2.59767 7.48869 2.46452 7.49807C2.33137 7.50745 2.20739 7.56933 2.11987 7.67012C2.03235 7.7709 1.98845 7.90232 1.99783 8.03547C2.0072 8.16862 2.06909 8.29259 2.16987 8.38012L2.82987 7.62012Z" fill="#2C2C2C" />
                    </svg> : null
                }
              </span>
              <Link onClick={this.handleCondition}>ยอมรับเงื่อนไข</Link>
            </div>
          </div>

          <div className="mt-32"><button className={`register-next-button ${isTrue ? 'active' : ''}`} type="submit" disabled={!isTrue}>ถัดไป</button></div>
        </form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showError: (message) => dispatch(alertActions.showError(message)),
  };
}

export default connect(null, mapDispatchToProps)(Foa);
