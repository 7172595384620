import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  addSelectedProductToCart,
  fetchBmiData,
  getAssessmentProducts,
  getAssessmentScore,
  getCartDetail,
} from '../../../../actions';
import { HnWDeleteModal, CartAssessmentHeader, CloudLoader } from '../../../../common';
import HnWContentModal from '../../../../common/hnWDeleteModal/HnWContentModal';
import { ASSESSMENT_QUESTION_TEMPLATE, DEFAULT_SCORE_RETRY_MODAL } from '../../../../common/commonConstant';
import { libraryImageUrl } from '../../../../utill.func';
import AnswerCard from './components/AnswerCard';
import AssessmentPopup from './components/AssessmentPopup';
import BannerCard from './components/BannerCard';
import ProductCarousel from './components/ProductCarousel';
import './Score.scss';
import { onlyUnique } from '../../../../services/healthWellness/healthWellness.service';
import { UserErrorModal } from '../components';
import { useLocation } from 'react-router-dom';

export const history = createBrowserHistory();
const liff = window.liff;

const Score = ({ routeTo }) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const dispatch = useDispatch();
  const bmi = useSelector((state) => state.bmi);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [assessmentId, setAssessmentId] = useState(false);
  const [scoreLoading, setScoreLoading] = useState(true);
  const [errorModal, setErrorModal] = useState(false);

  const {
    assessmentScore: ScoreData,
    assessmentProducts,
    customerCart,
  } = useSelector((state) => state.assessment);
  const [popupData, setPopupData] = useState({
    type: '',
    title: '',
    description: '',
    visible: false,
  });
  const recommendedProductsTypeMapping = useRef({});

  const handleChipClick = (type, title, content) => {
    setPopupData({ visible: true, type, title, content });
  };

  const handleAddProductEvent = (product, listName) => {
    if (product?.isSelected) {
      return;
    }
    try {
      setLoading(true);
      dispatch(addSelectedProductToCart(customerCart?.cartId, product))
        .then((res) => {
          const selectedProduct = assessmentProducts[listName]?.products?.find(
            (item) => item.id === product.id,
          );
          selectedProduct.isSelected = true;
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const closePopup = () => {
    setPopupData({ visible: false, type: '', title: '', content: '' });
  };

  const gotoHWHome = () => routeTo('pathHealthnWellness')

  const recommendedProductsIds = [];
  const upsellingProductsIds = [];

  const renderAnswerCard = (key, chipData) => {
    if (key === 'Others') {
      return;
    }
    return (
      <AnswerCard
        key={key}
        type={key}
        data={chipData}
        onChipClick={handleChipClick}
      />
    );
  }

  //extracting questions category based on type
  const RenderAnswerCards = useMemo(() => {
    if (ScoreData) {
      const customer = JSON.parse(sessionStorage.getItem('customer'));
      if (customer?.uid && ScoreData?.customerUid && customer?.uid !== ScoreData?.customerUid) {
        setErrorModal(true);
      }
      upsellingProductsIds.push(...ScoreData?.upselling_item);
      return Object.keys(ASSESSMENT_QUESTION_TEMPLATE)
        .map((key) => {
          const ScoreItems = ScoreData.hw_assessments.filter(
            (question) => question.question_type === key,
          );
          const chipData = [];
          ScoreItems.forEach((ScoreItem) => {
            const answers = ScoreItem.question_value.filter((question) =>
              ScoreItem.answer.includes(question._id),
            );
            chipData.push(...answers);
            const recommendedItems = chipData
              .map((value) => value.recommended_item)
              .flat();
            recommendedProductsIds.push(...recommendedItems);
            if (
              !recommendedProductsTypeMapping.current[ScoreItem.question_type]
            ) {
              recommendedProductsTypeMapping.current[ScoreItem.question_type] =
                recommendedItems;
            } else {
              recommendedProductsTypeMapping.current[
                ScoreItem.question_type
              ].push(...recommendedItems);
            }
          });
          return renderAnswerCard(key, chipData);
        });
    }
    return;
  }, [ScoreData]);

  useEffect(() => {
    const assessment_id = params.get('assessment_id');
    const answer_id = params.get('answer_id');
    setAssessmentId(assessment_id);

    try {
      setScoreLoading(true);
      dispatch(fetchBmiData())
        .then((res) => {
          dispatch(
            getAssessmentScore({
              answerId: answer_id,
              assessmentId: assessment_id,
            }),

          )
            .then((res) => {
              dispatch(getCartDetail('FULL')).then((cart) => {
                setScoreLoading(false);
              });
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      setScoreLoading(false);
      console.log(error);
    }
  }, []);

  useEffect(() => {
    const filteredRecommendedProductsIds = recommendedProductsIds.filter(onlyUnique);
    filteredRecommendedProductsIds.length &&
      dispatch(
        getAssessmentProducts(
          'recommendedProducts',
          filteredRecommendedProductsIds,
        ),
      );

    upsellingProductsIds.length &&
      dispatch(
        getAssessmentProducts('upsellingProducts', upsellingProductsIds),
      );
  }, [upsellingProductsIds.length, recommendedProductsTypeMapping.length]);

  useEffect(() => {
    assessmentProducts && setScoreLoading(false);
  }, [assessmentProducts]);

  const redirectToScorePage = () => {
    setOpenModal(false);
  };

  const redirectToAssessmentHome = () => {
    routeTo('pathHnWAssessmentHome', `?assessment_id=${assessmentId}`);
  };

  const btnTitle1 = `${DEFAULT_SCORE_RETRY_MODAL.btnTitle1}`;
  const btnTitle2 = `${DEFAULT_SCORE_RETRY_MODAL.btnTitle2}`;

  const renderContentModal = () => {
    return (
      <HnWContentModal btnTitle1={btnTitle1} btnTitle2={btnTitle2} assessmentId={assessmentId} btnOnClick1={redirectToAssessmentHome} btnOnClick2={redirectToScorePage} />
    );
  };

  const closeLiffWindow = async () => {
    await liff.closeWindow();
  }

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner
        text="Loading"
        styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
      />

      <CartAssessmentHeader
        rightFirstIcon={"/images/healthAndWellness/assessment/assessment_history_log.svg"}
        rightSecondIcon={"/images/healthAndWellness/assessment/assessment_cart.svg"}
        routeTo={routeTo}
      />

      {scoreLoading ?
        <CloudLoader /> :
        <div className="search-result-container">
          <div className="search-banner-wrapper-container">
            <img
              alt="assessment-score-banner-img"
              src={libraryImageUrl(ScoreData?.score_image_url)}
              className="w-100"
            />
            <BannerCard bmiData={bmi} />
          </div>
          <div className="bg-container">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="lifestyle-suggestion-container">
                    <div className="lifestyle-suggestions-title green">
                      คะแนนของคุณ
                    </div>
                    <div className="lifestyle-suggestions-description">
                      {`${ScoreData?.score_title} : `}
                      <span className="green">
                        {ScoreData?.min_score}-{ScoreData?.max_score}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="your-answer-container">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <h6 className="your-answer-title green">คำตอบของคุณ</h6>
                  {RenderAnswerCards}
                </div>
              </div>
            </div>
          </div>
          <div className="product-slider-wrapper-container">
            <img
              src="/images/healthAndWellness/assessment/bg_wave.svg"
              className="product-slider-top-img"
            />
            <div className="products-slider-container">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="product-title green">สินค้าที่เหมาะกับไลฟ์สไตล์</div>
                    <div className="products-recommend-container slick-slider slick-initialized">
                      {assessmentProducts?.recommendedProducts?.products && (
                        <ProductCarousel
                          typeMapping={recommendedProductsTypeMapping.current}
                          data={assessmentProducts.recommendedProducts.products}
                          handleAddProductEvent={(product) => {
                            handleAddProductEvent(product, 'recommendedProducts');
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="product-title">สินค้าที่คุณอาจจะสนใจ</div>
                    <div className="products-recommend-container slick-slider slick-initialized">
                      {assessmentProducts?.upsellingProducts?.products && (
                        <ProductCarousel
                          data={assessmentProducts.upsellingProducts.products}
                          handleAddProductEvent={(product) => {
                            handleAddProductEvent(product, 'upsellingProducts');
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-result-footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="search-result-footer-btns d-flex">
                    <button className="score-footer-btn home-btn" onClick={gotoHWHome}>
                      <img src="/images/healthAndWellness/assessment/score-home.svg" className="home-btn-img" />
                      <span>กลับไปหน้าหลัก</span>
                    </button>
                    <button
                      className="score-footer-btn retry-btn"
                      onClick={() => setOpenModal(true)}
                    >
                      <img src="/images/healthAndWellness/assessment/score-repeat.svg" className="retry-btn-img" />
                      <span>ทำใหม่อีกครั้ง</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AssessmentPopup
            type={popupData.type}
            onClick={closePopup}
            isOpen={popupData.visible}
            title={popupData.title}
          >
            {popupData.content}
          </AssessmentPopup>
        </div>
      }
      <HnWDeleteModal
        onClose={() => setOpenModal(false)}
        isOpen={openModal} title={DEFAULT_SCORE_RETRY_MODAL.title}
        subtitle={[DEFAULT_SCORE_RETRY_MODAL.subtitle]}
      >
        <div className="scroll">
          {renderContentModal()}
        </div>
      </HnWDeleteModal>
      {errorModal &&
        <UserErrorModal
          isOpen={errorModal}
          onClose={() => closeLiffWindow()}
        >
        </UserErrorModal>
      }
    </>
  );
};

export default Score;
