import React from "react";
import { TeamRectCard } from "../../../../common";
import { CHALLENGE } from "../../../../common/challengeConstants";
import './ChallengeMyStepsCard.scss';

const ChallengeMyStepsCard = ({ userData, teamDashboardStatus }) => {
    
    return (
        <div className="challenge-my-steps-container">
            <div className="challenge-card-body">
            <TeamRectCard
                    title={CHALLENGE.myDashboard.stepTodayHeading}
                    quantity={userData?.todaySteps}
                    amount={CHALLENGE.myDashboard.maxUserSteps}
                    stepsUnit={CHALLENGE.myDashboard.stepsUnit}
                    teamDashboardStatus={teamDashboardStatus}
                />
                <hr className="rounded-dividing-line"></hr>
                <div className="total-steps">
                <TeamRectCard
                    title={CHALLENGE.myDashboard.accStepHeading}
                    quantity={userData?.userTotalSteps}
                    amount={' '}
                    stepsUnit={CHALLENGE.myDashboard.stepsUnit}
                    teamDashboardStatus={teamDashboardStatus}
                />
                </div>
            </div>
        </div>
    )
}

export default ChallengeMyStepsCard;