import React from 'react'
import { DRINK_NAME, DRINK_STATUS } from '../../../../../../constants';
import { HNW_FOOD_LOG } from '../../../../../../common/commonConstant';
import { formatNumberWithCommas } from '../../../../../../utill.func';

const FoodLogDrinksWrapper = ({ data, header, label, volumeData, drinkName, cautionMsg, handleVolumeEdit, children }) => {

    const volume = data.filter(el => el.status === DRINK_STATUS.full || el.status === DRINK_STATUS.half)
        .reduce((acc, curr) => {
            const intake = drinkName === DRINK_NAME.water ? curr.waterIntake : drinkName === DRINK_NAME.coffee ? curr.coffeeIntake :
                curr.softDrinkIntake;
            return acc + intake;
        }, 0);

    return (
        <div className="food-overview-drink-card">
            <div className="food-overview-drink-card-header">
                <div className="food-overview-drink-card-header-left">
                    <div className="food-overview-drink-card-header-left-content"> 
                        <div className="food-overview-drink-card-header-name">{header}</div>
                        <div className="food-overview-drink-card-header-value">({volumeData ?? '-'} {label})</div>
                    </div>
                    { volume > 0 && (<div className="food-overview-drink-card-header-left-content">
                        <div>{HNW_FOOD_LOG.labelMapping['together']}</div>
                        <div>{formatNumberWithCommas(volume)} {label}</div>
                    </div> )}
                </div>
                <div className="food-overview-drink-card-header-right">
                    <button onClick={() => handleVolumeEdit(drinkName)} className="btn btn-edit">
                        <img src="/images/healthAndWellness/foodLog/icons/edit.svg" />
                    </button>
                </div>
            </div>
                {children}
            {
                cautionMsg &&
                <div className="food-overview-drink-card-suggestion">
                    <img src="/images/healthAndWellness/foodLog/icons/caution.svg" />
                    <div className="food-overview-drink-card-suggestion-title">{cautionMsg}</div>
                </div>
            }
        </div>
    )
}

export default FoodLogDrinksWrapper