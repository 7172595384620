import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { osName } from 'react-device-detect';
import LoadingOverlay from 'react-loading-overlay';
import _ from 'lodash';
import { createBrowserHistory } from 'history';
import { connect } from 'react-redux';

import { alertActions } from '../../actions';
import { commonConstant } from '../../common';
import { Modal } from '../../helpers';
import { customerService, activityService, amwayService } from '../../services';
import Condition from '../condition/condition.jsx';

export const history = createBrowserHistory();

class Abo extends Component {
  constructor(props) {
    super(props);

    if (sessionStorage.getItem('customer') === null || sessionStorage.getItem('customer') === 'undefined') {
      window.location.replace(commonConstant.pathHome);
    }

    const customer = JSON.parse(sessionStorage.getItem('customer'));

    if (!customer.type) {
      return window.location.replace(commonConstant.pathHome);
    }

    this.state = {
      customer: customer,
      DistNum: '',
      cid: '',
      loading: false,
      error: false,
      show: false,
      accept: false,
      condition: false,
      isOpenModal: false,
      modalContent: '',
      resMemberNumber: null,
      profile: ''
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (!location.state || !location.state.myMember || location.state.myMember.accountMst.accountTypeCd !== 'AmwayBusiness') return window.location.replace(commonConstant.pathHome);
    this.setState({ profile: location.state.myMember })
  }

  onPaste = e => {
    e.preventDefault();
    return false;
  };

  handleChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || e.target.value.match(regex)) {
      this.setState({ [e.target.name]: e.target.value });
    }
  }
  handleCheckBox = () => this.setState({ accept: !this.state.accept })
  handleCondition = () => this.setState({ condition: !this.state.condition })
  toggleModal = (content, resMemberNumber = null) => {
    this.setState({
      isOpenModal: !this.state.isOpenModal,
      modalContent: content,
      resMemberNumber,
    });
  }

  handleModalOk = () => {
    const { modalContent, isOpenModal } = this.state;
    this.toggleModal();
    switch (modalContent) {
      case 'duplicate':
        this.setState({ isOpenModal: !isOpenModal });
        return this.props.history.push('/changeMID', { myMember: this.props.location.state.myMember, oldLineId: this.state.oldLineId, oldMember: this.state.oldMember })
      default:
        break;
    }
  }

  handleModalContent = () => {
    const { modalContent, profile } = this.state;
    switch (modalContent) {
      case 'duplicate':
        return { title: `รหัส ${profile.accountMst.aboNum} มีการผูกกับ LINE ID อื่น`, detail: 'กรุณาคลิกปุ่มเปลี่ยนบัญชี เพื่อลงทะเบียน\nAmway Social Connect', type: 'warning' };
      case 'card':
        return { title: null, detail: '<img src="/images/id-card-hint.png" alt="" />' };
      case 'wrongCid':
        return { title: 'ข้อมูลนักธุรกิจไม่ถูกต้อง', detail: 'กรุณาตรวจสอบเลขบัตรประชาชน\nใหม่อีกครั้ง', type: 'warning' }
      default:
        break;
    }
  }

  handleSubmit = async (e) => {
    window.scrollTo(0, 1);
    const { cid, profile } = this.state;
    console.log(cid)
    console.log(profile)
    const amwayNumber = profile.accountMst.aboNum
    e.preventDefault();
    this.setState({ loading: true });

    // const searchKey = amwayNumber + cid;

    const cleanData = _.filter(profile.partyList, function (o) {
      return o.taxList[0] && o.taxList[0].taxTypeCd === 'CID' && o.partyMst && o.partyMst.roleCd && o.partyMst.roleCd === 'BusinessOwner';
    });
    const last4Digit = cleanData.map(function (raw) {
      return raw.taxList[0].taxId;
    });

    const accountProfile = _.filter(profile.partyList, function (o) { return o.taxList[0] && o.taxList[0].taxId.substr(-4) === cid; });
    const profileSchema = {
      familyName: '',
      givenName: '',
      middleName: '',
      suffix: '',
      title: '',
    };

    if (accountProfile.length > 0) {
      const localNameObj = accountProfile[0].nameList[0].localeName;
      profileSchema.familyName = (localNameObj.familyName !== null) ? localNameObj.familyName : '';
      profileSchema.givenName = (localNameObj.givenName !== null) ? localNameObj.givenName : '';
      profileSchema.middleName = (localNameObj.middleName !== null) ? localNameObj.middleName : '';
      profileSchema.suffix = (localNameObj.suffix !== null) ? localNameObj.suffix : '';
      profileSchema.title = (localNameObj.title !== null) ? localNameObj.title : '';
    }

    const AboName = `${profileSchema.givenName} ${profileSchema.familyName}`;

    let found = false;
    let citizenId = cid;
    for (let i in last4Digit) {
      if (last4Digit[i].substr(-4) === cid) {
        found = true;
        citizenId = last4Digit[i];
        break;
      }
    }

    if (!found) {
      this.setState({ isOpenModal: false, loading: false });
      this.toggleModal('wrongCid');
      return;
    }

    const resMemberNumber = await customerService.findMemberNumber(amwayNumber);
    if (resMemberNumber && (resMemberNumber.uid !== this.state.customer.uid) && (cid === resMemberNumber.cid)) {
      this.setState({ isOpenModal: false, loading: false, oldLineId: resMemberNumber.uid, oldMember: resMemberNumber });
      this.toggleModal('duplicate', resMemberNumber);
      return;
    }



    let partyId = null;
    profile.partyList.map(party => {
      const tax = party.taxList[0]
      if (tax.taxTypeCd === 'CID' && citizenId === tax.taxId) {
        partyId = tax.partyId;
      }
    })

    console.log("partyId", partyId);
    const customer = {
      name: AboName,
      _id: this.state.customer._id,
      cid: citizenId.substr(-4),
      info: osName === 'IOS' ? 'iPhone' : osName,
      amwayNumber: parseInt(amwayNumber),
      // partyId: profile.accountMst.primaryPersonPartyId,
      partyId: partyId,
      socialType: 'LINE',
    };

    const updated = await customerService.update(customer._id, customer);
    console.log("updated customer", updated);
    if (!updated._id) {
      const errorMessage = updated.message instanceof Object ? updated.message.map((value) => Object.values(value.constraints).join('<br>')) : 'ระบบไม่สามารถบันทึกข้อมูลได้กรุณาติดต่อเจ้าหน้าที่';

      this.props.showError(errorMessage);
      this.setState({ loading: false });

      return false;
    }
    updated.name = AboName;
    sessionStorage.setItem('customer', JSON.stringify(updated));

    const params = {
      mid: this.state.customer.uid,
      cid: citizenId,
      customerId: this.state.customer._id,
      info: osName === 'IOS' ? 'iPhone' : osName,
      status: 'unblock',
      displayname: this.state.customer.displayName,
    };
      const saveLineMID = await amwayService.saveLineMID(params);
      const { replymessage } = saveLineMID.data.lineMIDResult;

      if (replymessage !== 'Success') {
      this.props.showError('ระบบไม่สามารถบันทึกข้อมูลได้กรุณาติดต่อเจ้าหน้าที่');
      this.setState({ loading: false });
      return false;
    }

    activityService.create({
      uid: this.state.customer.uid,
      userId: this.state.customer._id,
      status: 'incomplete',
      action: 'VERIFY_ABO',
    });
    // ------- End ABO Verify --------------------------------------------------------------
    console.log("End ABO Verify");

    return this.props.history.push('/success', { 'user': updated });
  };

  render() {
    const { cid, isOpenModal, accept, condition, modalContent, profile } = this.state;

    const isTrue = cid.length === 4 && accept;
    const content = this.handleModalContent();
    if (condition) { return <Condition fn={this.handleCondition} />; }
    return (
      <div className="regisPage">
        <LoadingOverlay active={this.state.loading} spinner text="Loading" styles={{ wrapper: { width: '100%', minHeight: '100%' } }} />
        {
          isOpenModal ?
            <Modal
              title={content.title}
              detail={content.detail}
              type='warning'
              closeBtn
              fnClose={this.toggleModal}
              fnOk={this.handleModalOk}
              okText={modalContent !== 'duplicate' ? 'ตกลง' : 'เปลื่ยนบัญชี'}
              okBtn={modalContent !== 'card'} /> : null
        }
        <form onSubmit={this.handleSubmit}>
          <div className="logoAmway"><img src="/images/logoAmway-black.svg" alt="" /></div>
          <h2 className="mb-90">กรุณากรอกข้อมูลลงทะเบียน</h2>
          <div className="amwayNumber-container">
            <small>หมายเลขนักธุรกิจแอมเวย์</small>
            <h2 className="mb-32 amwayNumber">{profile && profile.accountMst.aboNum}</h2>
          </div>
          <div className="secForm">
            <img src="/images/IDCard.svg" alt="" />
            <div className="inputForm">
              <label className="abo">หมายเลขบัตรประชาชน 4 หลักสุดท้าย
              </label>
              <div className="relative">
                <input
                  value={cid}
                  pattern="[0-9]*"
                  type="tel"
                  maxLength="4"
                  minLength="4"
                  onChange={this.handleChange}
                  name="cid"
                  required
                  phone="true"
                  autoComplete="false"
                  className="center"
                  onPaste={this.onPaste}
                  placeholder=" "
                />
                <i className="question-mark" onClick={() => this.toggleModal('card')}>
                  <svg width="14" height="14" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.84375 5.75C9.84375 3.09375 7.65625 0.90625 5 0.90625C2.32422 0.90625 0.15625 3.09375 0.15625 5.75C0.15625 8.42578 2.32422 10.5938 5 10.5938C7.65625 10.5938 9.84375 8.42578 9.84375 5.75ZM5.11719 2.50781C6.23047 2.50781 7.40234 3.36719 7.40234 4.5C7.40234 6.02344 5.78125 6.04297 5.78125 6.58984V6.60938C5.78125 6.74609 5.66406 6.84375 5.54688 6.84375H4.45312C4.31641 6.84375 4.21875 6.74609 4.21875 6.60938V6.53125C4.21875 5.73047 4.82422 5.41797 5.27344 5.16406C5.66406 4.94922 5.91797 4.79297 5.91797 4.5C5.91797 4.10938 5.41016 3.85547 5.01953 3.85547C4.49219 3.85547 4.25781 4.10938 3.90625 4.55859C3.82812 4.65625 3.67188 4.67578 3.57422 4.59766L2.89062 4.08984C2.79297 4.01172 2.77344 3.875 2.85156 3.75781C3.37891 2.95703 4.0625 2.50781 5.11719 2.50781ZM5 7.35156C5.48828 7.35156 5.89844 7.76172 5.89844 8.25C5.89844 8.75781 5.48828 9.14844 5 9.14844C4.49219 9.14844 4.10156 8.75781 4.10156 8.25C4.10156 7.76172 4.49219 7.35156 5 7.35156Z" fill="#2C2C2C" />
                  </svg>
                </i>
              </div>
            </div>
          </div>

          <div className="text-notice-new">เพื่อตรวจสอบกับฐานข้อมูลที่ท่านทำการสมัครไว้ <br /> และรักษาสิทธิประโยชน์ที่จะได้รับในอนาคต</div>
          <div className="abo-text-center">
            <div className="input-checkbox">
              <span onClick={this.handleCheckBox} className={`_box-check ${accept ? '_active' : ''}`}>
                {
                  accept ?
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.87987 11.8301L13.8799 3.83012L13.1199 3.17012L6.11987 11.1701L6.87987 11.8301ZM2.16987 8.38012L6.16987 11.8801L6.82987 11.1201L2.82987 7.62012L2.16987 8.38012ZM13.8799 3.83012C13.9232 3.78021 13.9563 3.72226 13.9772 3.65958C13.9982 3.59689 14.0066 3.53069 14.0019 3.46476C13.9973 3.39883 13.9797 3.33446 13.9502 3.27533C13.9207 3.21619 13.8798 3.16345 13.8299 3.12012C13.78 3.07678 13.722 3.0437 13.6593 3.02276C13.5966 3.00181 13.5304 2.99343 13.4645 2.99807C13.3986 3.00271 13.3342 3.0203 13.2751 3.04982C13.2159 3.07934 13.1632 3.12021 13.1199 3.17012L13.8799 3.83012ZM6.49987 11.5001L6.16987 11.8801C6.27139 11.966 6.40241 12.0088 6.53504 11.9994C6.66766 11.9901 6.79139 11.9293 6.87987 11.8301L6.49987 11.5001ZM2.82987 7.62012C2.72909 7.53259 2.59767 7.48869 2.46452 7.49807C2.33137 7.50745 2.20739 7.56933 2.11987 7.67012C2.03235 7.7709 1.98845 7.90232 1.99783 8.03547C2.0072 8.16862 2.06909 8.29259 2.16987 8.38012L2.82987 7.62012Z" fill="#2C2C2C" />
                    </svg> : null
                }
              </span>
              <Link onClick={this.handleCondition}>ยอมรับเงื่อนไข</Link>
            </div>
          </div>

          <div className="mt-32"><button className={`register-next-button ${isTrue ? 'active' : ''}`} type="submit" disabled={!isTrue}>ถัดไป</button></div>
        </form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showError: (message) => dispatch(alertActions.showError(message)),
  };
}

export default connect(null, mapDispatchToProps)(Abo);
