import React, { Component } from 'react'
import { connect } from "react-redux"
import LoadingOverlay from 'react-loading-overlay';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';


import { customerService, activityService } from "../../services"
import { createBrowserHistory } from 'history';
import { alertActions, customerFetch } from "../../actions"
import { deepEqual, Modal } from '../../helpers';


const liff = window.liff;

export const history = createBrowserHistory();

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: "",
            profile: [],
            customer: [],
            show: false,
            error: false,
            loading: false,
            distNum: '',
            isOpenModal: false,

        }
    }

    componentDidMount() {
        if (!isEmpty(this.props.customer) && !isEmpty(this.props.profile)) {
            this.setState({
                show: true
            })
        }
    }

  handleButton = () => {
    liff.closeWindow();
  }

    async componentWillReceiveProps(nextProps) {
        let customer = nextProps.customer;
        const { profile } = nextProps;
        if (profile && !deepEqual(this.props.profile, profile)) {
            // let customer = await customerService.get(nextProps.profile.userId)
            this.props.getCustomer();
        }
        if (profile && (!deepEqual(this.props.customer, customer) || customer.hasOwnProperty('action'))) {
            if (!customer) {
                const params = {
                    registerStatus: "incomplete",
                    action: "SELECT_TYPE",
                    displayName: nextProps.profile.displayName,
                    pictureUrl: nextProps.profile.pictureUrl,
                    uid: nextProps.profile.userId,
                    status: "unblock"
                }

                customer = await customerService.create(params)
            }
            if (!customer.pictureUrl || (customer.pictureUrl !== nextProps.profile.pictureUrl)) {
                await customerService.update(customer._id, { pictureUrl: nextProps.profile.pictureUrl })
            }
            sessionStorage.setItem("customer", JSON.stringify(customer))
            console.log(customer)

            switch (customer.action) {
                case "REGISTER_COMPLETE":
                    // if (!customer.answerGroupSegmentId) {
                    //     return this.props.history.push("/question")
                    // }
                    if (customer.type !== 'public') {
                        return this.props.history.push("/success")
                    }

                // return this.props.history.push("/question")
                default:
                    break;
            }
            this.setState({
                show: true,
                customer: customer
            })
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        this.setState({
            'loading': true
        })

        const customer = JSON.parse(sessionStorage.getItem("customer"))

        const resMemberNumber = await customerService.findMemberbyABONumber(this.state.distNum);

        if (!resMemberNumber) return this.setState({ isOpenModal: true, loading: false })

        let type;
        switch(resMemberNumber.accountMst.accountTypeCd){
            case 'AmwayBusiness':
                type = 'abo';
                break;
            case 'Member':
                type = 'member';
                break;
            case 'Customer':
                type = 'foa';
                break;
        }
        if (resMemberNumber.accountMst.accountSubTypeCd === 'Employee'){type = 'employee';}

        const updated = await customerService.update(customer._id, { type })
        sessionStorage.setItem("customer", JSON.stringify(updated))

        const params = {
            uid: this.state.customer.uid,
            userId: customer._id,
            status: "incomplete",
            action: "SELECT_TYPE"
        }
        activityService.create(params)

        if (resMemberNumber.accountMst.accountTypeCd === 'Member') return this.props.history.push("/member", { myMember: resMemberNumber })
        if (resMemberNumber.accountMst.accountTypeCd === 'AmwayBusiness') return this.props.history.push("/abo", { myMember: resMemberNumber })
        if (resMemberNumber.accountMst.accountTypeCd === 'Customer') {
           return this.props.history.push("/foa", { myMember: resMemberNumber })
        }
    }

    handlePublic = async (e) => {
        e.preventDefault()
        this.setState({
            'loading': true
        })
        const customer = JSON.parse(sessionStorage.getItem("customer"))

        const updated = await customerService.update(customer._id, { type: "public" })
        updated.type = "public"
        sessionStorage.setItem("customer", JSON.stringify(updated))

        const params = {
            uid: this.state.customer.uid,
            userId: customer._id,
            status: "incomplete",
            action: "SELECT_TYPE"
        }
        activityService.create(params)

        return this.props.history.push(`/public`)
    }

    handleChange = e => this.setState({ [e.target.name]: e.target.value });
    handleDistNumChange = e => this.setState({ distNum: e.target.value.replace(/[^0-9.]/g, '') });

    handleModalOk = () => this.setState({ isOpenModal: false })



    render() {
        const { location } = this.props;
        const { distNum, show, loading, isOpenModal } = this.state;
        const isTrue = distNum.length >= 5 ? true : false;


        if (!show) {
            return (
                <div className="regisPage">
                    <LoadingOverlay
                        active={true}
                        spinner
                        text='Loading'
                        styles={{ wrapper: { width: "100vh", height: "100vh", position: "absolute", pointerEvents: "none" } }}
                    />
                </div>
            )
        }

        return (

            <div className="regisPage home">
                <LoadingOverlay
                    active={loading}
                    spinner
                    text='Loading'
                    styles={{ wrapper: { width: "100vh", height: "100vh", position: "absolute", pointerEvents: "none" } }}
                />
                {
                    isOpenModal &&
                    <Modal
                        title="ไม่พบข้อมูล"
                        detail={"กรุณาตรวจสอบหมายเลขนักธุรกิจ\nหรือหมายเลขสมาชิกใหม่อีกครั้ง"}
                        type="warning"
                        fnOk={this.handleModalOk}
                        okText={'ตกลง'} />
                }
                <div className="logoAmway"><img src="/images/logoAmway-black.svg" alt="" /></div>

                <h2 className="title">กรอกข้อมูลลงทะเบียน</h2>
                <small className="mb-40 subtitle">เพื่อรับข้อมูลโปรโมชั่นและสิทธิประโยชน์</small>

                <form onSubmit={this.handleSubmit}>
                    <div className="secForm homeForm">
                        <img src="/images/User.svg" alt="" />
                        <div className="inputForm">
                            <label>หมายเลขนักธุรกิจ/สมาชิกแอมเวย์</label>
                            <input
                                value={distNum}
                                pattern="[0-9]*"
                                type="tel"
                                minLength="5"
                                maxLength="15"
                                onChange={this.handleDistNumChange}
                                onPaste={this.onPaste}
                                name="distNum"
                                required
                                phone="true"
                                className="center"
                                placeholder=" "
                            />
                        </div>

                    </div>
                    <Link className="forget-password" to={{
                        pathname: '/forgot',
                        state: { from: location },
                    }}>ลืมหมายเลขนักธุรกิจ/สมาชิกแอมเวย์</Link>

                    <Link onClick={this.handlePublic}><p className="to-public mt-90">สมัครแบบบุคคลทั่วไป</p></Link>

                    <div className="text-center mt-90">
                        <button className={`register-next-button ${isTrue ? 'active' : ''}`} type="submit" disabled={!isTrue}>ถัดไป</button>
                    </div>

                </form>
            </div>
        )
    }
}

function mapStateToProps({ profile, customer }) {

    return {
        profile,
        customer
    }
}

function mapDispatchToProps(dispatch) {

    return ({
        showError: (message) => dispatch(alertActions.showError(message)),
        getCustomer: () => dispatch(customerFetch())
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)