import React, { useEffect } from 'react'
import './assessment.scss';
import SlickCarousel from '../../../../common/slickCarousel/slickCarousel';
import { HNW_ASSESSMENT_PATH_KEY } from '../../../../common/commonConstant';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAssessmentList } from '../../../../thunk/hnwHome.thunk';

const Assessment = ({ routeTo }) => {

    const dispatch = useDispatch();
    const { assessmentList } = useSelector(state => state.home);

    useEffect(() => {
        if (!assessmentList?.length) {
            dispatch(fetchAssessmentList());
        }
    }, []);

    const route = item => {
        if (item.path === HNW_ASSESSMENT_PATH_KEY) {
            routeTo(item.path, `?assessment_id=${item.id}`);
        } else {
            routeTo(item.path);
        }
    }

    const renderList = (item, index) => {
        return (
            <div onClick={() => route(item)} key={`assessment${index}`} className="position-relative assessment-content-container">
                <img src={item.imageUrl} className="w-100 img-container" />
                <div className="health-assessment-button-wrapper text-center">
                    <button className="btn btn-primary btn-rounded">ทำแบบประเมิน</button>
                </div>
            </div>
        )
    }

    return (
        <div className="container-fluid home-assessment-container">
            <div className="row position-relative">
                <div className="col-12">
                    <h5 className="health-assessment-title">แบบประเมินสุขภาพ</h5>
                    <img src="/images/healthAndWellness/plant.svg" className="plant-img" />
                </div>
            </div>
            <div className='assessment-carousel-container'>
                <SlickCarousel>
                    {
                        assessmentList?.length ?
                            assessmentList.map((item, index) => (
                                renderList(item, index)
                            )) : null
                    }
                </SlickCarousel>
            </div>
        </div >
    )
}

export default Assessment