import React, { useState, useEffect, useMemo, useCallback } from 'react';
import './ExerciseLogDetail.scss';
import commonConstant, {
  ERROR_TYPE,
  EXERCISE_LOG_DETAIL,
  HNW_EXERCISE_LOG_DETAIL_INITIAL_STATE,
  HNW_EXERCISE_LOG_ERROR_INITIAL_STATE,
  LABEL_EN_MANUAL_LOG_TAB,
  LABEL_EN_SUGGEST_LOG_TAB,
  SUCCESS_TYPE,
  EXERCISE_TIME_KEY,
  CALORIES_BURN_KEY,
  START_TIME_KEY,
  END_TIME_KEY,
  CALORIE_KEY,
  NO_OF_TIMES_KEY,
  AVG_HEART_RATE_KEY,
  MAX_HEART_RATE_KEY,
  DIST_COVERED_KEY,
  AVG_SPEED_KEY,
  SLOPE_KEY,
  CADENCE_KEY,
  LATENCY_LEVEL_KEY,
} from '../../../../common/commonConstant';
import BasicTab from '../../../shared/tab/BasicTab';
import ExerciseSuggestLogPage from '../components/exerciseSuggestLogPage/ExerciseSuggestLogPage';
import ExerciseManualLogPage from '../components/exerciseManualLogPage/ExerciseManualLogPage';
import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import {
  createExerciseLog,
  fetchExerciseLogById,
  fetchExerciseMenuAndTemplatesById,
  removeExerciseLogById,
  updateExerciseLog,
} from '../../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { CreateOrUpdateExerciseLogModel } from '../../../../model/ExerciseLog.model';
import {
  CalendarLoader,
  HealthDeleteFoodModal,
  HnwSuccessOverlayModal,
} from '../../../../common';
import SavedSuccessful from '../../../../json/saved-successful.json';
import CancelSuccessful from '../../../../json/cancel-successful.json';
import { checkIsIOS, timeMoment } from '../../../../utill.func';
import { useLocation } from 'react-router-dom';

export const history = createBrowserHistory();

export default function ExerciseLogDetail({ routeTo }) {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const exerciseMenuId = params.get('exerciseMenuId');
  const exerciseLogId = params.get('exerciseLogId');
  const type = params.get('type');
  const suggestedCalorie = params.get('suggestedCalorie');
  const headerClass = exerciseLogId && type ? 'headerClass' : '';

  const [isLoading, setLoading] = useState(false);
  const [showSaveSuccessModal, setSaveSuccessModal] = useState(false);
  const [showCancelSuccessModal, setCancelSuccessModal] = useState(false);
  const [showConfirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [activeTab, setActiveTab] = useState(LABEL_EN_SUGGEST_LOG_TAB);
  const [exerciseLogDetail, setExerciseLogDetail] = useState(
    HNW_EXERCISE_LOG_DETAIL_INITIAL_STATE,
  );
  const [exerciseLogError, setExerciseLogError] = useState(
    HNW_EXERCISE_LOG_ERROR_INITIAL_STATE,
  );

  const [keypadOpen, setKeypadOpen] = useState(false);
  const [scrollBottom, setScrollBottom] = useState(false);

  const { exerciseLogData, exerciseMenu } = useSelector(
    (state) => state.exerciseLog,
  );

  useEffect(() => {
    if (type) {
      setActiveTab(type);
    }
  }, [type]);

  useEffect(() => {
    if (exerciseMenuId) {
      setLoading(true);
      const didMount = async (emId) => {
        await dispatch(fetchExerciseMenuAndTemplatesById(emId));
        setLoading(false);
      };
      didMount(exerciseMenuId);
    }
  }, []);

  useEffect(() => {
    if (exerciseLogId) {
      setLoading(true);
      const didMount = async (elId) => {
        await dispatch(fetchExerciseLogById(elId));
        setLoading(false);
      };
      didMount(exerciseLogId);
    }
  }, [exerciseLogId]);

  useEffect(() => {
    const exerciseTimeValue = exerciseLogId
      ? parseInt(exerciseLogData?.[EXERCISE_TIME_KEY])
      : exerciseMenu?.suggestionTime;
    const caloriesBurnValue = exerciseLogId
      ? parseInt(exerciseLogData?.[CALORIES_BURN_KEY])
      : parseInt(exerciseTimeValue) * exerciseMenu?.calorieBurnTime;
    if (exerciseLogData) {
      setExerciseLogDetail({
        id: exerciseLogData?.id,
        type: exerciseLogData?.type,
        [EXERCISE_TIME_KEY]: exerciseTimeValue,
        [CALORIES_BURN_KEY]: caloriesBurnValue,
        [START_TIME_KEY]: exerciseLogData?.[START_TIME_KEY],
        [END_TIME_KEY]: exerciseLogData?.[END_TIME_KEY],
        [CALORIE_KEY]: exerciseLogData?.[CALORIES_BURN_KEY],
        [NO_OF_TIMES_KEY]: exerciseLogData?.[NO_OF_TIMES_KEY],
        [AVG_HEART_RATE_KEY]: exerciseLogData?.[AVG_HEART_RATE_KEY],
        [MAX_HEART_RATE_KEY]: exerciseLogData?.[MAX_HEART_RATE_KEY],
        [DIST_COVERED_KEY]: exerciseLogData?.[DIST_COVERED_KEY],
        [AVG_SPEED_KEY]: exerciseLogData?.[AVG_SPEED_KEY],
        [SLOPE_KEY]: exerciseLogData?.[SLOPE_KEY],
        [CADENCE_KEY]: exerciseLogData?.[CADENCE_KEY],
        [LATENCY_LEVEL_KEY]: exerciseLogData?.[LATENCY_LEVEL_KEY],
      });
    }
    if (exerciseLogData && exerciseLogId) {
      setExerciseLogError({
        [EXERCISE_TIME_KEY]: exerciseTimeValue ? SUCCESS_TYPE : '',
        [CALORIES_BURN_KEY]: caloriesBurnValue ? SUCCESS_TYPE : '',
        [START_TIME_KEY]: exerciseLogData?.[START_TIME_KEY] ? SUCCESS_TYPE : '',
        [END_TIME_KEY]: exerciseLogData?.[END_TIME_KEY] ? SUCCESS_TYPE : '',
        [CALORIE_KEY]: exerciseLogData?.[CALORIES_BURN_KEY] ? SUCCESS_TYPE : '',
        [NO_OF_TIMES_KEY]: exerciseLogData?.[NO_OF_TIMES_KEY]
          ? SUCCESS_TYPE
          : '',
        [AVG_HEART_RATE_KEY]: exerciseLogData?.[AVG_HEART_RATE_KEY]
          ? SUCCESS_TYPE
          : '',
        [MAX_HEART_RATE_KEY]: exerciseLogData?.[MAX_HEART_RATE_KEY]
          ? SUCCESS_TYPE
          : '',
        [DIST_COVERED_KEY]: exerciseLogData?.[DIST_COVERED_KEY]
          ? SUCCESS_TYPE
          : '',
        [AVG_SPEED_KEY]: exerciseLogData?.[AVG_SPEED_KEY] ? SUCCESS_TYPE : '',
        [SLOPE_KEY]: exerciseLogData?.[SLOPE_KEY] ? SUCCESS_TYPE : '',
        [CADENCE_KEY]: exerciseLogData?.[CADENCE_KEY] ? SUCCESS_TYPE : '',
        [LATENCY_LEVEL_KEY]: exerciseLogData?.[LATENCY_LEVEL_KEY]
          ? SUCCESS_TYPE
          : '',
      });
    }
  }, [exerciseLogData, exerciseLogId, exerciseMenu]);

  const handleKeypadOpen = (showKeypad) => {
    setKeypadOpen(showKeypad);
  };

  const handleScrollBottom = (scrollPage) => {
    setScrollBottom(scrollPage);
  };

  useEffect(() => {
    if (scrollBottom) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'auto',
      });
    }
    setScrollBottom(false);
  }, [scrollBottom])

  const calculateDifferenceValidation = (name, value) => {
    let startTime;
    let endTime;
    let difference = true;
    switch (name) {
      case START_TIME_KEY:
        startTime = timeMoment(value);
        endTime = timeMoment(exerciseLogDetail.endTime);
        difference = !!endTime ? endTime.diff(startTime, 'minutes') : true;
        if (Math.sign(difference) === -1 || Math.sign(difference) === -0) {
          difference = false;
        }
        return difference;
      case END_TIME_KEY:
        startTime = timeMoment(exerciseLogDetail.startTime);
        endTime = timeMoment(value);
        difference = !!startTime ? endTime.diff(startTime, 'minutes') : true;
        if (Math.sign(difference) === -1 || Math.sign(difference) === -0) {
          difference = false;
        }
        return difference;
      default:
        return difference;
    }
  };

  const onChangeValue = useCallback(
    (name, value, options = null) => {
      let caloriesBurnValue = exerciseLogDetail.caloriesBurn;
      let isInRange = true;
      let validateValue = true;
      let validateCalorieValue = true;
      let isCalorieChanged = false;
      switch (name) {
        case EXERCISE_TIME_KEY:
          caloriesBurnValue = parseInt(value) * exerciseMenu?.calorieBurnTime;
          isCalorieChanged = true;
          isInRange =
            parseInt(value) >=
            EXERCISE_LOG_DETAIL.LOG_DETAIL.EXERCISE_TIME_MIN_VALUE &&
            parseInt(value) <=
            EXERCISE_LOG_DETAIL.LOG_DETAIL.EXERCISE_TIME_MAX_VALUE;
          validateValue = !!value && isInRange;
          isInRange =
            parseInt(value) >=
            EXERCISE_LOG_DETAIL.LOG_DETAIL.BURN_CALORIE_MIN_VALUE &&
            parseInt(value) <=
            EXERCISE_LOG_DETAIL.LOG_DETAIL.BURN_CALORIE_MAX_VALUE;
          validateCalorieValue = !!caloriesBurnValue && isInRange;
          break;
        case CALORIES_BURN_KEY:
          caloriesBurnValue = value;
          isInRange =
            parseInt(value) >=
            EXERCISE_LOG_DETAIL.LOG_DETAIL.BURN_CALORIE_MIN_VALUE &&
            parseInt(value) <=
            EXERCISE_LOG_DETAIL.LOG_DETAIL.BURN_CALORIE_MAX_VALUE;
          validateValue = !!value && isInRange;
          break;
        case CALORIE_KEY:
        case NO_OF_TIMES_KEY:
        case AVG_HEART_RATE_KEY:
        case MAX_HEART_RATE_KEY:
        case SLOPE_KEY:
        case CADENCE_KEY:
        case LATENCY_LEVEL_KEY:
        case DIST_COVERED_KEY:
        case AVG_SPEED_KEY:
          isInRange =
            parseInt(value) >= options?.minRange &&
            parseInt(value) <= options?.maxRange;
          validateValue = value !== '' && value !== '-' && isInRange;
          break;
        case START_TIME_KEY:
          const differenceEndTime = calculateDifferenceValidation(name, value);
          validateValue = !!value && !!differenceEndTime;
          break;
        case END_TIME_KEY:
          const differenceStartTime = calculateDifferenceValidation(
            name,
            value,
          );
          validateValue = !!value && !!differenceStartTime;
          break;
        default:
          break;
      }
      const errorObj = {
        ...exerciseLogError,
        [name]: validateValue ? SUCCESS_TYPE : ERROR_TYPE,
      };
      if (isCalorieChanged) {
        errorObj[CALORIES_BURN_KEY] = validateCalorieValue
          ? SUCCESS_TYPE
          : ERROR_TYPE;
      }
      setExerciseLogError(errorObj);
      setExerciseLogDetail({
        ...exerciseLogDetail,
        [name]: value,
        caloriesBurn: caloriesBurnValue,
      });
    },
    [exerciseLogDetail, exerciseLogError, exerciseMenu],
  );

  const onTabClick = (tabValue) => {
    const exerciseTimeValue = exerciseLogId
      ? parseInt(exerciseLogData?.excerciseTime)
      : exerciseMenu?.suggestionTime;
    const caloriesBurnValue = exerciseLogId
      ? parseInt(exerciseLogData?.caloriesBurn)
      : parseInt(exerciseTimeValue) * exerciseMenu?.calorieBurnTime;
    setExerciseLogDetail({
      ...HNW_EXERCISE_LOG_DETAIL_INITIAL_STATE,
      excerciseTime: exerciseTimeValue,
      caloriesBurn: caloriesBurnValue,
      type: tabValue,
    });
    setExerciseLogError(HNW_EXERCISE_LOG_ERROR_INITIAL_STATE);
    setActiveTab(tabValue);
  };

  const startTimeValidationStatus =
    exerciseLogError.startTime !== SUCCESS_TYPE &&
      exerciseLogError.endTime === SUCCESS_TYPE
      ? ERROR_TYPE
      : exerciseLogError.startTime;
  const endTimeValidationStatus =
    exerciseLogError.startTime === SUCCESS_TYPE &&
      exerciseLogError.endTime !== SUCCESS_TYPE
      ? ERROR_TYPE
      : exerciseLogError.endTime;

  const renderTabContent = (at) => {
    switch (at) {
      case LABEL_EN_SUGGEST_LOG_TAB:
        return (
          <ExerciseSuggestLogPage
            exerciseLogDetailState={exerciseLogDetail}
            onChangeValue={onChangeValue}
            exerciseMenu={exerciseMenu}
            exerciseLogError={exerciseLogError}
            startTimeValidationStatus={startTimeValidationStatus}
            endTimeValidationStatus={endTimeValidationStatus}
            handleKeypadOpen={handleKeypadOpen}
            handleScrollBottom={handleScrollBottom}
          />
        );
      case LABEL_EN_MANUAL_LOG_TAB:
        return (
          <ExerciseManualLogPage
            exerciseLogDetailState={exerciseLogDetail}
            onChangeValue={onChangeValue}
            exerciseMenu={exerciseMenu}
            exerciseLogError={exerciseLogError}
            startTimeValidationStatus={startTimeValidationStatus}
            endTimeValidationStatus={endTimeValidationStatus}
            handleKeypadOpen={handleKeypadOpen}
            handleScrollBottom={handleScrollBottom}
          />
        );
      default:
        break;
    }
  };

  const handleAction = async (mode) => {
    switch (mode) {
      case EXERCISE_LOG_DETAIL.ACTION_TYPE_CREATE:
        setLoading(true);
        await dispatch(
          createExerciseLog({
            ...(suggestedCalorie && { suggestedCalorie }),
            ...new CreateOrUpdateExerciseLogModel({
              ...exerciseLogDetail,
              excerciseCategory: exerciseMenuId,
            }),
          }),
        );
        handleModalAndLoader(true, false);
        break;
      case EXERCISE_LOG_DETAIL.ACTION_TYPE_UPDATE:
        setLoading(true);
        await dispatch(
          updateExerciseLog(
            exerciseLogId,
            new CreateOrUpdateExerciseLogModel({
              ...exerciseLogDetail,
              excerciseCategory: exerciseMenuId,
            }),
          ),
        );
        handleModalAndLoader(true, false);
        break;
      case EXERCISE_LOG_DETAIL.ACTION_TYPE_DELETE:
        setConfirmDeleteModal(true);
        break;
      default:
        break;
    }
  };

  const handleExerciseLogDelete = async () => {
    setLoading(true);
    await dispatch(removeExerciseLogById(exerciseLogId));
    setLoading(false);
    setConfirmDeleteModal(false);
    routeTo('pathHnwExerciseLogHome');
  };

  const handleModalAndLoader = (isModal, isLoading) => {
    setSaveSuccessModal(isModal);
    setLoading(isLoading);
  };

  const handleIOSCondition = (condition) => {
    if (condition) {
      return handleExerciseLogDelete();
    }
    setConfirmDeleteModal(false);
    setCancelSuccessModal(true);
  };

  const assignDefaultErrorState = (fieldState) =>
    fieldState !== ERROR_TYPE ? SUCCESS_TYPE : ERROR_TYPE;
  const showActionBtn = useMemo(() => {
    let validateFailed = false;
    let validateMap = {
      startTime: exerciseLogError.startTime,
      endTime: exerciseLogError.endTime,
    };

    if (exerciseLogDetail.type === LABEL_EN_MANUAL_LOG_TAB) {
      validateMap['calorie'] = exerciseLogError.calorie;
      validateMap['noOfTimes'] = assignDefaultErrorState(
        exerciseLogError.noOfTimes,
      );
      validateMap['avgHeartRate'] = assignDefaultErrorState(
        exerciseLogError.avgHeartRate,
      );
      validateMap['maxHeartRate'] = assignDefaultErrorState(
        exerciseLogError.maxHeartRate,
      );
      validateMap['distCovered'] = assignDefaultErrorState(
        exerciseLogError.distCovered,
      );
      validateMap['avgSpeed'] = assignDefaultErrorState(
        exerciseLogError.avgSpeed,
      );
      validateMap['slope'] = assignDefaultErrorState(exerciseLogError.slope);
      validateMap['cadence'] = assignDefaultErrorState(
        exerciseLogError.cadence,
      );
      validateMap['latencyLevel'] = assignDefaultErrorState(
        exerciseLogError.latencyLevel,
      );
    } else {
      validateMap['caloriesBurn'] = assignDefaultErrorState(
        exerciseLogError.caloriesBurn,
      );
      validateMap['excerciseTime'] = assignDefaultErrorState(
        exerciseLogError.excerciseTime,
      );
    }

    Object.keys(validateMap).forEach((key) => {
      if (validateMap[key] !== SUCCESS_TYPE) {
        validateFailed = true;
      }
    });

    return !validateFailed;
  }, [exerciseLogDetail, exerciseLogError]);

  return (
    <>
      <div
        className={`excercise-details-page${showConfirmDeleteModal ||
          showSaveSuccessModal ||
          showCancelSuccessModal
          ? ' --no_scroll'
          : ''
          } ${keypadOpen ? activeTab === LABEL_EN_MANUAL_LOG_TAB ? 'exercise-log-mb set-transition' : 'exercise-log-suggested-mb set-transition' : ''}`}
      >
        <CalendarLoader showLoading={isLoading} />
        {
          <>
            <div className={`excercise-details-container`}>
              <div className="container-fluid">
                <div className={`excercise-details-header ${headerClass}`}>
                  <img src="/images/healthAndWellness/goal-setting/icons/burn.svg" />
                  <div className="excercise-details-title">
                    {EXERCISE_LOG_DETAIL.LOG_DETAIL.TITLE}
                  </div>
                </div>
                <div className="excercise-details-tab-container">
                  {exerciseLogId && type ? null : (
                    <BasicTab
                      tabs={EXERCISE_LOG_DETAIL.SUGGEST_OR_MANUAL_LOG_TAB}
                      activeTab={activeTab}
                      onTabClick={onTabClick}
                    />
                  )}
                  <div className="tab-content" id="nav-tabContent">
                    {renderTabContent(activeTab)}
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-container-wrapper">
              <img
                src="/images/healthAndWellness/exercise-log/footer-wave.svg"
                className="w-100 footer-curve-bg"
              />
              <div className="footer-container-btn-wrapper">
                {exerciseLogId && type ? (
                  <>
                    <button
                      className="btn btn-red"
                      onClick={() =>
                        handleAction(EXERCISE_LOG_DETAIL.ACTION_TYPE_DELETE)
                      }
                    >
                      {EXERCISE_LOG_DETAIL.LOG_DETAIL.DELETE_BTN}
                    </button>
                    <button
                      className={`btn mt-0 ${showActionBtn ? 'btn-green' : 'btn-disabled'
                        }`}
                      onClick={() => {
                        if (showActionBtn) {
                          handleAction(EXERCISE_LOG_DETAIL.ACTION_TYPE_UPDATE);
                        }
                      }}
                    >
                      {EXERCISE_LOG_DETAIL.LOG_DETAIL.EDIT_BTN}
                    </button>
                  </>
                ) : (
                  <button
                    className={`btn mt-0 w-100 ${showActionBtn ? 'btn-green' : 'btn-disabled'
                      }`}
                    onClick={() => {
                      if (showActionBtn) {
                        handleAction(EXERCISE_LOG_DETAIL.ACTION_TYPE_CREATE);
                      }
                    }}
                  >
                    {EXERCISE_LOG_DETAIL.LOG_DETAIL.CREATE_BTN}
                  </button>
                )}
              </div>
            </div>
          </>
        }
      </div>
      {showSaveSuccessModal && (
        <HnwSuccessOverlayModal
          show={showSaveSuccessModal}
          onTimeout={() => {
            setSaveSuccessModal(false);
            routeTo('pathHnwExerciseLogHome');
          }}
          animationPath={SavedSuccessful}
        />
      )}
      {showCancelSuccessModal && (
        <HnwSuccessOverlayModal
          show={showCancelSuccessModal}
          onTimeout={() => setCancelSuccessModal(false)}
          animationPath={CancelSuccessful}
        />
      )}
      {showConfirmDeleteModal && (
        <HealthDeleteFoodModal
          isDeleteModalOpen={showConfirmDeleteModal}
          onClose={() => handleIOSCondition(!checkIsIOS())}
          onConfirm={() => handleIOSCondition(checkIsIOS())}
          cancelBtnMsg={EXERCISE_LOG_DETAIL.ACTION_MODAL_CONFIRM_CANCEL}
          deleteBtnMsg={EXERCISE_LOG_DETAIL.ACTION_MODAL_CONFIRM_DELETE}
          deleteHeaderMsg={EXERCISE_LOG_DETAIL.ACTION_MODAL_DELETE_CONFIRMATION}
          deleteBtnMsgIos={EXERCISE_LOG_DETAIL.ACTION_MODAL_CONFIRM_DELETE_IOS}
          cancelBtnMsgIos={EXERCISE_LOG_DETAIL.ACTION_MODAL_CONFIRM_CANCEL_IOS}
          exerciseModal={true}
        />
      )}
    </>
  );
}
