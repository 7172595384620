import React, { useEffect, useState, useRef } from 'react';
import './ChallengeDetail.scss';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { fetchChallengeDetail } from '../../../actions';
import { initializeLiff, thaiDate, getParamsString, decodeHtml, isEmptyObject } from '../../../utill.func';
import { CalendarLoader, ChallengeButton, ChallengeDetailSlider, commonConstant } from '../../../common';
import { CHALLENGE_DETAIL, contentRegEx } from '../../../common/challengeConstants';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { DashboardFooter } from '../challengeTeamDashboard';

const ChallengeDetail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const challengeId = params['challengeId'];
  const eventId = params['eventId'];
  const isRegistered = params['isRegistered']==='true';
  const [isLoading, setIsLoading] = useState(false);
  const selectedChallenge = useSelector(
    (state) => state.challengeListObj?.selectedChallenge.challengeDetails,
  );

  const [titleClass, setTitleClass] = useState('');
  const [decodedContent, setDecodedContent] = useState("");

  const fetchDetail = async (id) => {
    setIsLoading(true);
    await dispatch(fetchChallengeDetail(id));
    setIsLoading(false);
  };

  const contentHeight = window.innerHeight <= 730 ? CHALLENGE_DETAIL.minContentHeightSm : CHALLENGE_DETAIL.minContentHeight;

  useEffect(() => {
    if (!selectedChallenge || isEmptyObject(selectedChallenge)) {
      fetchDetail(challengeId);
    }
  }, []);

  useEffect(() => {
    if (selectedChallenge?.content) {
      setDecodedContent(decodeHtml(selectedChallenge.content));
    }
  }, [selectedChallenge]);

const handleDrag = (contentHeight, contentY) => {
  if (contentHeight === CHALLENGE_DETAIL.maxContentHeight) {
      setTitleClass('challenge-details-title-wrap');
  } else if (contentHeight <= CHALLENGE_DETAIL.minContentHeight) {
    setTitleClass('');
  }
}

  const getChallengeDates = () => {
    if (selectedChallenge?.startDate && selectedChallenge?.endDate) {
      return `${thaiDate(
        selectedChallenge?.startDate,
        CHALLENGE_DETAIL.dateFormat,
      )} - ${thaiDate(selectedChallenge?.endDate, CHALLENGE_DETAIL.dateFormat)}`;
    } else {
      return '';
    }
  };

  const handleBtnClick = async(btnType) => {
    if(btnType === CHALLENGE_DETAIL.buttonTypes.registered) {
      const paramsObj = {
        challengeId : challengeId,
        eventId : eventId
      }
      history.push(`${commonConstant.pathChallengeTeamDashboard}?${getParamsString(paramsObj)}`);
    } else if(btnType === CHALLENGE_DETAIL.buttonTypes.participate) {
      try {
        const liff = await initializeLiff();
        if (liff) {
          await liff.openWindow({
            url: selectedChallenge?.challengeLink,
            external: false,
        });
        }
      } catch (error) {
        alert('Error handling LIFF action', error);
      }
    } else {
      try {
        const liff = await initializeLiff();
        if (liff) {
          await liff.shareTargetPicker(
            [
              {
                type: "text",
                text: selectedChallenge?.challengeLink,
              },
            ],
            {
              isMultiple: true,
            }
          )  
        }
      } catch (error) {
        alert('Error handling LIFF action', error);
      }
    }
  }

  return (
    <>
      {selectedChallenge && (
        <div className="challenge-details">
          <img className='challenge-details-image' src={selectedChallenge?.challengeDetailImg} alt='challenge-detail-img'/>
          <ChallengeDetailSlider minHeight={contentHeight} defaultHeight={contentHeight} maxHeight={CHALLENGE_DETAIL.maxContentHeight} showGrab={false} styleObj={{bottom:'10vh'}} onDrag={handleDrag}>
              <div className='challenge-details-content'>
                <div className={`challenge-details-title ${titleClass}`}>
                  {selectedChallenge?.name}
                </div>
                <div className="challenge-details-description">
                  {selectedChallenge?.description}
                </div>
                <div className='challenge-details-seperator'>
                  <img src="/images/challenge/separator.svg" alt="Separator" />
                </div>
                <div className="challenge-details-info">
                  {contentRegEx.test(decodedContent) ? (
                    <div dangerouslySetInnerHTML={{ __html: decodedContent }} />
                  ) : (
                    <pre>{decodedContent}</pre>
                  )}
                </div>
            </div>
          </ChallengeDetailSlider>
          <div className="challenge-details-footer">
            {isRegistered && (
              <ChallengeButton
                successBtn
                btnTitle={CHALLENGE_DETAIL.buttonMap.registered}
                onBtnClick={() =>
                  handleBtnClick(CHALLENGE_DETAIL.buttonTypes.registered)
                }
              />
            )}
            {!isRegistered && (
              <DashboardFooter
                firstButtonTitle={CHALLENGE_DETAIL.buttonMap.invite}
                secondButtonTitle={CHALLENGE_DETAIL.buttonMap.participate}
                onFirstBtnClick={() =>
                  handleBtnClick(CHALLENGE_DETAIL.buttonTypes.invite)
                }
                onSecondBtnClick={() =>
                  handleBtnClick(CHALLENGE_DETAIL.buttonTypes.participate)
                }
              />
            )}
          </div>
        </div>
      )}
      <CalendarLoader showLoading={isLoading} />
    </>
  );
};

export default ChallengeDetail;
