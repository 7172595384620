import React from 'react';
import {
  AS_BODY_KEY_VERIFICATION_STEP,
  AS_OTP_VERIFICATION_STEP,
} from '../../challengeConstants';
import AutoSyncBodyKeyVerification from '../autoSyncBodyKeyVerification/AutoSyncBodyKeyVerification';
import AutoSyncOtpVerification from '../autoSyncOtpVerification/AutoSyncOtpVerification';

export default function AutoSyncVerification({
  autoSyncStep,
  bodyKeyId,
  bodyKeyOtp,
  validationFailed,
  onChangeHandler,
  onClose,
  onVerify,
}) {
  const renderAutoSyncStep = (step) => {
    switch (step) {
      case AS_OTP_VERIFICATION_STEP:
        return (
          <AutoSyncOtpVerification
            bodyKeyId={bodyKeyId}
            validationFailed={validationFailed}
            onChangeHandler={onChangeHandler}
            onClose={onClose}
            onVerify={onVerify}
          />
        );
      case AS_BODY_KEY_VERIFICATION_STEP:
        return (
          <AutoSyncBodyKeyVerification
            bodyKeyId={bodyKeyId}
            validationFailed={validationFailed}
            onChangeHandler={onChangeHandler}
            onClose={onClose}
            onVerify={onVerify}
          />
        );
    }
  };
  return <>{renderAutoSyncStep(autoSyncStep)}</>;
}
