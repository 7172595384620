import axios from 'axios';
import {
  getAccessToken,
  getFacadeAccessToken,
} from '../services/promotion/auth.service';
import {
  getFacadeToken,
  setFacadeToken,
} from '../services/promotion/utils.service';

const AxiosLibrary = axios.create();

AxiosLibrary.interceptors.request.use(async (config) => {
  const token = await getAccessToken();
  const profile = sessionStorage.getItem("Profile");
  config.headers['x-api-key'] = profile.userId;
  config.headers.authorization = `Bearer ${token}`;
  const facadeToken = await getFacadeToken();
  if (!facadeToken) {
    try {
      const accessToken = await getFacadeAccessToken();
      if (!accessToken) {
        return config;
      }
      setFacadeToken(accessToken);
      config.headers['x-req-token'] = accessToken.access_token;
      config.headers.account = `${accessToken.account.accountId}`;
      config.headers.customer = `${accessToken.account.primaryParty.uid}`;
    } catch (error) {
      console.error(error);
    }
  } else {
    config.headers['x-req-token'] = facadeToken.access_token;
    config.headers.account = `${facadeToken.account.accountId}`;
    config.headers.customer = `${facadeToken.account.primaryParty.uid}`;
  }
  return config;
});

export default AxiosLibrary;
