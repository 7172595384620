import {
  GET_BODY_KEY_VERIFY,
  GET_BODY_KEY_VERIFY_ERROR,
  GET_BODY_KEY_VERIFY_SUCCESS,
  GET_BODY_KEY_RECORD,
  GET_BODY_KEY_RECORD_SUCCESS,
  GET_BODY_KEY_RECORD_ERROR,
} from '../../actions/types';

const initialState = {
  // pair verification
  loading: false,
  verificationResponse: null,
  isSuccess: false,
  error: null,
  // records
  loadingRecords: false,
  bodyKeyRecords: [],
  isRecordSuccess: false,
  recordError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_BODY_KEY_VERIFY:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_BODY_KEY_VERIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        isSuccess: !!action.payload?.isSuccess,
        verificationResponse: action.payload,
      };
    case GET_BODY_KEY_VERIFY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isSuccess: false,
        verificationResponse: null,
      };
    case GET_BODY_KEY_RECORD:
      return {
        ...state,
        loadingRecords: true,
        recordError: null,
      };
    case GET_BODY_KEY_RECORD_SUCCESS:
      return {
        ...state,
        loadingRecords: false,
        recordError: null,
        bodyKeyRecords: action.payload?.data || [],
        isRecordSuccess: !!action.payload?.isSuccess
      };
    case GET_BODY_KEY_RECORD_ERROR:
      return {
        ...state,
        loadingRecords: false,
        recordError: action.payload?.errorMsg,
        bodyKeyRecords: [],
        isRecordSuccess: false
      };
    default:
      return state;
  }
}
