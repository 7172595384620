import { HNW_GOAL_SETTING } from '../common/commonConstant';
import config from '../config';
import { checkImageURL, getNumberFromString } from '../utill.func';
export class HealthGoalSettingItems {
  constructor(data) {
    if (data) {
      this.weightGoal = data.weightGoal
        ? new WeightGoal(data.weightGoal)
        : null;
      this.foodGoal = data.foodGoal ? new FoodGoal(data.foodGoal) : null;
      this.waterGoal = data.waterGoal ? new WaterGoal(data.waterGoal) : null;
      this.supplementGoal = data?.supplementGoal?.supplementData?.length
        ? new SupplementGoal(data.supplementGoal)
        : null;
      this.activityGoal = data.activityGoal
        ? new ActivityGoal(data.activityGoal)
        : null;
    }
  }
}

export class WeightGoal {
  constructor(data) {
    if (data) {
      this.id = data._id || '';
      this.weight = data.weight || 0;
      this.targetWeight = data.targetWeight || 0;
      this.targetWeightPerDay = data.targetWeightPerDay || 0;
      this.weightGoalType = data.weightGoalType || '';
      this.customers = data.customers || '';
      this.createdDate = data.createdDate || '';
      this.updatedDate = data.updatedDate || '';
    }
  }
}

export class ActivityGoal {
  constructor(data) {
    if (data) {
      this.id = data._id || '';
      this.targetCalories = data.targetCalories || 0;
      this.exerciseTime = data.exerciseTime || 0;
      this.activities = data?.activities?.length
        ? data.activities.map((a) => new ActivityItem(a))
        : [];
      this.customers = data.customers || '';
      this.createdDate = data.createdDate || '';
      this.updatedDate = data.updatedDate || '';
    }
  }
}

export class ActivityItem {
  constructor(data) {
    if (data) {
      this.exerciseMenu = data?.exerciseMenu?.length
        ? data.exerciseMenu.map((em) => new ExerciseMenu(em))
        : [];
      this.exerciseCategoryId = data.exerciseCategory || '';
    }
  }
}

export class ExerciseMenu {
  constructor(data) {
    this.id = data?._id || '';
    this.name = data?.name || '';
    this.activityTime = data?.activityTime || 0;
    this.image = data?.image ? checkImageURL(data?.image) : '';
    this.exerciseCategory = data?.exerciseCategory || '';
    this.exerciseTemplate = data?.exerciseTemplate || '';
    this.calorieBurnTime = data?.calorieBurnTime || 0;
    this.suggestionTime = data?.suggestionTime || 0;
    this.suggestionList = data?.suggestionList || false;
    this.order = data?.order || 0;
    this.createdDate = data?.createdDate || '';
    this.updatedDate = data?.updatedDate || '';
    this.isChecked = false;
  }
}

export class ActivityExerciseCategoryGoal {
  constructor(data) {
    if (data) {
      this.id = data._id || '';
      this.targetCalories =
        data.targetCalories ||
        HNW_GOAL_SETTING.ACTIVITY.BURN_CALORIE_DEFAULT_VALUE;
      this.exerciseTime =
        data.exerciseTime ||
        HNW_GOAL_SETTING.ACTIVITY.EXERCISE_TIME_DEFAULT_VALUE;
      this.exerciseCategory = data?.exerciseCategory?.length
        ? data.exerciseCategory.map((a) => new ExerciseCategory(a))
        : [];
    }
  }
}

export class ExerciseCategory {
  constructor(data) {
    this.id = data?._id || '';
    this.name = data?.name || '';
    this.bannerImage = data?.bannerImage ? checkImageURL(data.bannerImage) : '';
    this.image = data?.image ? checkImageURL(data.image) : '';
    this.status = data?.status || '';
    this.order = data?.order || 1;
    this.createdDate = data?.createdDate || '';
    this.updatedDate = data?.updatedDate || '';
    this.calorieBurnTime = data?.calorieBurnTime || '';
    this.suggestionList = data?.suggestionList || true;
    this.suggestionTime = data?.suggestionTime || '';
    this.exerciseMenu = data?.exerciseMenu?.length
      ? data?.exerciseMenu?.map((a) => new ExerciseMenuResponse(a))
      : [];
  }
}

export class FoodGoal {
  constructor(data) {
    if (data) {
      this.id = data._id || '';
      this.customers = data.customers || '';
      this.createdDate = data.createdDate || '';
      this.updatedDate = data.updatedDate || '';
      this.targetCalories = data.targetCalories || 0;
      this.targetCarbs = data.targetCarbs || 0;
      this.targetFat = data.targetFat || 0;
      this.targetProtein = data.targetProtein || 0;
    }
  }
}

export class WaterGoal {
  constructor(data) {
    if (data) {
      this.id = data._id || '';
      this.targetWater = data.targetWater || 0;
      this.customers = data.customers || '';
      this.createdDate = data.createdDate || '';
      this.updatedDate = data.updatedDate || '';
    }
  }
}

export class SupplementGoal {
  constructor(data) {
    if (data) {
      this.id = data._id || '';
      this.supplementData = data?.supplementData?.length
        ? data.supplementData.map((a) => new SupplementData(a))
        : [];
      this.customers = data.customers || '';
      this.createdDate = data.createdDate || '';
      this.updatedDate = data.updatedDate || '';
    }
  }
}
class SupplementData {
  constructor(data) {
    if (data) {
      this.id = data._id || '';
      this.supplementId = data.supplementId || '';
      this.name = data.name || '';
      this.image = data.image || '';
      this.imageUrl = this?.image
        ? `${config.S3_URL}${this.image}`
        : '/images/healthAndWellness/goal-setting/icons/default-supplement.svg';
      this.unit = data.unit || '';
      this.quantity = data.quantity || 0;
    }
  }
}

// Request payload
export class ActivityGoalCreatePayload {
  constructor(data) {
    if (data) {
      this.targetCalories =
        getNumberFromString(data.targetCalories) ||
        HNW_GOAL_SETTING.ACTIVITY.BURN_CALORIE_DEFAULT_VALUE;
      this.exerciseTime =
        getNumberFromString(data.exerciseTime) ||
        HNW_GOAL_SETTING.ACTIVITY.EXERCISE_TIME_DEFAULT_VALUE;
      this.activities = data?.exerciseCategory?.length
        ? data.exerciseCategory
            .map((ec) =>
              ec?.exerciseMenu?.length ? new ActivityCreatePayload(ec) : null,
            )
            .filter((a) => a)
        : [];
    }
  }
}
export class ActivityCreatePayload {
  constructor(data) {
    if (data) {
      this.exerciseCategory = data.id || '';
      if (data?.exerciseMenu?.length) {
        this.exerciseMenu = data?.exerciseMenu?.length
          ? data.exerciseMenu.map((em) => new ExerciseMenuCreatePayload(em))
          : [];
      }
    }
  }
}

export class ExerciseMenuCreatePayload {
  constructor(data) {
    if (data) {
      this.exerciseId = data.id || '';
      this.name = data.name || '';
      this.image = data.image || '';
      this.calorieBurnTime = getNumberFromString(data.calorieBurnTime) || 0;
      this.suggestionTime = data.suggestionTime || 0;
    }
  }
}

// Response Payload
export class ExerciseMenuResponse {
  constructor(data) {
    this.calorieBurnTime = data?.calorieBurnTime || 0;
    this.id = data?.exerciseId || '';
    this.image = data?.image || '';
    this.name = data?.name || '';
    this.suggestionTime = data?.suggestionTime || 0;
    this.isChecked = false;
  }
}
