import config from '../../config';
import { ChallengeQuiz, QuizUserAnswers } from "../../model/ChallengeQuiz.model";
import AxiosInterceptor from '../axiosInterceptor.service';

export const getChallengeQuiz = async (params) => {
    
    try {
        const response = await AxiosInterceptor.get(
            `${config.API_URL}/challenge-external/quiz`,
            {
                params
            });

        const data = response?.data;

        return data?.map(round => new ChallengeQuiz(round));
    } catch (error) {
        throw error;
    }
};

export const updateQuizUserAnswers = async (examUserId, answers, isSendToChallenge) => {
    try {
        const response = await AxiosInterceptor.put(`${config.API_URL}/challenge-external/quiz/answer`,
            {
                examUserId,
                answers,
                isSendToChallenge
            },
        );
        return response?.data?.request_id;
    } catch (error) {
        throw error;
    }
};

export const getUpdatedAnswers = async (requestId) => {

    try {
        const response = await AxiosInterceptor.get(
            `${config.API_URL}/challenge-external/quiz/answer?requestId=${requestId}`);
        const isError = response?.data?.is_error;
        return isError ? response?.data : new QuizUserAnswers(response?.data?.processed_result);
    } catch (error) {
        throw error;
    }
};