import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import "./question.scoped.scss";
import axios from "axios";
import { apiUrl, manual } from "../constants";
import Card from 'react-bootstrap/Card';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { ImageViewer } from "react-image-viewer-dv"
import { AiOutlineClose } from "react-icons/ai";
import Swal from "sweetalert2";
import ImgCrop from 'antd-img-crop';
import { Input, Upload, Form, Button, Image, Spin, Modal } from 'antd';
import LoadingOverlay from 'react-loading-overlay'; 
//test
const Q2 = [

    {
        "_id": "1",
        "question": "test 1",
        "description": "คุณเป็นโรคมะเร็งใช่ หรือ ไม่",
        "type_question": "crisis",
        "type_answer": "yes_or_no",
        "answer": [
            {
                "_id": "646888066ำไดไำดไff115ff559c09ba",
                "key": "Yes",
                "value": "Yes",
                "is_correct": true
            },
            {
                "_id": "646888066ff115ำำำำำำำff559c09b9",
                "key": "No",
                "value": "No",
                "is_correct": false
            },

        ],
        "type_score": [
            {
                "condition": [
                    {
                        "operation": [
                            ">"
                        ],
                        "gender": "male",
                        "age": "30"
                    }
                ],
                "type_score": "weight"
            }
        ],
        "is_required": true,
        "display_order": "4",
        "createdDate": "2023-05-20T08:42:46.238Z",
        "updatedDate": "2023-05-20T13:36:04.340Z"
    },
    {
        "_id": "23423423",
        "question": "test 1",
        "description": "คุณเป็นโรคตับใช่ หรือ ไม่",
        "type_question": "normal",
        "type_answer": "yes_or_no",
        "answer": [
            {
                "_id": "2343",
                "key": "Yes",
                "value": "Yes",
                "is_correct": false
            },
            {
                "_id": "234324",
                "key": "No",
                "value": "No",
                "is_correct": true
            },

        ],
        "type_score": [
            {
                "condition": [
                    {
                        "operation": [
                            ">"
                        ],
                        "gender": "male",
                        "age": "30"
                    }
                ],
                "type_score": "weight"
            }
        ],
        "is_required": true,
        "display_order": "4",
        "createdDate": "2023-05-20T08:42:46.238Z",
        "updatedDate": "2023-05-20T13:36:04.340Z"
    },
    {
        "_id": "qwdqw32e23e2dq",
        "question": "test 1",
        "description": "จงเลือกคำตอบที่ถูกที่สุดำา่กสำไ่กส่ไำนรก่นไรำ่กนรไำ่นรดพ้่นรด้่ำนพดนรำพ่ดนำพดำพดำพด",
        "type_question": "normal",
        "type_answer": "mutiple_choices_image",
        "answer": [
            {
                "_id": "646888066ำไดไำดไff115ff559c09ba",
                "key": "A",
                "value": "เลือกตอบ 1 ",
                "is_correct": true
            },
            {
                "_id": "646888066ff115ำำำำำำำff559c09b9",
                "key": "B",
                "value": "เลือกตอบ 2 34r34r34r",
                "is_correct": false
            },
            {
                "_id": "646888066ff11ไไไไไไไ5ff559c09b8",
                "key": "C",
                "value": "เลือกตอบ 3",
                "is_correct": false
            },
            {
                "_id": "646888066ไไไไff115ff559c09b9",
                "key": "D",
                "value": "เลือกตอบ 2 ",
                "is_correct": false
            },
            {
                "_id": "646ไไไไไไ888066ff115ff559c09b8",
                "key": "F",
                "value": "เลือกตอบ 3",
                "is_correct": false
            }

        ],
        "type_score": [
            {
                "condition": [
                    {
                        "operation": [
                            ">"
                        ],
                        "gender": "male",
                        "age": "30"
                    }
                ],
                "type_score": "weight"
            }
        ],
        "is_required": false,
        "display_order": "4",
        "createdDate": "2023-05-20T08:42:46.238Z",
        "updatedDate": "2023-05-20T13:36:04.340Z"
    },
    {
        "_id": "3",
        "question": "test 1",
        "description": "จงเลือกคำตอบที่ถูกที่สุดำา่กสำไ่กส่ไำนรก่นไรำ่กนรไำ่นรดพ้่นรด้่ำนพดนรำพ่ดนำพดำพดำพด",
        "type_question": "normal",
        "type_answer": "choices_text",
        "answer": [
            {
                "_id": "646888066ำไดไำดไff115ff559c09ba",
                "key": "A",
                "value": "เลือกตอบ 1 ",
                "is_correct": true
            },
            {
                "_id": "646888066ff115ำำำำำำำff559c09b9",
                "key": "B",
                "value": "เลือกตอบ 2 34r34r34r",
                "is_correct": false
            },
            {
                "_id": "646888066ff11ไไไไไไไ5ff559c09b8",
                "key": "C",
                "value": "เลือกตอบ 3",
                "is_correct": false
            },
            {
                "_id": "646888066ไไไไff115ff559c09b9",
                "key": "D",
                "value": "เลือกตอบ 2 ",
                "is_correct": false
            },
            {
                "_id": "646ไไไไไไ888066ff115ff559c09b8",
                "key": "F",
                "value": "เลือกตอบ 3",
                "is_correct": false
            }

        ],
        "type_score": [
            {
                "condition": [
                    {
                        "operation": [
                            ">"
                        ],
                        "gender": "male",
                        "age": "30"
                    }
                ],
                "type_score": "weight"
            }
        ],
        "is_required": false,
        "display_order": "4",
        "createdDate": "2023-05-20T08:42:46.238Z",
        "updatedDate": "2023-05-20T13:36:04.340Z"
    },
    {
        "_id": "4",
        "question": "test 3",
        "description": "จงเลือกคำตอบที่ถูกที่สุดำา่กสำไ่กส่ไำนรก่นไรำ่กนรไำ่นรดพ้่นรด้่ำนพดนรำพ่ดนำพดำพดำพด",
        "type_question": "normal",
        "type_answer": "choices_text",
        "answer": [
            {
                "_id": "646888066ำไดไำดไff115ff559c09ba",
                "key": "A",
                "value": "เลือกตอบ 1 ",
                "is_correct": true
            },
            {
                "_id": "646888066ff115ำำำำำำำff559c09b9",
                "key": "B",
                "value": "เลือกตอบ 2 34r34r34r",
                "is_correct": false
            },
            {
                "_id": "646888066ff11ไไไไไไไ5ff559c09b8",
                "key": "C",
                "value": "เลือกตอบ 3",
                "is_correct": false
            },
            {
                "_id": "646888066ไไไไff115ff559c09b9",
                "key": "D",
                "value": "เลือกตอบ 2 ",
                "is_correct": false
            },
            {
                "_id": "646ไไไไไไ888066ff115ff559c09b8",
                "key": "F",
                "value": "เลือกตอบ 3",
                "is_correct": false
            }

        ],
        "type_score": [
            {
                "condition": [
                    {
                        "operation": [
                            ">"
                        ],
                        "gender": "male",
                        "age": "30"
                    }
                ],
                "type_score": "weight"
            }
        ],
        "is_required": false,
        "display_order": "4",
        "createdDate": "2023-05-20T08:42:46.238Z",
        "updatedDate": "2023-05-20T13:36:04.340Z"
    },

    {
        "_id": "qwdqwdq",
        "question": "test 1",
        "description": "จงเลือกคำตอบที่ถูกที่สุดำา่กสำไ่กส่ไำนรก่นไรำ่กนรไำ่นรดพ้่นรด้่ำนพดนรำพ่ดนำพดำพดำพด",
        "type_question": "normal",
        "type_answer": "choices_image",
        "answer": [
            {
                "_id": "646888066ำไดไำดไff115ff559c09ba",
                "key": "A",
                "value": "เลือกตอบ 1 ",
                "is_correct": true
            },
            {
                "_id": "646888066ff115ำำำำำำำff559c09b9",
                "key": "B",
                "value": "เลือกตอบ 2 34r34r34r",
                "is_correct": false
            },
            {
                "_id": "646888066ff11ไไไไไไไ5ff559c09b8",
                "key": "C",
                "value": "เลือกตอบ 3",
                "is_correct": false
            },
            {
                "_id": "646888066ไไไไff115ff559c09b9",
                "key": "D",
                "value": "เลือกตอบ 2 ",
                "is_correct": false
            },
            {
                "_id": "646ไไไไไไ888066ff115ff559c09b8",
                "key": "F",
                "value": "เลือกตอบ 3",
                "is_correct": false
            }

        ],
        "type_score": [
            {
                "condition": [
                    {
                        "operation": [
                            ">"
                        ],
                        "gender": "male",
                        "age": "30"
                    }
                ],
                "type_score": "weight"
            }
        ],
        "is_required": false,
        "display_order": "4",
        "createdDate": "2023-05-20T08:42:46.238Z",
        "updatedDate": "2023-05-20T13:36:04.340Z"
    },
    {
        "_id": "65",
        "question": "test 3",
        "description": "จงเลือกคำตอบที่ถูกที่สุดำา่กสำไ่กส่ไำนรก่นไรำ่กนรไำ่นรดพ้่นรด้่ำนพดนรำพ่ดนำพดำพดำพด",
        "type_question": "normal",
        "type_answer": "text",
        "answer": [
        ],
        "type_score": [
            {
                "condition": [
                    {
                        "operation": [
                            ">"
                        ],
                        "gender": "male",
                        "age": "30"
                    }
                ],
                "type_score": "weight"
            }
        ],
        "is_required": false,
        "display_order": "4",
        "createdDate": "2023-05-20T08:42:46.238Z",
        "updatedDate": "2023-05-20T13:36:04.340Z"
    },
    {
        "_id": "65rtgtr",
        "question": "test 3",
        "description": "จงเลือกคำตอบที่ถูกที่สุดำา่กสำไ่กส่ไำนรก่นไรำ่กนรไำ่นรดพ้่นรด้่ำนพดนรำพ่ดนำพดำพดำพด",
        "type_question": "normal",
        "type_answer": "text",
        "answer": [
        ],
        "type_score": [
            {
                "condition": [
                    {
                        "operation": [
                            ">"
                        ],
                        "gender": "male",
                        "age": "30"
                    }
                ],
                "type_score": "weight"
            }
        ],
        "is_required": false,
        "display_order": "4",
        "createdDate": "2023-05-20T08:42:46.238Z",
        "updatedDate": "2023-05-20T13:36:04.340Z"
    },
]

const Question = (props) => {
    const history = useHistory();
    const [imagePreview, setImagePreview] = useState(null);
    let User = localStorage.getItem("User");
    let assesment_id = localStorage.getItem("assesment_id");
    const cover_user = User ? JSON.parse(User) : '';


    const [loading2, setLoading2] = useState(false);
    const containerRef = useRef(null);
    const [containerHeight, setContainerHeight] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);


    const [currentImage, setCurrentImage] = useState(0);
    const [questions, setquestions] = useState([]);
    const totalPage = Math.ceil(questions.length / 2);
    // const currentQuestions1 = questions.slice((currentPage - 1) * 2, (currentPage - 1) * 2 + 2);
    // const currentQuestionIds = currentQuestions1.map((question) => question._id);
    const [answers, setAnswers] = useState([]);
    const [answers2, setAnswers2] = useState([]);
    const [currentQuestionIds, setCurrentQuestionIds] = useState([]);
    const handleScrollUp = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const [progressPercentage, setProgressPercentage] = useState('0%');
    const [selectedChoice, setSelectedChoice] = useState(null);
    const [crisis_alert, setcrisis_alert] = useState(null);

    console.log('answers', answers);

    const handleAnswer = (questionId, answer, question) => {
        console.log('test', questionId);
        setAnswers((prevAnswers) => {
            let updatedAnswers = [...prevAnswers];
            const existingAnswerIndex = updatedAnswers.findIndex((prevAnswer) => prevAnswer.questionId === questionId);

            if (question.type_answer === "mutiple_choices_image") {
                if (existingAnswerIndex !== -1) {
                    const existingAnswer = updatedAnswers[existingAnswerIndex];
                    const existingAnswerChoices = existingAnswer.answer.map((a) => a._id);
                    const isAnswerSelected = existingAnswerChoices.includes(answer._id);

                    let updatedAnswer;
                    if (isAnswerSelected) {
                        updatedAnswer = {
                            ...existingAnswer,
                            answer: existingAnswer.answer.filter((a) => a._id !== answer._id)
                        };
                    } else {
                        updatedAnswer = {
                            ...existingAnswer,
                            answer: [
                                ...existingAnswer.answer,
                                {
                                    _id: answer._id,
                                    key: answer.key,
                                    value: answer.image_path,
                                    image_path: answer.image_path,
                                    is_correct: answer.is_correct
                                }
                            ]
                        };
                    }

                    updatedAnswers[existingAnswerIndex] = updatedAnswer;
                } else {
                    const newAnswer = {
                        questionId,
                        answer: [
                            {
                                _id: answer._id,
                                key: answer.key,
                                value: answer.image_path,
                                image_path: answer.image_path,
                                is_correct: answer.is_correct
                            }
                        ]
                    };

                    updatedAnswers.push(newAnswer);
                }
            }
            else if (question.type_answer === "yes_or_no") {
                if (question.type_question === "crisis") {
                    if (answer.is_correct === true) {

                        Swal.fire({
                            html: `<div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                                     <img src="/images/assesment/H1.png" alt="Avatar" style="width: 25%;">
                                     <p style="white-space: pre-line;text-align: center;font-family: 'Sukhumvit Set tadmai bold';">
                                       ${crisis_alert}
                                     </p>
                                   </div>`,
                            showCancelButton: false,
                            allowOutsideClick: false,
                            focusConfirm: false,
                            confirmButtonText: "ok",
                            showCancelButton: false,
                            allowOutsideClick: false,
                            confirmButtonText: "OK",

                            hideClass: {


                                confirmButton: 'swal2-noanimation'
                            }


                        });


                    } else {
                        if (existingAnswerIndex !== -1) {
                            const existingAnswer = updatedAnswers[existingAnswerIndex];
                            const existingAnswerChoices = existingAnswer.answer.map((a) => a._id);
                            const isAnswerSelected = existingAnswerChoices.includes(answer._id);

                            let updatedAnswer;
                            if (isAnswerSelected) {
                                updatedAnswer = {
                                    ...existingAnswer,
                                    answer: []
                                };
                            } else {
                                updatedAnswer = {
                                    ...existingAnswer,
                                    answer: [
                                        {
                                            _id: answer._id,
                                            key: answer.key,
                                            value: answer.value,
                                            image_path: answer.image_path,
                                            is_correct: answer.is_correct
                                        }
                                    ]
                                };
                            }

                            updatedAnswers[existingAnswerIndex] = updatedAnswer;
                        } else {
                            const newAnswer = {
                                questionId,
                                answer: [
                                    {
                                        _id: answer._id,
                                        key: answer.key,
                                        value: answer.value,
                                        image_path: answer.image_path,
                                        is_correct: answer.is_correct
                                    }
                                ]
                            };
                            updatedAnswers.push(newAnswer);
                        }
                    }
                } else {
                    if (existingAnswerIndex !== -1) {
                        const existingAnswer = updatedAnswers[existingAnswerIndex];
                        const existingAnswerChoices = existingAnswer.answer.map((a) => a._id);
                        const isAnswerSelected = existingAnswerChoices.includes(answer._id);

                        let updatedAnswer;
                        if (isAnswerSelected) {
                            updatedAnswer = {
                                ...existingAnswer,
                                answer: []
                            };
                        } else {
                            updatedAnswer = {
                                ...existingAnswer,
                                answer: [
                                    {
                                        _id: answer._id,
                                        key: answer.key,
                                        value: answer.value,
                                        image_path: answer.image_path,
                                        is_correct: answer.is_correct
                                    }
                                ]
                            };
                        }

                        updatedAnswers[existingAnswerIndex] = updatedAnswer;
                    } else {
                        const newAnswer = {
                            questionId,
                            answer: [
                                {
                                    _id: answer._id,
                                    key: answer.key,
                                    value: answer.value,
                                    image_path: answer.image_path,
                                    is_correct: answer.is_correct
                                }
                            ]
                        };
                        updatedAnswers.push(newAnswer);
                    }
                }
            }
            else if(question.type_answer === "choices_image"){
                if (existingAnswerIndex !== -1) {
                    const existingAnswer = updatedAnswers[existingAnswerIndex];
                    const updatedAnswer = {
                        ...existingAnswer,
                        answer: [
                            {
                                _id: answer._id,
                                key: answer.key,
                                value: answer.image_path,
                                image_path: answer.image_path,
                                is_correct: answer.is_correct
                            }
                        ]
                    };
                    updatedAnswers[existingAnswerIndex] = updatedAnswer;
                } else {
                    const newAnswer = {
                        questionId,
                        answer: [
                            {
                                _id: answer._id,
                                key: answer.key,
                                value: answer.image_path,
                                image_path: answer.image_path,
                                is_correct: answer.is_correct
                            }
                        ]
                    };
                    updatedAnswers.push(newAnswer);
                }
            }
            else {

                if (existingAnswerIndex !== -1) {
                    const existingAnswer = updatedAnswers[existingAnswerIndex];
                    const updatedAnswer = {
                        ...existingAnswer,
                        answer: [
                            {
                                _id: answer._id,
                                key: answer.key,
                                value: answer.value,
                                image_path: answer.image_path,
                                is_correct: answer.is_correct
                            }
                        ]
                    };
                    updatedAnswers[existingAnswerIndex] = updatedAnswer;
                } else {
                    const newAnswer = {
                        questionId,
                        answer: [
                            {
                                _id: answer._id,
                                key: answer.key,
                                value: answer.value,
                                image_path: answer.image_path,
                                is_correct: answer.is_correct
                            }
                        ]
                    };
                    updatedAnswers.push(newAnswer);
                }
            }

            return updatedAnswers;
        });
        setAnswers2((prevAnswers) => {
            let updatedAnswers = [...prevAnswers];
            const existingAnswerIndex = updatedAnswers.findIndex((prevAnswer) => prevAnswer.questionId === questionId);

            if (question.type_answer === "mutiple_choices_image") {
                if (existingAnswerIndex !== -1) {
                    const existingAnswer = updatedAnswers[existingAnswerIndex];
                    const existingAnswerChoices = existingAnswer.answer.map((a) => a._id);
                    const isAnswerSelected = existingAnswerChoices.includes(answer._id);

                    let updatedAnswer;
                    if (isAnswerSelected) {
                        updatedAnswer = {
                            ...existingAnswer,
                            answer: existingAnswer.answer.filter((a) => a._id !== answer._id)
                        };
                    } else {
                        updatedAnswer = {
                            ...existingAnswer,
                            answer: [
                                ...existingAnswer.answer,
                                {
                                    _id: answer._id,
                                    key: answer.key,
                                    value: answer.image_path,
                                    image_path: answer.image_path,
                                    is_correct: answer.is_correct
                                }
                            ]
                        };
                    }

                    updatedAnswers[existingAnswerIndex] = updatedAnswer;
                } else {
                    const newAnswer = {
                        questionId,
                        answer: [
                            {
                                _id: answer._id,
                                key: answer.key,
                                value: answer.image_path,
                                image_path: answer.image_path,
                                is_correct: answer.is_correct
                            }
                        ]
                    };

                    updatedAnswers.push(newAnswer);
                }
            }
            else if (question.type_answer === "yes_or_no") {
                if (question.type_question === "crisis") {
                    if (answer.is_correct === true) {
                        // alert(crisis_alert);
                    } else {
                        if (existingAnswerIndex !== -1) {
                            const existingAnswer = updatedAnswers[existingAnswerIndex];
                            const existingAnswerChoices = existingAnswer.answer.map((a) => a._id);
                            const isAnswerSelected = existingAnswerChoices.includes(answer._id);

                            let updatedAnswer;
                            if (isAnswerSelected) {
                                updatedAnswer = {
                                    ...existingAnswer,
                                    answer: []
                                };
                            } else {
                                updatedAnswer = {
                                    ...existingAnswer,
                                    answer: [
                                        {
                                            _id: answer._id,
                                            key: answer.key,
                                            value: answer.value,
                                            image_path: answer.image_path,
                                            is_correct: answer.is_correct
                                        }
                                    ]
                                };
                            }

                            updatedAnswers[existingAnswerIndex] = updatedAnswer;
                        } else {
                            const newAnswer = {
                                questionId,
                                answer: [
                                    {
                                        _id: answer._id,
                                        key: answer.key,
                                        value: answer.value,
                                        image_path: answer.image_path,
                                        is_correct: answer.is_correct
                                    }
                                ]
                            };
                            updatedAnswers.push(newAnswer);
                        }
                    }
                } else {
                    if (existingAnswerIndex !== -1) {
                        const existingAnswer = updatedAnswers[existingAnswerIndex];
                        const existingAnswerChoices = existingAnswer.answer.map((a) => a._id);
                        const isAnswerSelected = existingAnswerChoices.includes(answer._id);

                        let updatedAnswer;
                        if (isAnswerSelected) {
                            updatedAnswer = {
                                ...existingAnswer,
                                answer: []
                            };
                        } else {
                            updatedAnswer = {
                                ...existingAnswer,
                                answer: [
                                    {
                                        _id: answer._id,
                                        key: answer.key,
                                        value: answer.value,
                                        image_path: answer.image_path,
                                        is_correct: answer.is_correct
                                    }
                                ]
                            };
                        }

                        updatedAnswers[existingAnswerIndex] = updatedAnswer;
                    } else {
                        const newAnswer = {
                            questionId,
                            answer: [
                                {
                                    _id: answer._id,
                                    key: answer.key,
                                    value: answer.value,
                                    image_path: answer.image_path,
                                    is_correct: answer.is_correct
                                }
                            ]
                        };
                        updatedAnswers.push(newAnswer);
                    }
                }
            }
            else if(question.type_answer === "choices_image"){
                if (existingAnswerIndex !== -1) {
                    const existingAnswer = updatedAnswers[existingAnswerIndex];
                    const updatedAnswer = {
                        ...existingAnswer,
                        answer: [
                            {
                                _id: answer._id,
                                key: answer.key,
                                value: answer.image_path,
                                image_path: answer.image_path,
                                is_correct: answer.is_correct
                            }
                        ]
                    };
                    updatedAnswers[existingAnswerIndex] = updatedAnswer;
                } else {
                    const newAnswer = {
                        questionId,
                        answer: [
                            {
                                _id: answer._id,
                                key: answer.key,
                                value: answer.image_path,
                                image_path: answer.image_path,
                                is_correct: answer.is_correct
                            }
                        ]
                    };
                    updatedAnswers.push(newAnswer);
                }
            }
            else {

                if (existingAnswerIndex !== -1) {
                    const existingAnswer = updatedAnswers[existingAnswerIndex];
                    const updatedAnswer = {
                        ...existingAnswer,
                        answer: [
                            {
                                _id: answer._id,
                                key: answer.key,
                                value: answer.value,
                                image_path: answer.image_path,
                                is_correct: answer.is_correct
                            }
                        ]
                    };
                    updatedAnswers[existingAnswerIndex] = updatedAnswer;
                } else {
                    const newAnswer = {
                        questionId,
                        answer: [
                            {
                                _id: answer._id,
                                key: answer.key,
                                value: answer.value,
                                image_path: answer.image_path,
                                is_correct: answer.is_correct
                            }
                        ]
                    };
                    updatedAnswers.push(newAnswer);
                }
            }

            return updatedAnswers;
        });
    };

    useEffect(() => {
        const imageCards = document.querySelectorAll('.image-card');
        imageCards.forEach((card) => {
            card.classList.remove('selected');
            if (card.dataset.choiceId === selectedChoice?._id) {
                card.classList.add('selected');
            }
        });
    }, [selectedChoice]);


    const handleSubmit = () => {
        const liffProfile = localStorage.getItem("Profile");
        setLoading2(true)
        const currentQuestions = questions.slice((currentPage - 1) * 2, (currentPage - 1) * 2 + 2);
        const requiredQuestions = currentQuestions.filter((question) => question.is_required);
        const optionalQuestions = currentQuestions.filter((question) => !question.is_required);


        // ตรวจสอบคำถามที่บังคับตอบ
        const unansweredRequiredQuestions = requiredQuestions.filter((question) => {
            const questionId = question._id;
            return !answers.some((answer) => answer.questionId === questionId);
        });

        if (unansweredRequiredQuestions.length > 0) {

            Swal.fire({
                html: `<div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                         <img src="/images/assesment/H1.png" alt="Avatar" style="width: 25%;">
                         <p style="white-space: pre-line;text-align: center;font-family: 'Sukhumvit Set tadmai bold';">
                         กรุณาตอบคำถามที่บังคับตอบในหน้าปัจจุบัน
                         </p>
                       </div>`,
                showCancelButton: false,
                allowOutsideClick: false,
                focusConfirm: false,
                confirmButtonText: "ok",
                showCancelButton: false,
                allowOutsideClick: false,
                confirmButtonText: "OK",

                hideClass: {


                    confirmButton: 'swal2-noanimation'
                }


            });
            setLoading2(false)
            return;
        }

        const unansweredOptionalQuestions = optionalQuestions.filter((question) => {
            const questionId = question._id;
            return !answers.some((answer) => answer.questionId === questionId);
        });

        const optionalAnswers = unansweredOptionalQuestions.map((question) => {
            return {
                questionId: question._id,
                answer: [],
            };
        });

        const optionalAnswers2 = unansweredOptionalQuestions.map((question) => {
            return {
                questionId: question._id,
                answer: [],
            };
        });

        const allAnswers = [...answers, ...optionalAnswers];
        const allAnswersWithModifiedKeys = allAnswers.map((answer) => {
            const { questionId, ...rest } = answer;
            return { question_id: questionId, ...rest };
        });

        // Set answers2 with optional answers
        const allAnswers2 = [...answers2, ...optionalAnswers2];

        const totalQuestions = questions.length;
        const answeredQuestionsCount = allAnswers2.length;
        const percentage = Math.floor((answeredQuestionsCount / totalQuestions) * 100) + '%';
        setProgressPercentage(percentage);

        let data = {
            "assessment_id": localStorage.getItem("assesment_id"),
            "question": allAnswersWithModifiedKeys
        }
        console.log("ข้อมูลที่ส่ง", data);
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_API_URL_UAT}/assessment/answer`,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${cover_user.token}`,
                'x-api-key' :  liffProfile.userId

            }, data
        })
            .then((res) => {
                setLoading2(false);
                const nextPage = currentPage + 1;
                const isLastQuestionInSet = nextPage > totalPage;
                console.log('test', answers2);
                if (isLastQuestionInSet) {

                    localStorage.setItem("assesment_id", localStorage.getItem("assesment_id"));
                    history.push("/assesment/finish");

                } else {
                    setCurrentPage(nextPage);
                    setAnswers([]);
                    setAnswers2(allAnswers2);
                }
            })
            .catch((err) => {
                setLoading2(false)
            });
    };
    useEffect(() => {
        const currentQuestions = questions.slice((currentPage - 1) * 2, (currentPage - 1) * 2 + 2);
        const questionIds = currentQuestions.map((question) => question._id);
        setCurrentQuestionIds(questionIds);
        console.log(questionIds);

        fetchData(questionIds); // เรียกใช้ fetchData ที่มีโค้ดการเรียก API

    }, [currentPage, questions]);


    const fetchData = async (questionIds) => {
        try {
            const liffProfile = localStorage.getItem("Profile");
            console.log('questionIds', questionIds);

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL_UAT}/assessment/getanswer`,
                {
                    assessment_id: assesment_id,
                    question_id: questionIds,
                },
                {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${cover_user.token}`,
                        'x-api-key' :  liffProfile.userId
                    },
                }
            );

            console.log(response.data.data);
            let updatedAnswers = response.data.data.map((item) => ({
                questionId: item.assessments_questions,
                answer: item.answer,
            }));
            setAnswers(updatedAnswers);
            updatedAnswers.forEach((answer) => {
                setAnswers2((prevAnswers) => [...prevAnswers, answer]);
            });

        } catch (error) {
            console.error(error);
            // จัดการข้อผิดพลาด
        }
    };

    useEffect(() => {
        getquestions();
    }, []);


    useEffect(() => {
        const containerElement = containerRef.current;
        const updateContainerHeight = () => {
            const height = containerElement.getBoundingClientRect().height;
            setContainerHeight(height);
        };

        updateContainerHeight();
        window.addEventListener('resize', updateContainerHeight);
        return () => {
            window.removeEventListener('resize', updateContainerHeight);
        };
    }, []);

    const getquestions = () => {
        let User = localStorage.getItem("assesment_id");
        const liffProfile = localStorage.getItem("Profile");
        console.log(Q2);
        setLoading2(true);
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_API_URL_UAT}/assessment/` + User,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${cover_user.token}`,
                'x-api-key' :  liffProfile.userId
            },
        })
            .then((res) => {
                console.log(res.data.data);
                const loadedQuestions = res.data.data.assessments_questions;

                setcrisis_alert(res.data.data.crisis_alert)
                setquestions(loadedQuestions);
                setLoading2(false);

            })
            .catch((err) => {
                setLoading2(false);
            });
    };

    const openImageViewer = useCallback((index) => {
        console.log(index);
        setCurrentImage(index);
        // setIsViewerOpen(true);
    }, []);

    const delect = () => {
        setImagePreview(null);
    };


    const renderQuestion = (question, index) => {
        switch (question.type_answer) {
            case "text":
                return (
                    <div key={question._id}>
                        <div style={{ padding: '25px' }}>
                            <p className="title color_title" style={{ fontSize: "18px", fontFamily: 'Sukhumvit Set tadmai bold' }}>
                                {question.question}
                            </p>
                            <p className="title color_title" style={{ fontSize: "16px", lineHeight: "20px", fontFamily: 'Sukhumvit Set Extra Medium' }}>
                                {question.description}
                            </p>
                        </div>
                        <Card style={{ borderRadius: "25px", minHeight: "34vh", background: "rgba(255, 255, 255, 0.5)" }}>
                            <Card.Body >
                                <textarea

                                    value={answers.find((a) => a.questionId === question._id)?.answer?.[0]?.value || ""}
                                    onChange={(e) =>
                                        handleAnswer(question._id, { _id: question._id, key: null, value: e.target.value, image_path: null, is_correct: false }, question)
                                    }
                                    style={{
                                        border: "none", // กำหนด style เพื่อลบเส้นขอบ
                                        width: "100%", // กำหนดความกว้างเท่ากับ Card
                                        height: "28vh", // กำหนดความสูงเท่ากับ Card
                                        padding: '25px',
                                        fontFamily: 'Sukhumvit Set tadmai bold'
                                    }}
                                />
                            </Card.Body>
                        </Card>
                    </div>
                );
            case "choices_text":
                return (
                    <div key={question._id}>
                        <div style={{ padding: '25px' }}>
                            <p className="title color_title" style={{ fontSize: "18px", fontFamily: 'Sukhumvit Set tadmai bold' }}>
                                {question.question}
                            </p>
                            <p className="title color_title" style={{ fontSize: "16px", lineHeight: "20px", fontFamily: 'Sukhumvit Set Extra Medium' }}>
                                {question.description}
                            </p>
                        </div>
                        <Card style={{ borderRadius: "25px", background: "rgba(255, 255, 255, 0.5)" }}>
                            <Card.Body style={{ alignSelf: "center" }}>
                                {question.answer.map((choice) => (
                                    <div
                                        key={choice._id}
                                        onClick={() => handleAnswer(question._id, choice, question)}
                                        style={{
                                            marginTop: '15px',
                                            borderRadius: "20px",
                                            width: "40vh",
                                            height: "43px",
                                            backgroundColor:
                                                answers.find((a) => a.questionId === question._id)?.answer?.[0]?._id === choice._id
                                                    ? "#9cc163"
                                                    : "white",
                                            border: "2px solid #f1f1fb",
                                            textAlign: "center",
                                            padding: "10px",
                                            color:
                                                answers.find((a) => a.questionId === question._id)?.answer?.[0]?._id === choice._id
                                                    ? "black"
                                                    : "black"
                                        }}
                                    >
                                        <span style={{ fontWeight: 'bold' }}>{choice.key}. {choice.value} </span>
                                    </div>
                                ))}
                            </Card.Body>
                        </Card>
                    </div>
                );
            case "yes_or_no":
                return (
                    <div key={question._id}>
                        <div style={{ padding: '25px' }}>
                            <p className="title color_title" style={{ fontSize: "18px", fontFamily: 'Sukhumvit Set tadmai bold' }}>
                                {question.question}
                            </p>
                            <p className="title color_title" style={{ fontSize: "16px", lineHeight: "20px", fontFamily: 'Sukhumvit Set Extra Medium' }}>
                                {question.description}
                            </p>
                        </div>
                        <Card style={{ borderRadius: "30px", minHeight: "20vh", background: "rgba(255, 255, 255, 0.5)" }}>
                            <Card.Body style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                                {question.answer.map((choice, index) => (
                                    <div
                                        key={choice._id}
                                        onClick={() => handleAnswer(question._id, choice, question)}
                                        style={{
                                            borderRadius: "40px",
                                            width: "30vh",
                                            height: "7vh",
                                            backgroundColor:
                                                answers.find((a) => a.questionId === question._id)?.answer?.[0]?._id === choice._id
                                                    ? (choice.value === "Yes" ? "#3c9d47" : "#f44336")
                                                    : "#f7f7fd",
                                            border: "4px solid #ffffff",
                                            textAlign: "center",
                                            padding: "10px",
                                            color:
                                                answers.find((a) => a.questionId === question._id)?.answer?.[0]?._id === choice._id
                                                    ? "#ffffff"
                                                    : "#6c757d",
                                            // fontWeight: "bold",
                                            fontSize: "20px",
                                            margin: index > 0 ? "10px" : "0",
                                            display: "flex",
                                            alignItems: "center", // จัดตำแหน่งไอคอนและข้อความให้อยู่ตรงกลาง
                                            justifyContent: "center", // จัดตำแหน่งไอคอนและข้อความให้อยู่ตรงกลาง
                                        }}
                                    >
                                        {choice.value === "Yes" ? (
                                            <>
                                                <FaCheck style={{
                                                    marginRight: "5px",
                                                    color: answers.find((a) => a.questionId === question._id)?.answer?.[0]?._id === choice._id
                                                        ? "#ffffff"
                                                        : "#3c9d47",
                                                }} />{" "}
                                                <span style={{ marginLeft: "10px", fontFamily: 'Sukhumvit Set tadmai bold' }}>ใช่</span>
                                            </>
                                        ) : (
                                            <>
                                                <FaTimes style={{
                                                    marginRight: "5px",
                                                    color: answers.find((a) => a.questionId === question._id)?.answer?.[0]?._id === choice._id
                                                        ? "#ffffff"
                                                        : "#f44336",
                                                }} />{" "}
                                                <span style={{ marginLeft: "10px", fontFamily: 'Sukhumvit Set tadmai bold' }}>ไม่ใช่</span>
                                            </>
                                        )}
                                    </div>
                                ))}
                            </Card.Body>
                        </Card>
                    </div>
                );
            case "image":
                const liffProfile = localStorage.getItem("Profile");
                const handleImageUpload = (e, questionId) => {
                    const file = e.target.files[0];
                    const formData = new FormData();
                    formData.append('imageFile', file);

                    setLoading2(true);
                    axios.post(`${process.env.REACT_APP_API_URL_UPLOAD}/assessment/upload`, formData, {
                        headers: {
                            Accept: "application/json",
                            Authorization: `Bearer ${cover_user.token}`,
                            'x-api-key' :  liffProfile.userId
                        }
                    })
                        .then(response => {
                            console.log(process.env.REACT_APP_S3_URL_ASSESMENT + response.data.data);
                            setLoading2(false);
                            const updatedAnswer = {
                                _id: questionId,
                                key: null,
                                value: process.env.REACT_APP_S3_URL_ASSESMENT + response.data.data,
                                image_path: response.data.data,
                                is_correct: false
                            };
                            handleAnswer(questionId, updatedAnswer, question);

                        })
                        .catch(error => {
                            setLoading2(false);
                            console.error(error);
                        });
                };
                const handleImageDelete = (questionId, question) => {
                    // ดำเนินการลบรูปภาพที่เกี่ยวข้องกับคำถาม questionId ที่ระบุที่นี่
                    // เช่น เรียกใช้ API หรือดำเนินการตามที่คุณต้องการ
                    // อัพเดตสถานะของรูปภาพหรือลบข้อมูลออกจากระบบ

                    // ตัวอย่างการอัพเดตสถานะของรูปภาพเป็น null
                    const updatedAnswer = {
                        _id: questionId,
                        key: null,
                        value: null,
                        image_path: null,
                        is_correct: false
                    };

                    handleAnswer(questionId, updatedAnswer, question);
                };
                return (
                    <div key={question._id}>
                        <div style={{ padding: '25px' }}>
                            <p className="title color_title" style={{ fontSize: "18px", fontFamily: 'Sukhumvit Set tadmai bold' }}>
                                {question.question}
                            </p>
                            <p className="title color_title" style={{ fontSize: "16px", lineHeight: "20px", fontFamily: 'Sukhumvit Set Extra Medium' }}>
                                {question.description}
                            </p>
                        </div>
                        <Card style={{ borderRadius: "25px", minHeight: "34vh", background: "rgba(255, 255, 255, 0.5)" }}>
                            <Card.Body style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                               
                                {answers.find((a) => a.questionId === question._id)?.answer?.[0]?.value && (
                                        <div onClick={() => handleImageDelete(question._id, question)}>
                                           
                                            {/* <ImageViewer>  */}
                                                
                                            <AiOutlineClose
                                                style={{ position: 'absolute', color: 'red', fontSize: '30px', top: '5px', right: '10px' }}
                                               
                                            />
                                                
                                                </div>
                                
                                    )}
                                
                               
                                <div style={{textAlign: 'center', position: 'relative' }}>
                                    {answers.find((a) => a.questionId === question._id)?.answer?.[0]?.value && (
                                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                           
                                            {/* <ImageViewer>  */}
                                                
                                                <img src={answers.find((a) => a.questionId === question._id)?.answer?.[0]?.value} alt="Uploaded Image" style={{ maxWidth: '100%' ,height:'240px'}} />
                                                
                                                {/* </ImageViewer> */}
                                        </div>
                                    )}
                                    {!answers.find((a) => a.questionId === question._id)?.answer?.[0]?.value && (
                                        <div>
                                            <label htmlFor={`file-input-${question._id}`} style={{ cursor: 'pointer' }}>
                                                <div>
                                                    <img src="/images/assesment/iconuplode.png" alt="Upload Icon" />
                                                    <p style={{ color: "#707070" }}>อัพโหลดรูปภาพ</p>
                                                </div>
                                            </label>
                                        </div>
                                    )}
                                </div>


                                <input
                                    type="file"
                                    id={`file-input-${question._id}`}
                                    onChange={(e) => handleImageUpload(e, question._id)}
                                    style={{ display: 'none' }}
                                    accept="image/*"
                    
                                />
                            </Card.Body>
                        </Card>
                    </div>
                );

            case "choices_image":
                return (
                    <div key={question._id}>
                        <div style={{ padding: '25px' }}>
                            <p className="title color_title" style={{ fontSize: "18px", fontFamily: 'Sukhumvit Set tadmai bold' }}>
                                {question.question}
                            </p>
                            <p className="title color_title" style={{ fontSize: "16px", lineHeight: "20px", fontFamily: 'Sukhumvit Set Extra Medium' }}>
                                {question.description}
                            </p>
                        </div>
                        <Card
                            style={{
                                borderRadius: "25px",
                                // maxHeight: "40vh",
                                background: "rgba(255, 255, 255, 0.5)",
                                // overflowY: question.answer.length > 2 ? "auto" : "initial" 
                            }}
                        >
                            <Card.Body
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    height: "100%", // กำหนดความสูงเป็นเต็มรูปแบบเพื่อให้คอนเทนเนอร์เต็มความสูง
                                }}
                            >
                                <div className="image-card-container">
                                    {question.answer.map((choice) => {
                                        const isSelected = answers.find((a) => a.questionId === question._id && a.answer[0]?._id === choice._id);
                                        const cardClassName = isSelected ? "image-card selected" : "image-card";

                                        return (
                                            <div
                                                key={choice._id}
                                                className={cardClassName}
                                                onClick={() => handleAnswer(question._id, choice, question)}
                                                data-choice-id={choice._id}
                                            >
                                                <img src={choice.image_path} alt="Choice Image" className="image-card-image" style={{ width: '120px', height: '110px', borderRadius: '20px' }} />
                                            </div>
                                        );
                                    })}
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                );
            case "mutiple_choices_image":
                return (
                    <div key={question._id}>
                        <div style={{ padding: '25px' }}>
                            <p className="title color_title" style={{ fontSize: "18px", fontFamily: 'Sukhumvit Set tadmai bold' }}>
                                {question.question}
                            </p>
                            <p className="title color_title" style={{ fontSize: "16px", lineHeight: "20px", fontFamily: 'Sukhumvit Set Extra Medium' }}>
                                {question.description}
                            </p>
                        </div>
                        <Card
                            style={{
                                borderRadius: "25px",
                                // maxHeight: "40vh",
                                background: "rgba(255, 255, 255, 0.5)",
                                // overflowY: question.answer.length > 2 ? "auto" : "initial" 
                            }}
                        >
                            <Card.Body
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    height: "100%", // กำหนดความสูงเป็นเต็มรูปแบบเพื่อให้คอนเทนเนอร์เต็มความสูง
                                }}
                            >
                                <div className="image-card-container">
                                    {question.answer.map((choice) => {
                                        const isSelected = answers.find((a) => a.questionId === question._id && a.answer.some((ans) => ans._id === choice._id));
                                        const cardClassName = isSelected ? "image-card selected" : "image-card";

                                        return (
                                            <div
                                                key={choice._id}
                                                className={cardClassName}
                                                onClick={() => handleAnswer(question._id, choice, question)}
                                                data-choice-id={choice._id}
                                            >
                                                <img src={choice.image_path} alt="Choice Image" className="image-card-image" style={{ width: '120px', height: '110px', borderRadius: '20px' }} />
                                            </div>
                                        );
                                    })}
                                </div>
                            </Card.Body>
                        </Card>

                    </div>
                );
            default:
                return null;
        }
    };
    return (
        <div>
            {/* <div className="page overflow_disable" style={{ backgroundColor: '#F7F8FB', minHeight: `${containerHeight}px` }}> */}
            <div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px', paddingLeft: '17px', paddingRight: '17px' }}>
                    <div className="process" style={{ '--widthProcess': `${progressPercentage}`, width: '80%' }}></div>
                    <span style={{ marginLeft: '10px', color: '#d1e8ba', fontWeight: 'bold' }}>{progressPercentage}</span>
                    <span style={{ marginLeft: '10px', color: '#d1e8ba', fontWeight: 'bold' }}>complete</span>

                </div>

                <div className="Success3">

                    {/* <div className="containerAssesment" style={{ position: 'absolute', zIndex: 1 }} ref={containerRef}> */}
                    <div className="containerAssesment" style={{ zIndex: 1 }} ref={containerRef} >
                        {questions.slice((currentPage - 1) * 2, currentPage * 2).map((question) => renderQuestion(question, question.length))}
                        <br></br>
                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginBottom: '5vh' }}>


                            {/*                             
                            <img
                                src="/images/assesment/gran.png"
                                alt="รูปภาพ"
                                style={{ zIndex: '1', width: '50%', marginTop: '20px', marginRight: '-15vh', marginBottom: '30px' }}

                            /> */}

                            <button
                                style={{
                                    // position: 'absolute',
                                    bottom: '-100px',
                                    borderRadius: '36px',
                                    backgroundColor: 'rgb(15, 119, 74)',
                                    borderColor: 'rgb(15, 119, 74)',
                                    width: '40vh',
                                    fontSize: '18px',
                                    height: '6vh',
                                    fontFamily: 'Sukhumvit Set tadmai bold',
                                }}
                                type="button"
                                className="btn btn-primary btn-lg btn-block button"
                                onClick={() => {
                                    handleSubmit();
                                    handleScrollUp();
                                }}
                            >
                                Continue
                            </button>

                        </div>

                    </div>
                </div>



                {/* <div style={{ overflow: "hidden", minHeight: "93vh" }}>
                        <img
                            src="/images/assesment/BG1.png"
                            alt="Image description"
                            style={{
                                position: "absolute",
                                width: "100%",
                                top: "85%",
                                transform: "translateY(-50%)",
                                objectFit: "cover",
                            }}
                        />
                    </div> */}

                {/* </div> */}


            </div>
            <LoadingOverlay active={loading2} spinner text="Loading" styles={{ wrapper: { width: '100%', minHeight: '100%' } }} />
        </div>
    );
};

export default Question;
