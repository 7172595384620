import React, { Component,createRef } from 'react';
import { Link } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import { osName } from 'react-device-detect';
import { connect } from 'react-redux';
import { isEqual, update } from 'lodash';

import { alertActions ,otpFetch, verifyOtpFetch} from '../../actions';
import { commonConstant } from '../../common';
import { customerService, activityService, amwayService, utilsService } from '../../services';
import { Modal,OTPInput } from '../../helpers';
import _, { last } from 'lodash';

class ChangeMID extends Component {
  constructor(props) {
    super(props);

    const customer = JSON.parse(sessionStorage.getItem('customer'));
    if (sessionStorage.getItem('customer') === null || sessionStorage.getItem('customer') === 'undefined') {
      window.location.replace(commonConstant.pathHome);
    }

    this.state = {
      customer: customer,
      birthday: '',
      distNum: '',
      loading: false,
      error: false,
      isOpenCalendar: false,
      isOpenModal: false,
      profile:'',
      invalid: false,
      invalidCount: 0,
      otpLoading:  true,
      initialLoading: true,
      isVerifyOTP: false
    };

    this.otpRef = createRef();
  }

 async componentDidMount(){
    const { location } = this.props;
    if(!location.state || !location.state.myMember) return window.location.replace(commonConstant.pathHome);
    this.setState({profile:location.state.myMember,oldLineId:location.state.oldLineId, oldMember: location.state.oldMember})
    this.props.otpFetch(location.state.myMember.accountMst.aboNum);

  }

  onPaste = e => {
    e.preventDefault();
    return false;
  };

    async componentDidUpdate(prevProps){
        if (!isEqual(prevProps.otp,this.props.otp)){
          this.setState({
            otpLoading: false,
            initialLoading: false,
          });
        }
        if (!isEqual(prevProps.verifyOtp,this.props.verifyOtp)){
          if (this.props.verifyOtp.code === 200){
            const {profile, oldLineId, oldMember, isVerifyOTP} = this.state
            const customer = JSON.parse(sessionStorage.getItem('customer'));
            const amwayNumber = profile.accountMst.aboNum;
            if(profile && isVerifyOTP === false){
                this.state.isVerifyOTP = true;
                const date = new Date(profile.partyList[0].partyMst.birthdate);
                const convertYearBEtoAD = date.getFullYear();
                const month = date.getMonth() + 1;
                const monthWithZero = month <= 9 ? `0${month.toString()}` : month.toString();
                const day = date.getDate().toString();
                const dayWithZero = day <= 9 ? `0${day}` : day;
      
                const cleanData = _.filter(profile.partyList, function (o) { return o.taxList[0] && o.taxList[0].taxTypeCd === 'CID' && o.partyMst && o.partyMst.roleCd === 'BusinessOwner'; });
                const last4Digit = cleanData.map(function (raw) {
                  return raw.taxList[0].taxId;
                });
                const cidKey = last4Digit ? last4Digit[0]: '';
                
                const customerParam = {
                  name: profile.accountMst.accountName,
                  amwayNumber: parseInt(amwayNumber),
                  cid: cidKey.substr(-4),
                  birthday: `${convertYearBEtoAD.toString()}-${monthWithZero}-${dayWithZero}`,
                  partyId: profile.accountMst.primaryPersonPartyId,
                  socialType: 'LINE',
                };
                const updated = await customerService.update(this.state.customer._id, customerParam);
                if (updated) {
                  const params = {
                    mid: this.state.customer.uid,
                    cid: cidKey,
                    customerId: this.state.customer._id,
                    info: osName === 'IOS' ? 'iPhone' : osName,
                    status: 'unblock',
                    displayname: this.state.customer.displayName,
                  };
                  sessionStorage.setItem('customer', JSON.stringify(updated));
                  const saveLineMID = await amwayService.saveLineMID(params);
                  console.log('changeMID Line');
                  const { replymessage } = saveLineMID.data.lineMIDResult;
                  if (replymessage !== 'Success') {
                    this.props.showError('ระบบไม่สามารถบันทึกข้อมูลได้กรุณาติดต่อเจ้าหน้าที่');
                    this.setState({ loading: false });
                    return false;
                  } else {
                    activityService.create({
                      uid: updated.uid,
                      userId: updated._id,
                      status: 'incomplete',
                      action: 'CHANGE_MID',
                    });

                    if(oldMember && (oldMember.uid !== this.state.customer.uid)){
                      await utilsService.deleteUserFromRichmenu(oldLineId)
                        .then( () => {
                            activityService.create({
                            service: "customers",
                            action: "delete",
                            userId: oldMember._id,
                            uid: oldMember.uid,
                            data: oldMember,
                        })
                      });

                    }

                    return this.props.history.push(commonConstant.pathSuccess);

                  }
                }
            }
          } 
          else {
            const { invalidCount } = this.state;
            // if (invalidCount < 2){
              this.setState(state => ({
                loading: false,
                invalid: true,
                invalidCount: state.invalidCount + 1,
              }));
              this.otpRef.current.clear();
            // } 
            // else {
            //   this.otpRef.current.clear();
            //   this.setState({
            //     isOpenModal: true,
            //   });
            // }
          }
        }
      }

    handleSubmitOtp = async (otp,props) => {
        const {profile,oldLineId} = this.state
        const customer = JSON.parse(sessionStorage.getItem('customer'));
        const amwayNumber = profile.accountMst.aboNum;
        const { isFilled } = props;
        if (isFilled){
          this.props.verifyOtpFetch({
            otp,
            'accountId': profile.accountMst.aboNum,
            'eventName': 'LOGIN',
            'newLineId': customer.uid,
            'oldLineId': oldLineId,
            'newObjectId': customer._id,
            'displayName': customer.displayName,
            'pictureUrl': customer.pictureUrl,
          });
          this.setState({ loading: true });
        }
      }
      
      handleFetchOtp = () => {
        this.setState({
            otpLoading: true,
        });
        this.props.otpFetch(this.state.profile.accountMst.aboNum);
      }

    render() {
      const { handleSubmitOtp, handleFetchOtp } = this;
      const {  isOpenModal ,invalid ,otpLoading, initialLoading} = this.state;
      
      if(initialLoading){
        return <LoadingOverlay active={this.state.otpLoading} spinner text="Loading" styles={{ wrapper: { width: '100%', minHeight: '100%' } }} />
      }

      return (
        <div className="regisPage member-page">
          
          {
            isOpenModal &&
            <Modal
            title="คุณกรอกรหัส OTP ผิดเกินกำหนด"
            content="กรุณาติดต่อ Amway Contact Center"
            type="warning"
            fnOk={() => window.location = 'tel:027258000'}
            fnCancel={() => this.props.history.replace(commonConstant.pathHome)}
            okText="โทร"
            cancleText="ยกเลิกรายการ" />
          }

          <div className="logoAmway"><img src="/images/logoAmway-black.svg" alt="" /></div>
            <h2 className="mb-90">กรอกรหัส OTP เพื่อยืนยัน</h2>
              <div className="amwayNumber-container">
              <div>หมายเลข OTP จะถูกส่งไปที่หมายเลข</div>
              <div className='mb-20'>{this.props.otp.phoneNumber}</div>
                <small>หมายเลขอ้างอิง (Ref No.): {}</small>
                <OTPInput
                loading={otpLoading} 
                ref={this.otpRef} 
                invalid={invalid} 
                count={4} 
                onChange={invalid ? () => this.setState({ invalid: false }) : null} 
                onLastInput={(val,props) => handleSubmitOtp(val,props)}
                resendFn={handleFetchOtp}
                resendBtn={true}
                delay={true}
                resendDelay={60}
                invalidMessage="รหัส OTP ไม่ถูกต้องกรุณาลองอีกครั้ง"
              />
              </div>
              <div className="mt-32">
              <small className="ref-text">ระบบจะทำการเปลื่ยนบัญชี LINE ของท่าน</small>
              <br/>
              <small className="ref-text">เมื่อทำการยืนยันตัวตนผ่านเบอร์โทรศัพท์เสร็จสมบูรณ์</small>
            </div>
        </div >
      );
    }
}

const mapStateToProps = ({ otp, verifyOtp }) => ({
    otp,
    verifyOtp,

  });

const  mapDispatchToProps = (dispatch) => ({
    showError: (message) => dispatch(alertActions.showError(message)),
    otpFetch: uid => dispatch(otpFetch(uid)),
    verifyOtpFetch: params => dispatch(verifyOtpFetch(params)),

})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeMID);
