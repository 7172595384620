import { CUSTOMER_FETCH, CUSTOMER_CREATE, CUSTOMER_UPDATE, CUSTOMER_FIND } from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case CUSTOMER_FETCH:
      return action.payload;
    case CUSTOMER_CREATE:
      return { ...state, created: true };
    case CUSTOMER_UPDATE:
      return { ...state, updated: true };
    default:
      return state;
  }
}
export function find(state = {}, action) {
  switch (action.type) {
    case CUSTOMER_FIND:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
