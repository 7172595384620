import React from 'react';
import './HealthNutritionCard.scss';
import { round } from '../../services';
import { formatNumberWithCommas, getNumberFromString } from '../../utill.func';
import {
  GOALS_NUTRITION_DETAILS,
  NUTRITION_CARD_MAPPING,
} from '../commonConstant';

const HealthNutritionCard = ({ nutritionData, maxValueToShow }) => {
  return (
    <div key={'nutrition'} className="nutrition-card-container">
      <div className="card">
        <div className="nutrition-card-heading">
          <span className="heading-font padding-small">กินแล้ว</span>
          <span className="heading-font padding-large">เป้าหมาย</span>
          <span className="heading-font">เหลืออีก</span>
        </div>
        {nutritionData.map((item, index) => {
          const value = round(item?.value, 0);
          const max = round(item?.max, 0);
          const diff = round(item?.diff, 0);
          return (
            <div key={item?.label}>
              <div key={`bar${index}`} className="bar-nutrition">
                <div className="heading-font">{item?.label}</div>
                <div className="bar-nutrition-values">
                  <span className="values-font ">
                    {value
                      ? value > maxValueToShow
                        ? `${formatNumberWithCommas(maxValueToShow)}+`
                        : formatNumberWithCommas(value)
                      : '-'}
                  </span>
                  <span className="values-font ">
                    {max ? formatNumberWithCommas(max) : '-'}
                  </span>
                  <span className="values-font">
                    {diff ? formatNumberWithCommas(diff) : '-'}
                  </span>
                </div>
              </div>
              <progress
                key={`progress${index}`}
                value={value ?? '-'}
                max={max ?? '-'}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HealthNutritionCard;
