import React from "react";
import BmiBar from "../../../../common/bmiBar/BmiBar";
import './ChallengeMyBmiData.scss';
import { handleBmiBadges } from "../../../../utill.func";

const ChallengeMyBmiData = ({ bmiHeading, bmiValue, teamDashboardStatus }) => {
    const bmiClassname = handleBmiBadges(bmiValue)?.classname;
    const bmiTitle = handleBmiBadges(bmiValue)?.bmiTitle;
    return (
        <div className="challenge-my-bmi-container">
            <div className="challenge-card-body">
                <div className="card-ongoing-bmi-details">
                    <span>{bmiHeading}</span>
                </div>
                <BmiBar bmiValue={bmiValue} teamDashboardStatus={teamDashboardStatus} bmiClassname={bmiClassname} bmiTitle={bmiTitle}/>
            </div>
        </div>
    )
}

export default ChallengeMyBmiData