import React from 'react';
import './header.scss';

const Header = ({ routeTo, customer }) => {
    return (
        <div className="container-fluid">
            <div className="row align-items-center hnwHeader">
                <div className="col-9 pt-2 px-0">
                    <h2 className="home-title">สวัสดี! <span className="green">{customer?.name}</span></h2>
                </div>
                <div className="col-2 text-right pr-2" onClick={() => routeTo('pathHnwHomeCalendar')}>
                    <a>
                        <img src="/images/healthAndWellness/food_calorie_calendar.svg" alt="calendar_icon"/>
                    </a>
                </div>
                <div className="col-1 text-right px-0" onClick={() => routeTo('pathProfile')}>
                    <a>
                        <img src="/images/healthAndWellness/fi_user.svg" alt="user_profile_icon" />
                    </a>
                </div>
            </div>
        </div>
    )
};

export default Header;
