import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { couponFetch } from '../../actions';
import Coupon from './coupon';
import axios from "axios";
import { commonConstant } from '../../common';
import { liffGetProfile } from '../../services/promotion/auth.service'

const liff = window.liff
class CouponList extends Component {
  componentDidMount() {
    // const querystring = queryString.parse(this.props.params);
    // const userId =  querystring['userId'];
    // const userIdMobile = querystring['?userId'];
    // if (userId || userIdMobile){
    //   if (userId){
    //     this.props.fetchCoupon(userId);
    //   } else {
    //     this.props.fetchCoupon(userIdMobile);
    //   }
    // } else {
    //   this.props.history.push(commonConstant.pathHome);
    // }
    liff.init({ liffId: process.env.REACT_APP_LIFF_CLIENT_ID_COUPON, withLoginOnExternalBrowser: false }, async () => {
      if (liff.isLoggedIn()) {
        let profile = await liffGetProfile()
        profile.token = await liff.getAccessToken()


        axios.post(`${process.env.REACT_APP_API_URL}/auth/token`, profile)
          .then(async (res) => {
            axios({
              method: "get",
              url: `${process.env.REACT_APP_API_URL}/customers`,
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${res.data.token}`,
                'x-api-key' :  profile.userId
              },
            })
              .then((res) => {
                if (res.data.amwayNumber) {
                  this.props.fetchCoupon(res.data.amwayNumber);
                } else {
                  window.location.href = process.env.REACT_APP_REDIRECT_URL;
                }
              })
              .catch((err) => {
              });
          })
          .catch(async (err) => {
          });
      } else {
        liff.login();
      }
    });
  }

  renderCoupon = (coupons) => {
    if (!coupons) {
      return <div>Loading...</div>;
    }
    if (coupons.length === 0) {
      return (<div className="empty-coupon-container">
        <img src="/images/No-coupon.svg" alt="" />
        <div>
          คุณไม่มีคูปองที่สามารถใช้ได้
        </div>
      </div>);
    }
    return coupons.map(
      (coupon, i) => <Coupon key={i} detail={coupon.description} discount={Math.round(coupon.value)} valid={coupon.endDate} />
    );
  }

  render() {
    // TODO chow loading when onLoad
    // TODO format date
    const { renderCoupon } = this;
    const { coupon } = this.props;
    return (
      <div className="coupon-list-page">
        {renderCoupon(coupon.coupons)}
      </div>
    );
  }
}

const mapStateToProps = ({ coupon }) => ({
  coupon,
});

const mapDispatchToProp = dispatch => ({

  fetchCoupon: (params) => dispatch(couponFetch(params)),
});

export default connect(mapStateToProps, mapDispatchToProp)(CouponList);
// TODO conect to mapStateToProps and mapDispatchToProps
// function mapStateToProps({ profile, customer }) {
//   return {
//       profile,
//       customer
//   }
// }

// function mapDispatchToProps(dispatch) {
//   return ({
//       showError: (message) => dispatch(alertActions.showError(message)),
//       getCustomer: () => dispatch(customerFetch())
//   })
// }

// export default connect(mapStateToProps, mapDispatchToProps)(CouponList)
