import { GET_CUSTOMER_BMI, GET_CUSTOMER_MONTHLY_BMI, GET_LAST_MONTH_BMI } from '../actions/types';

const INITIAL_STATE = { monthlyBmi: [] };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CUSTOMER_BMI:
      return { ...state, ...action.payload };
    case GET_CUSTOMER_MONTHLY_BMI:
      return { ...state, monthlyBmi: action.payload };
    case GET_LAST_MONTH_BMI:
      return { ...state, lastMonthBmi: action.payload };
    default:
      return state;
  }
}
