import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router, Redirect, Route, Switch,
} from 'react-router-dom';
import SweetAlert from 'sweetalert-react';
import 'sweetalert/dist/sweetalert.css';
import VConsole from 'vconsole';
import { alertActions, customerFetch, profileFetch } from './actions';
import { commonConstant } from './common';
import {
  Abo, AbuddyContent, AHappy, AmwayRedirect, Cart, CartPromotion, ChangeMID, ChooseAddress, ChooseBank, ChooseCreditCard, ChooseMagType, ChoosePayment, ConditionBook,
  ConditionEBook, Confirmation, CouponList, CreateAddress, CreateCreditCard, CreatePromotionAddress, Foa, FOARegister, Forgot, Home, Interest, LinkRichmenu, LoginSucess, Member, MUConfirmPayment, MUMagType, MUOtp, MyList, MySOP, Otp, PaymentStatus, PhoneChange, PromotionAddressList, PromotionDetail, PromotionProduct, Public, Question, RemovePromotion, RenewConfirmPayment, SeamLess, SOPDetail,
  SOPOptions, SOPProfileList, SOPSolution, Success,
} from './components';
import Abudyassesment from './components/assment/abudyassesment/abudyassesment';
import Pageassesment from './components/assment/assesment/assesment';
import Pagefinish from './components/assment/finish/finish';
import Finishview from './components/assment/finishview/finishview';
import InsertW from './components/assment/insertW/insertW';
import Score from './components/healthAndWellness/assessment/score/Score';
import Pdpaasesment from './components/assment/pdpaasesment/pdpaasesment';
import Profile from './components/assment/profile';
import Pagequestion from './components/assment/question/question';
import AdminCategory from './components/library/category/adminCategories';
import LibraryCategoryForm from './components/library/category/libraryCategoryForm';
import LibraryContent from './components/library/content/libraryContent';
import LibraryContentForm from './components/library/content/libraryContentForm';
import LibrarySubContent from './components/library/content/librarySubContent';
import ViewLibraryContent from './components/library/content/viewContent';
import LibraryProfile from './components/library/profile';
import { FOAOtp } from './components/lineFOA/otpFOA/otpFOA';
import ShareTargetPicker from './components/shareTargetPicker';
import {
  Bmi, HomeProfile, healthAndWellness, HeightAndWeight, HnWAssessment,
  HnWFoodCalories, HnWSearchHistory, HnwFoodDetail,
  FoodLogHome, HnwHomeCalendar, FoodLogCalendar, ExerciseLogCalendar,
  HnwGoals, HnwGoalsActivity, HnwGoalsNutrition, HnwGoalsWater, HnwGoalsSupplements, HnwGoalsWeight,
  GoalsSearchHistory,
} from './components/healthAndWellness';
import { TermsCondition, Policy } from './components/policies';
import { HEALTH_AND_WELLNESS_TYPE } from './common/commonConstant';
import AssessmentHomePage from './components/healthAndWellness/assessment/assessmentHomePage/AssessmentHomePage';
import AssessmentHistoryLog from './components/healthAndWellness/assessment/components/assessmentHistoryLog/AssessmentHistoryLog';
import RedirectSmartReport from './components/smart-report/RedirectSmartReport';
import ViewSmartReport from './components/smart-report/ViewSmartReport';
import PDP from './components/sop/Subscription/PDP';
import PLP from './components/sop/Subscription/PLP';
import { HealthLogList, AddMenu } from './components/healthAndWellness/hnwFoodLog/components';
import './scss/app.scss';
import ExerciseLogHome from './components/healthAndWellness/hnwExercise/ExerciseLogHome';
import ExerciseLogDetail from './components/healthAndWellness/hnwExercise/exerciseLogDetail/ExerciseLogDetail';
import { ExerciseCategoryList, ExerciseLogList } from './components/healthAndWellness/hnwExercise/components';
import AccessCamera from './components/healthAndWellness/hnwFoodLog/components/accessCamera/AccessCamera';
import ChallengeQuiz from './components/challenge/challengeQuiz/ChallengeQuiz';
import ChallengeTeamDashboard from './components/challenge/challengeTeamDashboard/ChallengeTeamDashboard';
import ChallengeList from './components/challenge/challengeList/ChallengeList';
import { ChallengeMyDashboard, ChallengeMap, ChallengeBadgeScreen, ChallengeBadgeDetail, ChallengeVideoPlayer } from './components/challenge';
import ChallengeDetail from './components/challenge/challengeDetail/ChallengeDetail';
import AccessChallengeCamera from './common/challenge/accessChallengeCamera/AccessChallengeCamera';
import { ChallengeMapDetail } from './components/challenge/challengeMap';
import { ChallengeVideoDetail } from './components/challenge';
import Navigation from './hoc/Navigation/Navigation';
import { ChallengeMyReward } from './components/challenge/challengeMyDashboard';
import ChallengeMyPictures from './components/challenge/challengeMyDashboard/challengeMyPictures/ChallengeMyPictures';
import ScrollToTop from './common/scrollToTop';

export const history = createBrowserHistory();

class App extends Component {
  componentDidMount() {
    if (process.env.REACT_APP_CLIENT_ID !== '1498011841') {
      // enable vconsole is not PROD state
      const vConsole = new VConsole();
    }
    const urlParams = queryString.parse(history.location.search);
    const isCoupon = queryString.parse(urlParams['liff.state']).userId;
    const linkrichmenu = queryString.parse(urlParams['linkrichmenu']);
    if (window.location.href.includes('assesment')) {
      return <Profile />;
    }
    if (window.location.href.includes('coupon')) {
      return <CouponList />;
    }
    if (!isCoupon && !urlParams['redirect'] && !urlParams['seamless']) {
      if (linkrichmenu) {
        this.props.profileFetch(history.location.search);
      } else {
        this.props.profileFetch();
      }
    }

    const thisProps = this.props;
    history.listen(() => {
      thisProps.clear();
    });
  }

  render() {
    const urlParams = queryString.parse(history.location.search);
    const isCoupon = queryString.parse(urlParams['liff.state']).userId;
    const linkrichmenu = queryString.parse(urlParams['liff.state'])[
      '?linkrichmenu'
    ];
    if (isCoupon) {
      return <CouponList params={urlParams['liff.state']} />;
    } else if (urlParams['redirect']) {
      return <AmwayRedirect redirect={urlParams['redirect']} />;
    } else if (urlParams['page']) {
      return window.location.replace(urlParams['page']);
    } else if (urlParams['feature'] === 'payment') {
      return <PaymentStatus params={urlParams} />;
    } else if (urlParams['seamless']) {
      return <SeamLess redirect={urlParams['seamless']} />;
    } else if (urlParams['mylist']) {
      const encoded = encodeURIComponent(urlParams['mylist'])
        .replace(/'/g, '%27')
        .replace(/"/g, '%22');
      const url = '/mylist/' + encoded;
      return <MyList redirect={url} />;
    } else if (urlParams['linkrichmenu']) {
      return <LinkRichmenu params={urlParams} />;
    }
    // else{
    //   const encoded = encodeURIComponent(urlParams['profile'])
    //     .replace(/\?liff.state=%3F/g, "?")
    //     .replace(/%3D/g, "=")
    //     .replace(/%26/g, "&");
    //   const url = '/assesment/profile' + encoded;
    //   return <Profile redirect={url} />
    // }
    // else if(urlParams['ahappy']) {
    //   const encoded = encodeURIComponent(urlParams['AHappy'])
    //     .replace(/'/g, '%27')
    //     .replace(/"/g, '%22');
    //   const url = 'https://uat.amway.co.th/a-happy';
    //   return <AHappy redirect={url} />
    // }
    return (
      <Router>
        <div id="wrapper" className="typePage">
          <SweetAlert
            title=""
            type="error"
            show={this.props.alert.error}
            text={this.props.alert.message}
            onConfirm={this.props.clear}
            confirmButtonColor="#0069d9"
          />
          <Switch>
            <Route exact path={commonConstant.pathHome} component={Home} />
            <Route exact path={commonConstant.pathAbo} component={Abo} />
            <Route exact path={commonConstant.pathFoa} component={Foa} />
            <Route exact path={commonConstant.pathForgot} component={Forgot} />
            <Route exact path={commonConstant.pathMember} component={Member} />
            <Route
              exact
              path={commonConstant.pathChangeMID}
              component={ChangeMID}
            />
            <Route exact path={commonConstant.pathPublic} component={Public} />
            <Route
              exact
              path={commonConstant.pathInterest}
              component={Interest}
            />
            <Route
              exact
              path={commonConstant.pathSuccess}
              component={Success}
            />
            <Route
              exact
              path={commonConstant.pathLoginSuccess}
              component={LoginSucess}
            />
            <Route
              exact
              path={commonConstant.pathQuestion}
              component={Question}
            />
            <Route exact path={commonConstant.pathOtp} component={Otp} />
            <Route
              exact
              path={commonConstant.pathChoosePayment}
              component={ChoosePayment}
            />
            <Route
              exact
              path={commonConstant.pathChoosePayment}
              component={ChoosePayment}
            />
            <Route
              exact
              path={commonConstant.pathChooseCreditCard}
              component={ChooseCreditCard}
            />
            <Route
              exact
              path={commonConstant.pathPaymentConfirm}
              component={Confirmation}
            />
            <Route
              exact
              path={commonConstant.pathChooseBankTransfer}
              component={ChooseBank}
            />
            <Route
              exact
              path={commonConstant.pathCreateCreditCard}
              component={CreateCreditCard}
            />
            <Route
              exact
              path={commonConstant.pathConditionBook}
              component={ConditionBook}
            />
            <Route
              exact
              path={commonConstant.pathConditionEBook}
              component={ConditionEBook}
            />
            <Route
              exact
              path={commonConstant.pathChooseAddress}
              component={ChooseAddress}
            />
            <Route
              exact
              path={commonConstant.pathCreateAddress}
              component={CreateAddress}
            />
            <Route
              exact
              path={commonConstant.pathTermsConditions}
              component={Navigation(TermsCondition)}
            />
            <Route exact path={commonConstant.pathPolicy} component={Navigation(Policy)} />
            <Route exact path={commonConstant.pathMySOP} component={MySOP} />
            <Route
              exact
              path={commonConstant.pathSOPDetail}
              component={SOPDetail}
            />
            <Route
              exact
              path={commonConstant.pathToSOPOptions}
              component={SOPOptions}
            />
            <Route
              exact
              path={commonConstant.pathSolutionPLP}
              component={SOPSolution}
            />
            <Route
              exact
              path={commonConstant.pathToSOPProfileList}
              component={SOPProfileList}
            />

            <Route exact path={commonConstant.pathCart} component={Cart} />
            <Route
              exact
              path={commonConstant.pathChooseMag}
              component={ChooseMagType}
            />
            <Route
              exact
              path={commonConstant.pathConfirmPayment}
              component={RenewConfirmPayment}
            />
            <Route
              exact
              path={commonConstant.pathUpgradeOTP}
              component={MUOtp}
            />
            <Route
              exact
              path={commonConstant.pathUpgradePhoneChange}
              component={PhoneChange}
            />
            <Route
              exact
              path={commonConstant.pathUpgradeChooseMag}
              component={MUMagType}
            />
            <Route
              exact
              path={commonConstant.pathUpgradeConfirm}
              component={MUConfirmPayment}
            />
            <Route
              exact
              path={commonConstant.pathLinkRichmenu}
              component={LinkRichmenu}
            />
            <Route
              exact
              path={commonConstant.pathPromotionAddressList}
              component={PromotionAddressList}
            />
            <Route
              exact
              path={commonConstant.pathCreatePromotionAddress}
              component={CreatePromotionAddress}
            />
            <Route
              exact
              path={commonConstant.pathCartPromotion}
              component={CartPromotion}
            />
            <Route
              exact
              path={commonConstant.pathProductPromotion}
              component={PromotionProduct}
            />
            <Route
              exact
              path={commonConstant.pathRemovePromotion}
              component={RemovePromotion}
            />
            <Route
              exact
              path={commonConstant.pathPromotionDetail}
              component={PromotionDetail}
            />
            <Route
              exact
              path={commonConstant.pathAbuddyContent}
              component={AbuddyContent}
            />
            <Route
              exact
              path={commonConstant.pathFOARegister}
              component={FOARegister}
            />
            <Route exact path={commonConstant.pathFOAOtp} component={FOAOtp} />
            <Route
              exact
              path={commonConstant.pathAhappy}
              component={() => <AHappy redirect="/a-happy-landing-line" />}
            />
            <Route
              exact
              path={commonConstant.pathLibraryProfile}
              component={LibraryProfile}
            />

            <Route
              exact
              path={commonConstant.pathShareTargetPicker}
              component={ShareTargetPicker}
            />

            <Route
              exact
              path={commonConstant.pathLibraryCategoryForm}
              component={LibraryCategoryForm}
            />

            <Route
              exact
              path={commonConstant.pathAdminCategory}
              component={AdminCategory}
            />

            <Route
              exact
              path={commonConstant.pathLibraryContentList}
              component={LibraryContent}
            />

            <Route
              exact
              path={commonConstant.pathLibrarySubContentList}
              component={LibrarySubContent}
            />

            <Route
              exact
              path={commonConstant.pathLibraryContentForm}
              component={LibraryContentForm}
            />

            <Route
              exact
              path={commonConstant.pathLibraryViewContent}
              component={ViewLibraryContent}
            />

            <Route
              exact
              path={commonConstant.pathLibraryViewContent}
              component={ViewLibraryContent}
            />

            <Route
              exact
              path={commonConstant.pathCoupon}
              component={CouponList}
            />

            <Route
              exact
              path={commonConstant.pathAssesment}
              component={Profile}
            />

            <Route
              exact
              path={commonConstant.pathInsertW}
              component={InsertW}
            />

            <Route
              exact
              path={commonConstant.pathPageAssesment}
              component={Pageassesment}
            />

            <Route
              exact
              path={commonConstant.pathPagequestion}
              component={Pagequestion}
            />

            <Route
              exact
              path={commonConstant.pathPagefinish}
              component={Pagefinish}
            />

            <Route
              exact
              path={commonConstant.pathPagefinishview}
              component={Finishview}
            />
            <Route
              exact
              path={commonConstant.pathPageAbudyassesment}
              component={Abudyassesment}
            />
            <Route
              exact
              path={commonConstant.pathPagePdpaasesment}
              component={Pdpaasesment}
            />
            <Route
              exact
              path={commonConstant.pathRedirectSmartReport}
              component={RedirectSmartReport}
            />
            <Route
              exact
              path={commonConstant.pathViewSmartReport}
              component={ViewSmartReport}
            />
            <Route exact path={commonConstant.pathPLP} component={PLP} />
            <Route exact path={commonConstant.pathPDP} component={PDP} />
            {/* Health and Wellness start */}

            <Route exact path={commonConstant.pathHealthnWellness} component={Navigation(healthAndWellness)} />

            <Route exact path={commonConstant.pathHnWAssessmentHome} component={Navigation(AssessmentHomePage)} />
            <Route exact path={commonConstant.pathHnWAssessment} component={Navigation(HnWAssessment)} />

            <Route exact path={commonConstant.pathBMI} component={Bmi} />
            <Route exact path={commonConstant.pathProfile} component={Navigation(HomeProfile)} />
            <Route exact path={commonConstant.pathHeight} render={(props) => <HeightAndWeight type={HEALTH_AND_WELLNESS_TYPE.HEIGHT} {...props} />} />
            <Route exact path={commonConstant.pathWeight} render={(props) => <HeightAndWeight type={HEALTH_AND_WELLNESS_TYPE.WEIGHT} {...props} />} />
            <Route exact path={commonConstant.pathWaist} render={(props) => <HeightAndWeight type={HEALTH_AND_WELLNESS_TYPE.WAIST} {...props} />} />
            <Route exact path={commonConstant.pathMuscle} render={(props) => <HeightAndWeight type={HEALTH_AND_WELLNESS_TYPE.MUSCLE} {...props} />} />
            <Route exact path={commonConstant.pathFat} render={(props) => <HeightAndWeight type={HEALTH_AND_WELLNESS_TYPE.FAT} {...props} />} />
            <Route exact path={commonConstant.pathHnWAssessmentScore} component={Navigation(Score)} />
            <Route exact path={commonConstant.pathHnWAssessmentHistoryLog} component={AssessmentHistoryLog} />

            <Route exact path={commonConstant.pathFoodCalories} component={Navigation(HnWFoodCalories)} />
            <Route exact path={commonConstant.pathFoodDetail} component={Navigation(HnwFoodDetail)} />
            <Route exact path={commonConstant.pathSearchHistory} component={Navigation(HnWSearchHistory)} />
            <Route exact path={commonConstant.pathGoalSearchHistory} component={Navigation(GoalsSearchHistory)} />

            <Route exact path={commonConstant.pathFoodLogHome} component={Navigation(FoodLogHome)} />
            <Route exact path={commonConstant.pathFoodLogCalendar} component={Navigation(FoodLogCalendar)} />
            <Route exact path={commonConstant.pathAddMenu} component={Navigation(AddMenu)} />
            <Route exact path={commonConstant.pathFoodLogList} component={HealthLogList} />
            <Route exact path={commonConstant.pathHnwHomeCalendar} component={Navigation(HnwHomeCalendar)} />
            <Route exact path={commonConstant.pathHnwGoalsHome} component={Navigation(HnwGoals)} />
            <Route exact path={commonConstant.pathHnwGoalsActivity} component={Navigation(HnwGoalsActivity)} />
            <Route exact path={commonConstant.pathHnwGoalsWeight} component={Navigation(HnwGoalsWeight)} />
            <Route exact path={commonConstant.pathHnwGoalsNutrition} component={Navigation(HnwGoalsNutrition)} />
            <Route exact path={commonConstant.pathHnwGoalsWater} component={Navigation(HnwGoalsWater)} />
            <Route exact path={commonConstant.pathHnwGoalsSupplements} component={Navigation(HnwGoalsSupplements)} />
            <Route exact path={commonConstant.pathHnwExerciseLogHome} component={Navigation(ExerciseLogHome)} />
            <Route exact path={commonConstant.pathHnwExerciseLogDetail} component={Navigation(ExerciseLogDetail)} />
            <Route exact path={commonConstant.pathHnwExerciseCategoryList} component={Navigation(ExerciseCategoryList)} />
            <Route exact path={commonConstant.pathExerciseLogList} component={ExerciseLogList} />
            <Route exact path={commonConstant.pathHnwExerciseLogCalendar} component={Navigation(ExerciseLogCalendar)} />
            <Route exact path={commonConstant.pathAccessCamera} component={AccessCamera} />
            <Route exact path={commonConstant.pathChallengeQuiz} component={ChallengeQuiz} />

            <Route exact path={commonConstant.pathChallengeTeamDashboard} component={ChallengeTeamDashboard} />
            <Route exact path={commonConstant.pathChallengeList} component={ChallengeList} />
            <Route exact path={commonConstant.pathChallengeMyDashboard} component={ChallengeMyDashboard} />
            <Route exact path={commonConstant.pathChallengeDetail} component={ChallengeDetail} />
            <Route exact path={commonConstant.pathAccessChallengeCamera} component={AccessChallengeCamera} />
            <Route exact path={commonConstant.pathChallengeMapDetail} component={ChallengeMapDetail} />
            <Route exact path={commonConstant.pathChallengeVideo} component={ChallengeVideoDetail} />
            {/* Health and Wellness end */}
            <Route exact path={commonConstant.pathChallengeMap} component={ChallengeMap} />
            <Route exact path={commonConstant.pathChallengeReward} component={ChallengeMyReward} />
            <Route exact path={commonConstant.pathBadgeScreen} component={ChallengeBadgeScreen} />
            <Route exact path={commonConstant.pathBadgeDetail} component={ChallengeBadgeDetail} />
            <Route exact path={commonConstant.pathMyPictures} component={ChallengeMyPictures} />
            <Route exact path={commonConstant.pathChallengeBadgeScreen} component={ChallengeBadgeScreen} />
            <Route exact path={commonConstant.pathChallengeBadgeDetail} component={ChallengeBadgeDetail} />
            <Route exact path={commonConstant.pathChallengeVideoPlayer} component={ChallengeVideoPlayer} />
            <Redirect to="/" />
          </Switch>
          <ScrollToTop />
        </div>
      </Router>
    );
  }
}

function mapStateToProps({ alert }) {
  return {
    alert,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clear: () => dispatch(alertActions.clear()),
    profileFetch: (code) => dispatch(profileFetch(code)),
    customerFetch: () => dispatch(customerFetch()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);