import { fetchCustomerBmi, updateCustomerBmi, fetchCustomerMonthlyBmi } from '../services/healthWellness/healthWellness.service';

import { GET_CUSTOMER_MONTHLY_BMI, GET_CUSTOMER_BMI, GET_LAST_MONTH_BMI } from './types';

export const fetchBmiData = () => (dispatch) => fetchCustomerBmi()
  .then(({ customers, ...rest }) => {
    dispatch({
      type: GET_CUSTOMER_BMI,
      payload: rest,
    });
    return rest;
  })
  .catch(function (error) {
    alert(error.message);
  });

export const updateBmiData = (healthInfo) => (dispatch) => updateCustomerBmi(healthInfo)
  .then(({ customers, ...rest }) => {
    dispatch({
      type: GET_CUSTOMER_BMI,
      payload: rest,
    });
    return rest;
  })
  .catch(function (error) {
    alert(error.message);
  });

  export const fetchMonthlyBmiData = (month, year, isPrevious) => dispatch => fetchCustomerMonthlyBmi(month, year)
  .then(res => {
    dispatch({
      type: isPrevious ? GET_LAST_MONTH_BMI : GET_CUSTOMER_MONTHLY_BMI,
      payload: res,
    });
  })
  .catch(function (error) {
    alert(error.message);
  });
