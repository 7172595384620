import React from 'react'
import { isCartABOType } from '../../../../services/promotion/utils.service'
import { isCartMemberType } from '../../../../services/promotion/utils.service'
import './userInfo.scss'
import { getCustomerGender } from '../../../../services/healthWellness/healthWellness.service'
import { GENDER } from '../../../../common/commonConstant'

const UserInfo = ({ customer }) => {

  const renderInfo = (image, label, value, unit = '') => {
    return (
      <div className="user-info-details-single-container">
        <img src={`/images/healthAndWellness/icon/${image}.svg`} />
        <div className="user-info-details-heading">{label}:</div>
        <div className="user-info-details-value">{value ?? '-'} {unit}</div>
      </div>
    )
  }

  return (
    <>
      <div className="user-info-details"> 
        <h5 className="user-info-name">{customer?.name}</h5>
        <p className="user-info-number">{customer?.type ? isCartABOType(customer?.type) ? `ABO#${customer?.amwayNumber}` : isCartMemberType(customer?.type) ? `Member#${customer?.amwayNumber}` : `Employee#${customer?.amwayNumber}` : ''}</p>
      </div>

      <div className="user-info-details-wrapper">
        {renderInfo(getCustomerGender() === GENDER.male ? 'gender-male' : 'gender', 'เพศ', getCustomerGender())}
        {renderInfo('age', 'อายุ', customer?.age, 'ปี')}
      </div>
    </>
  )
}

export default UserInfo