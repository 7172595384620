import React, {Component} from 'react';
import { connect } from 'react-redux';
import { alertActions, customerFetch } from '../../actions';
import LoadingOverlay from 'react-loading-overlay';
import { deepEqual } from '../../helpers';
import { activityLogService } from '../../services/activity-log.service';
class LinkRichmenu extends Component{
    constructor(props){
        super(props);
        this.state={
            profile: [],
            customer: null,
            dst: "",
            tag: "",
            show: false,
            createlog: false
        }
    }

    componentDidMount() {
        const { location, params } = this.props;
        
        let dst = params["linkrichmenu"].replace('dst=','');
        let tag = params["tag"];
        
        if (tag === undefined) {
            const splitParams = dst.split('&tag=')
            if (splitParams.length === 2) {
                dst = splitParams[0]
                tag = splitParams[1]
            }
        }
        this.setState({ dst: dst, tag: tag, show: true });
    }

    async componentWillReceiveProps(nextProps) {
        let customer = nextProps.customer;

        const { profile } = nextProps;

        if (profile && !deepEqual(this.props.profile, profile)) {
            this.props.getCustomer();
        } else
        {
            let data = {}
            if (customer && customer.type) {
                 data = {
                    activityTag: this.state.tag,
                    actionType: 'LINK',
                    userType: customer.type.toUpperCase(),
                    mid: customer.uid,
                    displayName: customer.displayName,
                    amwayNumber: customer.amwayNumber,
                }
            } else {
                data = {
                    activityTag: this.state.tag,
                    actionType: 'LINK',
                    userType: "NON_ASC",
                    mid: nextProps.profile.userId,
                    displayName: nextProps.profile.displayName,
                };
            }

            await activityLogService.createActivityLog(data).then((response) => {
                    window.location.assign(this.state.dst)
                });
        }
    }

    render(){
        const { show } = this.state;
        return (
                <div className="regisPage home">
                    <LoadingOverlay
                        active={true}
                        spinner
                        text='Loading'
                        styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
                    />
                </div>
            )
    }
}

function mapStateToProps({ profile, customer }) {

    return {
        profile,
        customer
    }
}

function mapDispatchToProps(dispatch) {
    return {
      showError: (message) => dispatch(alertActions.showError(message)),
      getCustomer: () => dispatch(customerFetch())
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(LinkRichmenu);
