import React from 'react';
import './HealthLogCard.scss';
import { useLocation } from 'react-router-dom';
import { HNW_EXERCISE_LOG, logListCardMapping } from '../commonConstant';
import { calculateDifference, thaiDate } from '../../utill.func';

export default function HealthLogCard({ item, exerciseType }) {
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const type = params.get('type');
    const { icon, unit, timeIcon, timeUnit } = logListCardMapping[type || HNW_EXERCISE_LOG];
    return (
        <div className='log-card-info'>
            <div className={HNW_EXERCISE_LOG === exerciseType ? 'exercise-log-card-info-img' : 'log-card-info-img'}>
                <img src={item?.image} />
            </div>
            <div className='log-card-info-content'>
                <div className='log-title'>{item?.name}</div>
                <div className='log-description-wrapper'>
                <div className='log-description'>
                        <img className='food-log-img' src={icon} />
                        <span className='food-log-value'>{item?.amount? item?.amount :'-'}</span>
                        <span className='food-log-unit'>{unit}</span>
                    </div>
                    {exerciseType ? (<div className='log-description'>
                        <img className='food-log-img' src={timeIcon} />
                        {/* <span className='food-log-value'>{item?.exerciseTime? item?.exerciseTime : '-'}</span> */}
                        <span className='food-log-value'>{calculateDifference(item.startTime, item.endTime)}</span>
                        <span className='food-log-unit'>{timeUnit}</span>
                    </div>) : ''}
                </div>
                <div className='log-date'>{thaiDate(`${item.createdDate}`, 'dd LLLL yy')}<span>{thaiDate(`${item.createdDate}`, 'pp')}</span></div>
            </div>

        </div>
    );
}
