import { getBodyKeyRecord, verifyBodyKeyId } from '../../services/challenge/challengeBodyKey.service';
import {
  GET_BODY_KEY_RECORD,
  GET_BODY_KEY_RECORD_ERROR,
  GET_BODY_KEY_RECORD_SUCCESS,
  GET_BODY_KEY_VERIFY,
  GET_BODY_KEY_VERIFY_ERROR,
  GET_BODY_KEY_VERIFY_SUCCESS,
} from '../types';

export const verifyBodyKeyIdAction = (params) => (dispatch) => {
  dispatch({ type: GET_BODY_KEY_VERIFY });
  return verifyBodyKeyId(params)
    .then((result) => {
      dispatch({
        type: GET_BODY_KEY_VERIFY_SUCCESS,
        payload: result,
      });
      return result;
    })
    .catch(function (error) {
      dispatch({ type: GET_BODY_KEY_VERIFY_ERROR, payload: error.message });
    });
};

export const getBodyKeyRecordAction = (params) => (dispatch) => {
  dispatch({ type: GET_BODY_KEY_RECORD });
  return getBodyKeyRecord(params)
    .then((result) => {
      dispatch({
        type: GET_BODY_KEY_RECORD_SUCCESS,
        payload: result,
      });
      return result;
    })
    .catch(function (error) {
      dispatch({ type: GET_BODY_KEY_RECORD_ERROR, payload: error.message });
    });
};