import React from 'react';
import { HEALTH_LOG_HEADING, HEALTH_LOG_SUBHEADING } from '../commonConstant';
import HealthLogExcerciseCard from '../healthLogExcerciseCard/HealthLogExcerciseCard';
import HealthLogFoodCard from '../healthLogFoodCard/HealthLogFoodCard';
import HealthLogWaterCard from '../healthLogWaterCard/HealthLogWaterCard';
import './HealthHomeCard.scss';

const HealthHomeCard = ({ logData }) => {

    return (
        <div className='health-log-section'>
            <img src='/images/healthAndWellness/foodLog/bg-log.svg' className='w-100 homelog-mobile-lg' />
            <img src='/images/healthAndWellness/foodLog/bg-log-sm.svg' className='w-100 homelog-mobile-sm' />
            <div className='health-log-content-wrapper'>
                <div className='health-log-content-food'>
                    <h5 className='health-log-content-heading'>{HEALTH_LOG_HEADING}</h5>
                    <p className='health-log-content-subheading'>{HEALTH_LOG_SUBHEADING}</p>
                    {
                        logData?.foodCard &&
                        <HealthLogFoodCard {...logData.foodCard} />
                    }
                </div>
                <div className='food-log-container two-cards'>
                    {
                        logData?.waterCard &&
                        <HealthLogWaterCard {...logData.waterCard} />
                    }
                    {
                        logData?.excerciseCard &&
                        <HealthLogExcerciseCard {...logData.excerciseCard} />
                    }
                </div>
            </div>
        </div>
    )
}

export default HealthHomeCard;