import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import { osName } from 'react-device-detect';
import { connect } from 'react-redux';

import { alertActions } from '../../actions';
import { commonConstant } from '../../common';
import { customerService, activityService, amwayService } from '../../services';
import { Modal, AWDatepicker } from '../../helpers';
import _ from 'lodash';

const today = new Date();
const maxYear = today.getFullYear() + 540;
const minYear = today.getFullYear() + 443;

class Member extends Component {
  constructor(props) {
    super(props);

    const customer = JSON.parse(sessionStorage.getItem('customer'));
    if (sessionStorage.getItem('customer') === null || sessionStorage.getItem('customer') === 'undefined') {
      window.location.replace(commonConstant.pathHome);
    }

    this.state = {
      customer: customer,
      birthday: '',
      distNum: '',
      loading: false,
      error: false,
      isOpenCalendar: false,
      accept: false,
      isOpenModal: false,
      modalContent: 'notfound',
      profile: ''
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (!location.state || !location.state.myMember || location.state.myMember.accountMst.accountTypeCd !== 'Member') return window.location.replace(commonConstant.pathHome);
    this.setState({ profile: location.state.myMember })

  }

  onPaste = e => {
    e.preventDefault();
    return false;
  };
  handleCheckBox = () => this.setState({ accept: !this.state.accept })
  handleChange = e => this.setState({ [e.target.name]: e.target.value });
  handleDate = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleCloseDate = () => this.setState({ isOpenCalendar: false });
  handleOkDate = _day => {
    const birthday = `${_day.getDay()} / ${_day.getMonth()} / ${_day.getFullYear()}`;
    this.setState({ isOpenCalendar: false, birthday });
  };
  toggleModal = (content) => {
    this.setState({
      isOpenModal: !this.state.isOpenModal,
      modalContent: content,
    });
  }

  handleCheckValidDate = () => {
    const { birthday } = this.state;
    const dateParts = birthday.split('-');
    const dateObject = new Date(birthday);
    if (dateObject.getFullYear() === +dateParts[0] && dateObject.getMonth() === dateParts[1] - 1 && dateObject.getDate() === +dateParts[2]) {
      return true;
    }
    return false;
  }

  handleModalContent = () => {
    const { modalContent, profile } = this.state;
    switch (modalContent) {
      case 'duplicate':
        return { title: `รหัส ${profile.accountMst.aboNum} มีการผูกกับ LINE ID อื่น`, detail: 'กรุณาคลิกปุ่มเปลี่ยนบัญชี เพื่อลงทะเบียน\nAmway Social Connect' };
      case 'wrongBirtDay':
        return { title: 'ข้อมูลสมาชิกไม่ถูกต้อง', detail: 'กรุณาตรวจสอบวันเกิดใหม่อีกครั้ง' }
      default:
        break;
    }
  }

  handleSubmit = async e => {
    window.scrollTo(0, 1);
    const { profile } = this.state;
    const amwayNumber = profile.accountMst.aboNum

    e.preventDefault();
    this.setState({
      loading: true,
    });

    if (!this.handleCheckValidDate()) {
      this.toggleModal('invalidbd');
      this.setState({ loading: false });
      return false;
    }

    const date = new Date(this.state.birthday);
    const convertYearBEtoAD = date.getFullYear();
    const month = date.getMonth() + 1;
    const monthWithZero = month <= 9 ? `0${month.toString()}` : month.toString();
    const day = date.getDate().toString();
    const dayWithZero = day <= 9 ? `0${day}` : day;
    const birthdayKey = convertYearBEtoAD.toString() + monthWithZero + dayWithZero;
    const searchKey = amwayNumber + birthdayKey;
    let userBirthDate = null;
    let inputBirthDate = '';

    // const getBirthDate = profile.partyList[0].partyMst;
    // const getBirthDate = profile.partyList.map((party) => {
    //   if (party.partyMst.birthdate.substr(0, 10) === date.toISOString().substr(0, 10)) {
    //     return party.partyMst
    //   }
    // })
    let findIndex = profile.partyList.findIndex(party => {
      return party.partyMst.birthdate.substr(0, 10) == date.toISOString().substr(0, 10)
    });
    console.log('test', findIndex);
    if (findIndex !== -1) {

      inputBirthDate = date.toISOString().substr(0, 10);
      userBirthDate = profile.partyList[findIndex].partyMst.birthdate.substr(0, 10);

      console.log('test', findIndex);
      console.log("userBirthDate", userBirthDate);
      console.log('inputBirthDate', inputBirthDate);


    } else {

      this.setState({ isOpenModal: false, loading: false });
      this.toggleModal('wrongBirtDay');
      return
    }

    console.log('profile.partyList[findIndex].partyMst////', profile.partyList[findIndex].partyMst.partyId
    );

    let partyId2 = profile.partyList[findIndex].partyMst.partyId
    console.log('partyId2', partyId2);

    // if (getBirthDate[0] && getBirthDate[0].birthdate) {

    //   inputBirthDate = date.toISOString().substr(0, 10);
    //   userBirthDate = getBirthDate[0].birthdate.substr(0, 10);

    //   console.log('inputBirthDate', inputBirthDate);

    //   console.log('userBirthDate', userBirthDate);
    // }

    // if (inputBirthDate !== userBirthDate) {
    //   this.setState({ isOpenModal: false, loading: false });
    //   this.toggleModal('wrongBirtDay');
    //   return;
    // }

    let cidKey = searchKey;
    if (profile && findIndex !== -1) {
      // const cleanData = _.filter(profile.partyList, function (o) { return o.taxList[0] && o.taxList[0].taxTypeCd === 'CID' && o.partyMst && o.partyMst.roleCd === 'BusinessOwner'; });
      // const last4Digit = cleanData.map(function (raw) {
      //   return raw.taxList[0].taxId;
      // });
      // cidKey = last4Digit ? last4Digit[0] : searchKey;
      cidKey = profile.partyList[findIndex].taxList[0].taxId;
    }

    const myMember = await customerService.findMemberNumber(amwayNumber);
    console.log('myMember: ', myMember);
    if (myMember && (myMember.uid !== this.state.customer.uid) && myMember.partyId == partyId2) {
      this.setState({ isOpenModal: false, loading: false, oldLineId: myMember.uid, oldMember: myMember });
      this.toggleModal('duplicate');
      return false;
    }


    const customer = {
      name: profile.accountMst.accountName,
      _id: this.state.customer._id,
      amwayNumber: parseInt(amwayNumber),
      birthday: `${convertYearBEtoAD.toString()}-${monthWithZero}-${dayWithZero}`,
      // partyId: profile.accountMst.primaryPersonPartyId,
      partyId: partyId2,
      socialType: 'LINE',
    };
    console.log('customer', customer);
    console.log('partyId2', partyId2);
    const updated = await customerService.update(customer._id, customer);
    if (updated) {
      const params = {
        mid: this.state.customer.uid,
        cid: cidKey,
        customerId: this.state.customer._id,
        info: osName === 'IOS' ? 'iPhone' : osName,
        status: 'unblock',
        displayname: this.state.customer.displayName,
      };
      sessionStorage.setItem('customer', JSON.stringify(updated));
      const saveLineMID = await amwayService.saveLineMID(params);
      const { replymessage } = saveLineMID.data.lineMIDResult;
      if (replymessage !== 'Success') {
        this.props.showError('ระบบไม่สามารถบันทึกข้อมูลได้กรุณาติดต่อเจ้าหน้าที่');
        this.setState({ loading: false });
        return false;
      } else {
        // activityService.create({
        //   uid: this.state.customer.uid,
        //   userId: this.state.customer._id,
        //   status: 'incomplete',
        //   action: 'SELECT_PRODUCT',
        // });

        // const params = {
        //   registerStatus: 'incomplete',
        //   action: 'SELECT_PRODUCT',
        // };

        // customerService.update(this.state.customer._id, params);

        //return this.props.history.push('/interest');
        return this.props.history.push('/success', { 'user': updated });
      }
    }
  }

  handleModalOk = async () => {
    const { modalContent, isOpenModal } = this.state;
    this.setState({ isOpenModal: !isOpenModal });
    if (modalContent === 'duplicate') {
      this.setState({ isOpenModal: !isOpenModal });
      // return window.location = '/otp';
      return this.props.history.push('/changeMID', { myMember: this.props.location.state.myMember, oldLineId: this.state.oldLineId, oldMember: this.state.oldMember })
    } else if (modalContent === 'invalidbd') {
      this.setState({ isOpenModal: !isOpenModal });
    } else if (modalContent === 'notfound') {
      this.setState({ isOpenModal: !isOpenModal });
    } else if (modalContent === 'wrongBirtDay') {
      this.setState({ isOpenModal: !isOpenModal });
    } else {
      this.setState({ isOpenModal: !isOpenModal });
      return window.location = 'tel:027258000';
    }
  }

  render() {
    const { birthday, accept, isOpenModal, modalContent, profile } = this.state;
    const isTrue = birthday && accept ? true : false;

    //const isTrue = tel.length === 10 && accept;

    const content = this.handleModalContent();
    return (
      <div className="regisPage member-page">
        <LoadingOverlay active={this.state.loading} spinner text="Loading" styles={{ wrapper: { width: '100%', minHeight: '100%' } }} />
        {
          isOpenModal ?
            <Modal
              title={content.title}
              detail={content.detail}
              type='warning'
              closeBtn={modalContent === 'duplicate' ? 'closeBtn' : null}
              fnOk={this.handleModalOk}
              fnClose={this.toggleModal}
              okText={modalContent === 'duplicate' ? 'เปลี่ยนบัญชี' : 'ตกลง'}
              okBtn={modalContent !== 'card'} /> : null
        }

        <div className="logoAmway"><img src="/images/logoAmway-black.svg" alt="" /></div>
        <form onSubmit={this.handleSubmit}>
          <h2 className="mb-90">กรุณากรอกข้อมูลลงทะเบียน</h2>
          <div className="amwayNumber-container">
            <small>หมายเลขสมาชิกแอมเวย์</small>
            <h2 className="mb-32 amwayNumber">{profile && profile.accountMst.aboNum}</h2>
          </div>

          <div className="secForm">
            <img src="/images/Gift.svg" alt="" />
            <div className="inputForm">
              <label>วันเกิด</label>
              <AWDatepicker
                id="birthday" name="birthday"
                maxYear={maxYear}
                minYear={minYear}
                value={birthday}
                onChange={this.handleChange} />
            </div>
          </div>
          <div className="text-notice-new">เพื่อตรวจสอบกับฐานข้อมูลที่ท่านทำการสมัครไว้ <br /> และรักษาสิทธิประโยชน์ที่จะได้รับในอนาคต</div>

          <div className="abo-text-center">
            <div className="input-checkbox">
              <span onClick={this.handleCheckBox} className={`_box-check ${accept ? '_active' : ''}`}>
                {
                  accept ?
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.87987 11.8301L13.8799 3.83012L13.1199 3.17012L6.11987 11.1701L6.87987 11.8301ZM2.16987 8.38012L6.16987 11.8801L6.82987 11.1201L2.82987 7.62012L2.16987 8.38012ZM13.8799 3.83012C13.9232 3.78021 13.9563 3.72226 13.9772 3.65958C13.9982 3.59689 14.0066 3.53069 14.0019 3.46476C13.9973 3.39883 13.9797 3.33446 13.9502 3.27533C13.9207 3.21619 13.8798 3.16345 13.8299 3.12012C13.78 3.07678 13.722 3.0437 13.6593 3.02276C13.5966 3.00181 13.5304 2.99343 13.4645 2.99807C13.3986 3.00271 13.3342 3.0203 13.2751 3.04982C13.2159 3.07934 13.1632 3.12021 13.1199 3.17012L13.8799 3.83012ZM6.49987 11.5001L6.16987 11.8801C6.27139 11.966 6.40241 12.0088 6.53504 11.9994C6.66766 11.9901 6.79139 11.9293 6.87987 11.8301L6.49987 11.5001ZM2.82987 7.62012C2.72909 7.53259 2.59767 7.48869 2.46452 7.49807C2.33137 7.50745 2.20739 7.56933 2.11987 7.67012C2.03235 7.7709 1.98845 7.90232 1.99783 8.03547C2.0072 8.16862 2.06909 8.29259 2.16987 8.38012L2.82987 7.62012Z" fill="#2C2C2C" />
                    </svg> : null
                }
              </span>
              <Link onClick={this.handleCondition}>ยอมรับเงื่อนไข</Link>
            </div>
          </div>
          <div className="text-center mt-90"><button className={`register-next-button ${isTrue ? 'active' : ''}`} type="submit" disabled={!isTrue}>ถัดไป</button></div>
        </form>
      </div >
    );
  }
}

function mapDispatchToProps(dispatch) {
  return ({
    showError: (message) => dispatch(alertActions.showError(message)),
  });
}

export default connect(null, mapDispatchToProps)(Member);
