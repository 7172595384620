import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import "./finishview.scoped.scss";
import Spinner from '../components/Spinner'
import axios from "axios";
import { createBrowserHistory } from 'history';
import Card from 'react-bootstrap/Card';
import { Col, Row } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import Slider from "react-slick";
import queryString from 'query-string';
import { AiFillHeart } from "react-icons/ai";
import { Avatar, Progress } from 'antd';
import LoadingOverlay from 'react-loading-overlay';
import { commonConstant } from "../../../common";
export const history = createBrowserHistory();


const Finishview = (props) => {
    let [loading2, setLoading2] = useState(false);
    const history = useHistory();

    const [listAssesment, setlistAssesment] = useState([
    ]);
    const [listsolutiont, setlistsolutiont] = useState([
    ]);

    const [showCardBody, setShowCardBody] = useState(Array(listAssesment.length).fill(false));
    const [content_suggestion, setcontent_suggestion] = useState(
    );
    const [title, settitle] = useState(
    );
    const [description, setdescription] = useState(
    );

    const avatarStyle = {
        marginRight: '8px', // Adjust the spacing as desired
        fontSize: '24px', // Increase the font size for a larger Avatar
    };
    const avatarStyle2 = {
        marginRight: '5px', // Adjust the spacing as desired


        // Increase the font size for a larger Avatar
    };
    const avatarStyle3 = {
        padding: '4px', // Adjust the spacing as desired
        marginRight: '6px'

        // Increase the font size for a larger Avatar
    };
    const avatarStyle4 = {
        padding: '4px', // Adjust the spacing as desired
        marginRight: '8px'

        // Increase the font size for a larger Avatar
    };


    useEffect(() => {
        getStore();
    }, []);

    const getStore = () => {
        var currentURL = queryString.parse(history.location.search);
        const assessment_id = queryString.parse(currentURL['liff.state']).assessment_id;
        const customer_id = queryString.parse(currentURL['liff.state']).user_id;
        const type = queryString.parse(currentURL['liff.state']).type;
        const roomid = queryString.parse(currentURL['liff.state']).roomId;
        console.log('ass', customer_id);
        console.log('assessment_id', assessment_id);

        setLoading2(true)
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_API_URL_UAT}/assessment-public/result?assessment_id=${assessment_id}&user_id=${customer_id}&type=${type}&roomId=${roomid}`,
            headers: {
                Accept: "application/json",
                Authorization : commonConstant.dummyToken 
            }
        }).then((res) => {

            setlistAssesment(res.data.data.result.filter(obj => obj.type_score !== "beauty"))
            setlistsolutiont(res.data.data.solution?.suggestions_solutions)

            setLoading2(true)
            axios({
                method: "GET",
                url: `${process.env.REACT_APP_API_URL_UAT}/assessment-public/` + assessment_id,
                headers: {
                    Accept: "application/json",
                    Authorization : commonConstant.dummyToken 
                }
            })
                .then((res) => {
                    console.log(res.data.data.question);
                    setcontent_suggestion(res.data.data.content_suggestion)
                    settitle(res.data.data.title)
                    setdescription(res.data.data.description)
                    setLoading2(false)
                })
                .catch((err) => {
                    setLoading2(false)
                });


        }).catch((err) => {
            setLoading2(false)
        });
    };


    const Clickbanner = (data) => {

        console.log(data);

        if (data.link) {

            window.location.href = data.link;
        }



    }

    const settings = {
        dots: false,
        arrow: false, // Set to false to remove the arrows
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        appendDots: dots => (
            <div>
                <ul
                    style={{
                        position: "absolute",
                        bottom: "-50px",
                        width: "100%",
                        padding: "0",
                        margin: "0",
                        listStyle: "none",
                        textAlign: "center"
                    }}
                >
                    {dots.map((dot, index) => (
                        <li
                            key={index}
                            style={{
                                display: "inline-block",
                                margin: "0 4px",
                                width: "10px",
                                height: "10px",
                                borderRadius: "50%",
                                backgroundColor: dot.props.className === "slick-active" ? "#83c346" : "gray"
                            }}
                        />
                    ))}
                </ul>
            </div>
        ),
    };
    const [activeAccordion, setActiveAccordion] = useState(null);

    const handleClick = (index) => {
        const updatedShowCardBody = [...showCardBody];
        updatedShowCardBody[index] = !updatedShowCardBody[index];
        setShowCardBody(updatedShowCardBody);
    };


    const getProgressPercentage = (level) => {

        if (level === 'ไม่มี') {
            return 0;
        } else if (level === 'ต่ำ') {
            return 20;
        } else if (level === 'กลาง') {
            return 50;
        } else if (level === 'สูง') {
            return 100;
        } else if (level === 'มี') {
            return 100;
        }
    };





    return (
        <>
            <div className="page overflow_disable" style={{ minHeight: '130vh', overflowX: 'hidden' }}>

                <div className="Success2" style={{ position: 'relative' }}>
                    <img src="/images/assesment/H1.png" alt="รูปภาพ" style={{ position: 'absolute', zIndex: '1', width: '30%', marginTop: '20px', right: '10px' }} />
                    <span style={{ fontSize: '22px', fontFamily: 'Sukhumvit Set Extra Bold', textAlign: 'center', marginTop: '10px' }}>Finish</span><br />
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                        <Card style={{
                            borderRadius: "25px",
                            minHeight: "20vh", background: "rgba(255, 255, 255, 18%)",
                            width: '90vw', maxWidth: '400px',
                            zIndex: 1,
                            borderColor: '#F7FAFF',
                            backdropFilter: 'blur(15px)',
                            WebkitBackdropFilter: 'blur(15px)',
                            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                        }}>
                            <Card.Body>
                                <div style={{ paddingRight: '60px' }}>
                                    <span style={{ marginTop: '10px', color: 'green', fontFamily: 'Sukhumvit Set Extra Bold', fontSize: '19px' }}>{title} </span>
                                </div>


                                <p style={{ fontSize: '12px', fontFamily: 'Sukhumvit Set Extra Medium' }}>{description}</p>

                            </Card.Body>
                        </Card>

                    </div>



                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {window.screen.width > 390 ?
                            <div style={{ marginBottom: activeAccordion ? '200px' : '0' }}>
                                <Card style={{
                                    marginTop: '20px',
                                    borderRadius: "25px", minHeight: "20vh",
                                    background: "rgba(255, 255, 255, 0.18)",
                                    width: '90vw',
                                    maxWidth: '400px',
                                    zIndex: 1,
                                    borderColor: '#F7FAFF',
                                    transition: 'margin-bottom 0.3s ease-in-out',
                                    WebkitBackdropFilter: 'blur(15px)',
                                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'

                                }}>
                                    <Card.Body>
                                        <span style={{ marginTop: '10px', color: 'green', fontFamily: 'Sukhumvit Set Extra Bold', fontSize: '19px' }}>Result</span>

                                        {listAssesment.map((data, index) => (
                                            <Card
                                                key={index}
                                                style={{ borderRadius: '26px', marginBottom: '15px', marginTop: '15px', zIndex: 1, borderColor: '#F7FAFF', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}
                                            >
                                                <Card.Header
                                                    style={{ height: '80px', display: 'flex', alignItems: 'center' }}
                                                    onClick={() => handleClick(index)}
                                                >
                                                    <Row style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                                        <Col xs={{ span: 4, offset: 1 }} lg={{ span: 6, offset: 2 }} style={{ textAlign: 'end' }}>

                                                            {data.type_score === 'cvd' && <Avatar size={40} src="/images/assesment/CVD.png" style={avatarStyle} />}
                                                            {data.type_score === 'mobility' && <Avatar size={40} src="/images/assesment/Mobility.png" style={avatarStyle} />}
                                                            {data.type_score === 'gut' && <Avatar size={40} src="/images/assesment/GUT.png" style={avatarStyle} />}
                                                            {data.type_score === 'weight' && <Avatar size={40} src="/images/assesment/Weight.png" style={avatarStyle4} />}
                                                            {data.type_score === 'beauty' && <Avatar size={40} src="/images/assesment/GUT.png" style={avatarStyle} />}
                                                            <div style={{ position: 'absolute', top: '50%', left: '48%', transform: 'translate(-50%, -50%)' }}>
                                                                <Progress
                                                                    type="circle"
                                                                    percent={getProgressPercentage(data.level)}
                                                                    width={70}
                                                                    strokeWidth={6}
                                                                    showInfo={false}
                                                                />
                                                            </div>

                                                        </Col>
                                                        <Col xs={{ span: 12, offset: 1 }} lg={{ span: 8, offset: 2 }} style={{ lineHeight: '16px' }}>
                                                            <div style={{ fontSize: '18px', color: '#0f774a', display: 'flex', flexDirection: 'column' }}>
                                                                <span style={{
                                                                    marginBottom: '5px',
                                                                    fontFamily: 'Sukhumvit Set Extra Bold',
                                                                    // textTransform: data.type_score === 'cvd' ? 'uppercase' : 'capitalize'
                                                                }}>
                                                                    {data.type_score === 'cvd' && 'หัวใจและหลอดเลือด'}
                                                                    {data.type_score === 'mobility' && 'กระดูกและข้อต่อ'}
                                                                    {data.type_score === 'gut' && 'ระบบขับถ่าย'}
                                                                    {data.type_score === 'weight' && 'น้ำหนักตัว'}
                                                                    {data.type_score === 'beauty' && 'beauty'}
                                                                </span>
                                                            </div>
                                                            <div style={{ fontSize: '17px', fontFamily: 'Sukhumvit Set Extra Bold', color: '#0f774a', lineHeight: '20px' }}>
                                                                <span>ระดับความเสี่ยง :</span>
                                                                <span>{data.level ? data.level : 'ไม่มี'}</span>
                                                            </div>
                                                        </Col>
                                                        <Col xs={{ span: 4, offset: 1 }} lg={{ span: 6, offset: 2 }} style={{ textAlign: 'center' }}>
                                                            {showCardBody[index] ? <UpOutlined /> : <DownOutlined />}
                                                        </Col>
                                                    </Row>
                                                </Card.Header>
                                                {showCardBody[index] && (
                                                    <Card.Body className="cardbody">
                                                        <div style={{ maxHeight: '200px', overflow: 'hidden' }}>
                                                            {data.content_suggestion}
                                                        </div>
                                                    </Card.Body>
                                                )}
                                            </Card>
                                        ))}


                                    </Card.Body>
                                </Card>
                            </div>
                            : <div style={{ marginBottom: activeAccordion ? '200px' : '0' }}>
                                <Card style={{
                                    borderRadius: "25px", minHeight: "20vh",
                                    background: "rgba(255, 255, 255, 0.18)",
                                    width: '90vw',
                                    maxWidth: '400px',
                                    zIndex: 1,
                                    marginTop: '20px',
                                    borderColor: '#F7FAFF',
                                    transition: 'margin-bottom 0.3s ease-in-out',
                                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'

                                }}>
                                    <Card.Body>
                                        <span style={{ marginTop: '10px', color: 'green', fontWeight: 'bold', fontSize: '17px' }}>Result</span>

                                        {listAssesment.map((data, index) => (
                                            <Card
                                                key={index}
                                                style={{ borderRadius: '26px', marginBottom: '15px', marginTop: '15px', borderColor: '#F7FAFF', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}
                                            >
                                                <Card.Header
                                                    style={{ height: '80px', display: 'flex', alignItems: 'center' }}
                                                    onClick={() => handleClick(index)}
                                                >
                                                    <Row style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                                        <Col xs={{ span: 4, offset: 1 }} lg={{ span: 6, offset: 2 }} style={{ textAlign: 'end' }}>

                                                            {data.type_score === 'cvd' && <Avatar size={40} src="/images/assesment/CVD.png" style={avatarStyle2} />}
                                                            {data.type_score === 'mobility' && <Avatar size={40} src="/images/assesment/Mobility.png" style={avatarStyle2} />}
                                                            {data.type_score === 'gut' && <Avatar size={40} src="/images/assesment/GUT.png" style={avatarStyle2} />}
                                                            {data.type_score === 'weight' && <Avatar size={40} src="/images/assesment/Weight.png" style={avatarStyle3} />}
                                                            {data.type_score === 'beauty' && <Avatar size={40} src="/images/assesment/GUT.png" style={avatarStyle2} />}
                                                            <div style={{ position: 'absolute', top: '50%', left: '48%', transform: 'translate(-50%, -50%)' }}>
                                                                <Progress
                                                                    type="circle"
                                                                    percent={getProgressPercentage(data.level)}
                                                                    width={60}
                                                                    strokeWidth={6}
                                                                    showInfo={false}
                                                                />
                                                            </div>

                                                        </Col>
                                                        <Col xs={{ span: 12, offset: 1 }} lg={{ span: 8, offset: 2 }} style={{ lineHeight: '20px' }}>
                                                            <div style={{ fontSize: '16px', color: '#0f774a', display: 'flex', flexDirection: 'column' }}>
                                                                <span style={{
                                                                    marginBottom: '5px',
                                                                    fontFamily: 'Sukhumvit Set Extra Bold',
                                                                    textTransform: data.type_score === 'cvd' ? 'uppercase' : 'capitalize'
                                                                }}>
                                                                    {data.type_score === 'cvd' && 'หัวใจและหลอดเลือด'}
                                                                    {data.type_score === 'mobility' && 'กระดูกและข้อต่อ'}
                                                                    {data.type_score === 'gut' && 'ระบบขับถ่าย'}
                                                                    {data.type_score === 'weight' && 'น้ำหนักตัว'}
                                                                    {data.type_score === 'beauty' && 'beauty'}
                                                                </span>
                                                            </div>
                                                            <div style={{ fontSize: '15px', fontFamily: 'Sukhumvit Set Extra Bold', color: '#0f774a' }}>
                                                                <span>ระดับความเสี่ยง :</span>
                                                                <span>{data.level ? data.level : 'ไม่มี'}</span>
                                                            </div>
                                                        </Col>
                                                        <Col xs={{ span: 4, offset: 1 }} lg={{ span: 6, offset: 2 }} style={{ textAlign: 'center' }}>
                                                            {showCardBody[index] ? <UpOutlined /> : <DownOutlined />}
                                                        </Col>
                                                    </Row>
                                                </Card.Header>
                                                {showCardBody[index] && (
                                                    <Card.Body className="cardbody">
                                                        <div style={{ maxHeight: '200px', overflow: 'hidden' }}>
                                                            {data.content_suggestion}
                                                        </div>
                                                    </Card.Body>
                                                )}
                                            </Card>
                                        ))}


                                    </Card.Body>
                                </Card>
                            </div>
                        }


                    </div>

                </div>


                <div>
                    <div>
                        <div>
                            <div>



                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {window.screen.width > 400 ?
                                        <Card style={{ borderRadius: "25px", background: "rgba(255, 255, 255, 18%)", width: '90vw', maxWidth: '400px', zIndex: 1, borderColor: '#F7FAFF', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', marginTop: '20px' }}>
                                            <Card.Body>
                                                <span style={{ marginTop: '10px', color: 'green', fontFamily: 'Sukhumvit Set Extra Bold', fontSize: '19px' }}>Suggest</span>
                                                <div
                                                    style={{
                                                        fontSize: '13px',
                                                        fontFamily: 'Sukhumvit Set Extra Medium',
                                                        marginTop: '13px'
                                                    }}
                                                    dangerouslySetInnerHTML={{ __html: content_suggestion }}
                                                />
                                            </Card.Body>
                                        </Card> : <Card style={{ borderRadius: "25px", minHeight: "20vh", background: "rgba(255, 255, 255, 18%)", width: '90vw', maxWidth: '400px', zIndex: 1, borderColor: '#F7FAFF', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', marginTop: '20px' }}>
                                            <Card.Body>
                                                <span style={{ marginTop: '10px', color: 'green', fontFamily: 'Sukhumvit Set Extra Bold', fontSize: '17px' }}>Suggest</span>
                                                <div
                                                    style={{
                                                        fontSize: '13px',
                                                        fontFamily: 'Sukhumvit Set Extra Medium',
                                                        marginTop: '13px'
                                                    }}
                                                    dangerouslySetInnerHTML={{ __html: content_suggestion }}
                                                />
                                            </Card.Body>
                                        </Card>
                                    }


                                </div>


                                <div >

                                    <Card style={{ borderRadius: "25px", minHeight: "20vh", background: "rgba(255, 255, 255, 18%)", maxWidth: '400px', zIndex: 1, borderColor: '#F7FAFF', overflowX: 'hidden' }}>
                                        <Card.Body>
                                            <span style={{ marginTop: '10px', fontFamily: 'Sukhumvit Set Extra Bold', fontSize: '20px' }}>Solution Suggest </span>
                                            <Slider {...settings} >
                                                {listsolutiont?.map((data, key) => (
                                                    <div key={key} className="card2" style={{ width: '200px' }}>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <span style={{ marginRight: '5px', fontFamily: 'Sukhumvit Set Extra Bold', color: '#707070' }}>{data.number}</span>
                                                            <AiFillHeart style={{ color: '#83C346' }} />
                                                        </div>
                                                        <div className="card-image" onClick={() => Clickbanner(data)}>
                                                            <img src={data.url} alt="Product" />
                                                        </div>
                                                        <div className="card-content">
                                                            <div style={{ textAlign: 'initial' }}><span>{data.content}</span></div>
                                                            <div style={{ textAlign: 'initial', marginTop: '10px', fontFamily: 'Sukhumvit Set Extra Bold', fontSize: '16px' }}><span> {data.price}</span></div>
                                                        </div>

                                                    </div>


                                                ))}
                                            </Slider>

                                            <div >


                                                <img src="/images/assesment/Wath.jpg" style={{ width: '-webkit-fill-available', marginTop: '5px' }} alt="รูปภาพ1" />
                                            </div>

                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>


                            </div>
                        </div>
                    </div>
                </div>
                <LoadingOverlay active={loading2} spinner text="Loading" styles={{ wrapper: { width: '100%', minHeight: '100%' } }} />
            </div>

        </>
    );
};

export default Finishview;