import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import "./finish.scoped.scss";

import axios from "axios";
import { apiUrl, manual } from "../constants";
import Card from 'react-bootstrap/Card';
import { Col, Row } from 'antd';
import Spinner from '../components/Spinner'
import { AiFillHeart } from "react-icons/ai";
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useSwipeable } from 'react-swipeable';
import LoadingOverlay from 'react-loading-overlay';
import Slider from "react-slick";
import Assesment from "../assesment/assesment";
import { LineShareButton } from 'react-share';
import { AiOutlineShareAlt } from "react-icons/ai";
import { Avatar, Progress } from 'antd';

const Finish = (props) => {
    let [loading2, setLoading2] = useState(false);
    const history = useHistory();
    const [marginTop, setMarginTop] = useState(0);
    let User = localStorage.getItem("User");
    let assesment_id = localStorage.getItem("assesment_id")
    let roomidsestion = localStorage.getItem("roomid");
    const normalizedRoomid2 = roomidsestion.replace(/\"/g, "");
    const cover_user = User ? JSON.parse(User) : '';
    const Assesment_id = assesment_id ? assesment_id : '';
    const [listAssesment, setlistAssesment] = useState([
    ]);
    const [listsolutiont, setlistsolutiont] = useState([
    ]);

    const [showCardBody, setShowCardBody] = useState(Array(listAssesment.length).fill(false));
    const [content_suggestion, setcontent_suggestion] = useState(
    );
    const [title, settitle] = useState(
    );
    const [description, setdescription] = useState(
    );
    const avatarStyle = {
        marginRight: '8px', // Adjust the spacing as desired
        fontSize: '24px', // Increase the font size for a larger Avatar
    };
    const avatarStyle2 = {
        marginRight: '5px', // Adjust the spacing as desired
        // Increase the font size for a larger Avatar
    };
    const avatarStyle3 = {
        padding: '4px', // Adjust the spacing as desired
        marginRight: '6px'

        // Increase the font size for a larger Avatar
    };
    const avatarStyle4 = {
        padding: '4px', // Adjust the spacing as desired
        marginRight: '8px'

        // Increase the font size for a larger Avatar
    };
    const answer = 'คำตอบของคุณ';
    const liffURL = process.env.REACT_APP_LIFF_URL_VIEW;
    let decodedURL = '';

    if (cover_user.type === 'member') {
        const sharedURL = `${liffURL}?assessment_id=${Assesment_id}&user_id=${cover_user.customer?._id}&type=${cover_user.type}`;
        decodedURL = decodeURIComponent(sharedURL);
    }
    if (cover_user.type === 'abuddy') {
        const sharedURL = `${liffURL}?assessment_id=${Assesment_id}&user_id=${cover_user.abuddy?._id}&type=${cover_user.type}&roomId=${normalizedRoomid2}`;
        decodedURL = decodeURIComponent(sharedURL);
    }

    // กำหนดข้อความแชร์

    const shareText = 'แชร์คำตอบของฉันใน LINE';

    useEffect(() => {
        getStore();
    }, []);

    useEffect(() => {
        const handleResize = () => {
            const windowHeight = window.innerHeight;
            setMarginTop(-windowHeight);
        };

        // เมื่อหน้าเว็บโหลดเสร็จ และเมื่อเปลี่ยนขนาดหน้าต่างของเบราว์เซอร์
        window.addEventListener('DOMContentLoaded', handleResize);
        window.addEventListener('resize', handleResize);

        // Cleanup event listeners เมื่อ component unmounts
        return () => {
            window.removeEventListener('DOMContentLoaded', handleResize);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getStore = () => {
        let User = localStorage.getItem("assesment_id");
        const liffProfile = localStorage.getItem("Profile");
        setLoading2(true)
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_API_URL_UAT}/assessment/answer/result?assessment_id=` + User,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${cover_user.token}`,
                'x-api-key' :  liffProfile.userId
            }
        }).then((res) => {
            setlistAssesment(res.data.data = res.data.data.filter(obj => obj.type_score !== "beauty"))
            let User = localStorage.getItem("assesment_id");

            setLoading2(false)
            axios({
                method: "GET",
                url: `${process.env.REACT_APP_API_URL_UAT}/assessment/` + User,
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${cover_user.token}`,
                    'x-api-key' :  liffProfile.userId

                }
            })
                .then((res) => {
                    console.log(res.data.data.question);
                    setcontent_suggestion(res.data.data.content_suggestion)
                    settitle(res.data.data.title)
                    setdescription(res.data.data.description)
                    setLoading2(false)

                    axios({
                        method: "GET",
                        url: `${process.env.REACT_APP_API_URL_UAT}/assessment/result-solution/` + User,
                        headers: {
                            Accept: "application/json",
                            Authorization: `Bearer ${cover_user.token}`,
                            'x-api-key' :  liffProfile.userId

                        }
                    })
                        .then((res) => {
                            setlistsolutiont(res.data.data?.suggestions_solutions)
                        })
                        .catch((err) => {
                            setLoading2(false)
                        });
                })
                .catch((err) => {
                    setLoading2(false)
                });


        }).catch((err) => {
            setLoading2(false)
        });
    };
    const PageAssesment = () => {

        history.push("/assesment/pagequestion");
    }
    const PageAssesment2 = () => {


        localStorage.setItem("title", title);

        history.push("/assesment/abudyassesment");
    }
    const getProgressPercentage = (level) => {

        if (level === 'ไม่มี') {
            return 0;
        } else if (level === 'ต่ำ') {
            return 20;
        } else if (level === 'กลาง') {
            return 50;
        } else if (level === 'สูง') {
            return 100;
        } else if (level === 'มี') {
            return 100;
        }
    };



    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: false,
        appendDots: dots => (
            <div>
                <ul
                    style={{
                        position: "absolute",
                        bottom: "-50px",
                        width: "100%",
                        padding: "0",
                        margin: "0",
                        listStyle: "none",
                        textAlign: "center"
                    }}
                >
                    {dots.map((dot, index) => (
                        <li
                            key={index}
                            style={{
                                display: "inline-block",
                                margin: "0 4px",
                                width: "10px",
                                height: "10px",
                                borderRadius: "50%",
                                backgroundColor: dot.props.className === "slick-active" ? "#83c346" : "gray"
                            }}
                        />
                    ))}
                </ul>
            </div>
        ),
    };

    const [activeAccordion, setActiveAccordion] = useState(null);

    const handleClick = (index) => {
        const updatedShowCardBody = [...showCardBody];
        updatedShowCardBody[index] = !updatedShowCardBody[index];
        setShowCardBody(updatedShowCardBody);
    };

    const Clickbanner = (data) => {

        console.log(data);

        if (data.link) {

            window.location.href = data.link;
        }



    }

    const handlers = useSwipeable({
        // onSwipedLeft: () => {
        //    
        // },
        onSwipedRight: () => {
            if (cover_user.type == "member") {
                history.push("/assesment");
            } else if (cover_user.type == "abuddy") {
                let roomidsestion = localStorage.getItem("roomid");
                const normalizedRoomid = roomidsestion.replace(/\"/g, "");
                history.push("/assesment?roomId=" + normalizedRoomid);

            }


        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    return (
        <>

            <div className="page overflow_disable" style={{ minHeight: '130vh' }}>
                <div className="Success2" style={{ position: 'relative' }}>

                    <img src="/images/assesment/H1.png" alt="รูปภาพ" style={{ position: 'absolute', zIndex: '1', width: '30%', marginTop: '20px', right: '30px' }} />
                    <span style={{ fontSize: '22px', fontFamily: 'Sukhumvit Set Extra Bold', textAlign: 'center', marginTop: '10px' }}>Finish</span><br />
                    {/* <img src="/images/assesment/finich.png" alt="รูปภาพ" style={{ maxWidth: '100%', width: '70vh', height: '83vh', position: 'relative' }} /> */}

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                        <div style={{ position: 'absolute', top: '15px', left: '15px' }}>
                            <div
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    borderRadius: '50%',
                                    backgroundColor: '#FFFFFF',
                                    transition: 'margin-bottom 0.3s ease-in-out',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    paddingTop: '5px',
                                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                                }}
                            >
                                <LineShareButton url={decodedURL} title={answer}>
                                    <AiOutlineShareAlt style={{ fontSize: '30px' }} />
                                </LineShareButton>
                            </div>
                        </div>
                        <div style={{ position: 'absolute', top: '6px', right: '-20px', zIndex: '1' }}>
                            {cover_user.type == "member" ?
                                <div style={{ position: 'absolute', right: '20px', top: '10px' }}>
                                    <Avatar size={40} onClick={() => PageAssesment2()} src="/images/assesment/abuddy.jpg" style={avatarStyle} />
                                </div>
                                : null
                            }
                        </div>



                    </div>

                    <div {...handlers}>
                        <div >
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Card style={{
                                        borderRadius: "25px",
                                        minHeight: "20vh", background: "rgba(255, 255, 255, 18%)",
                                        width: '90vw', maxWidth: '400px',
                                        marginTop: '20px',
                                        zIndex: 1,
                                        borderColor: '#F7FAFF',
                                        transition: 'margin-bottom 0.3s ease-in-out',
                                        backdropFilter: 'blur(15px)',
                                        WebkitBackdropFilter: 'blur(15px)',
                                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                                    }}>
                                        <Card.Body>
                                            <div style={{ paddingRight: '60px' }}>
                                                <span style={{ marginTop: '10px', color: '#0F774A', fontFamily: 'Sukhumvit Set Extra Bold', fontSize: '19px' }}>{title} </span>
                                            </div>


                                            <p style={{ fontSize: '12px', fontFamily: 'Sukhumvit Set Extra Medium' }}>{description}</p>

                                        </Card.Body>
                                    </Card>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {window.screen.width > 390 ?
                                        <div style={{ marginBottom: activeAccordion ? '200px' : '0' }}>
                                            <Card style={{
                                                marginTop: '20px',
                                                borderRadius: "25px", minHeight: "20vh",
                                                background: "rgba(255, 255, 255, 0.18)",
                                                width: '90vw',
                                                maxWidth: '400px',
                                                zIndex: 1,
                                                borderColor: '#F7FAFF',
                                                transition: 'margin-bottom 0.3s ease-in-out',
                                                WebkitBackdropFilter: 'blur(15px)',
                                                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'

                                            }}>
                                                <Card.Body>
                                                    <span style={{ marginTop: '10px', color: '#0F774A', fontFamily: 'Sukhumvit Set Extra Bold', fontSize: '19px' }}>Result</span>

                                                    {listAssesment.map((data, index) => (
                                                        <Card
                                                            key={index}
                                                            style={{ borderRadius: '26px', marginBottom: '15px', marginTop: '15px', zIndex: 1, borderColor: '#F7FAFF', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}
                                                        >
                                                            <Card.Header
                                                                style={{ height: '80px', display: 'flex', alignItems: 'center' }}
                                                                onClick={() => handleClick(index)}
                                                            >
                                                                <Row style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                                                    <Col xs={{ span: 4, offset: 1 }} lg={{ span: 6, offset: 2 }} style={{ textAlign: 'end' }}>

                                                                        {data.type_score === 'cvd' && <Avatar size={40} src="/images/assesment/CVD.png" style={avatarStyle} />}
                                                                        {data.type_score === 'mobility' && <Avatar size={40} src="/images/assesment/Mobility.png" style={avatarStyle} />}
                                                                        {data.type_score === 'gut' && <Avatar size={40} src="/images/assesment/GUT.png" style={avatarStyle} />}
                                                                        {data.type_score === 'weight' && <Avatar size={40} src="/images/assesment/Weight.png" style={avatarStyle4} />}
                                                                        {data.type_score === 'beauty' && <Avatar size={40} src="/images/assesment/GUT.png" style={avatarStyle} />}
                                                                        <div style={{ position: 'absolute', top: '50%', left: '48%', transform: 'translate(-50%, -50%)' }}>
                                                                            <Progress
                                                                                type="circle"
                                                                                percent={getProgressPercentage(data.level)}
                                                                                width={70}
                                                                                strokeWidth={6}
                                                                                showInfo={false}
                                                                            />
                                                                        </div>

                                                                    </Col>
                                                                    <Col xs={{ span: 12, offset: 1 }} lg={{ span: 8, offset: 2 }} style={{ lineHeight: '16px' }}>
                                                                        <div style={{ fontSize: '18px', color: '#0f774a', display: 'flex', flexDirection: 'column' }}>
                                                                            <span style={{
                                                                                marginBottom: '5px',
                                                                                fontFamily: 'Sukhumvit Set Extra Bold',
                                                                                // textTransform: data.type_score === 'cvd' ? 'uppercase' : 'capitalize'
                                                                            }}>
                                                                                {data.type_score === 'cvd' && 'หัวใจและหลอดเลือด'}
                                                                                {data.type_score === 'mobility' && 'กระดูกและข้อต่อ'}
                                                                                {data.type_score === 'gut' && 'ระบบขับถ่าย'}
                                                                                {data.type_score === 'weight' && 'น้ำหนักตัว'}
                                                                                {data.type_score === 'beauty' && 'beauty'}
                                                                            </span>
                                                                        </div>
                                                                        <div style={{ fontSize: '17px', fontFamily: 'Sukhumvit Set Extra Bold', color: '#0f774a', lineHeight: '20px' }}>
                                                                            <span>ระดับความเสี่ยง :</span>
                                                                            <span>{data.level ? data.level : 'ไม่มี'}</span>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs={{ span: 4, offset: 1 }} lg={{ span: 6, offset: 2 }} style={{ textAlign: 'center' }}>
                                                                        {showCardBody[index] ? <UpOutlined /> : <DownOutlined />}
                                                                    </Col>
                                                                </Row>
                                                            </Card.Header>
                                                            {showCardBody[index] && (
                                                                <Card.Body className="cardbody">
                                                                    <div style={{ maxHeight: '200px', overflow: 'hidden' }}>
                                                                        {data.content_suggestion}
                                                                    </div>
                                                                </Card.Body>
                                                            )}
                                                        </Card>
                                                    ))}


                                                </Card.Body>
                                            </Card>
                                        </div>
                                        : <div style={{ marginBottom: activeAccordion ? '200px' : '0' }}>
                                            <Card style={{
                                                borderRadius: "25px", minHeight: "20vh",
                                                background: "rgba(255, 255, 255, 0.18)",
                                                width: '90vw',
                                                maxWidth: '400px',
                                                marginTop: '20px',
                                                zIndex: 1,
                                                borderColor: '#F7FAFF',
                                                transition: 'margin-bottom 0.3s ease-in-out',
                                                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'

                                            }}>
                                                <Card.Body>
                                                    <span style={{ marginTop: '10px', color: '#0F774A', fontWeight: 'bold', fontSize: '17px' }}>Result</span>

                                                    {listAssesment.map((data, index) => (
                                                        <Card
                                                            key={index}
                                                            style={{ borderRadius: '26px', marginBottom: '15px', marginTop: '15px', borderColor: '#F7FAFF', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}
                                                        >
                                                            <Card.Header
                                                                style={{ height: '80px', display: 'flex', alignItems: 'center' }}
                                                                onClick={() => handleClick(index)}
                                                            >
                                                                <Row style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                                                    <Col xs={{ span: 4, offset: 1 }} lg={{ span: 6, offset: 2 }} style={{ textAlign: 'end' }}>

                                                                        {data.type_score === 'cvd' && <Avatar size={40} src="/images/assesment/CVD.png" style={avatarStyle2} />}
                                                                        {data.type_score === 'mobility' && <Avatar size={40} src="/images/assesment/Mobility.png" style={avatarStyle2} />}
                                                                        {data.type_score === 'gut' && <Avatar size={40} src="/images/assesment/GUT.png" style={avatarStyle2} />}
                                                                        {data.type_score === 'weight' && <Avatar size={40} src="/images/assesment/Weight.png" style={avatarStyle3} />}
                                                                        {data.type_score === 'beauty' && <Avatar size={40} src="/images/assesment/GUT.png" style={avatarStyle2} />}
                                                                        <div style={{ position: 'absolute', top: '50%', left: '48%', transform: 'translate(-50%, -50%)' }}>
                                                                            <Progress
                                                                                type="circle"
                                                                                percent={getProgressPercentage(data.level)}
                                                                                width={60}
                                                                                strokeWidth={6}
                                                                                showInfo={false}
                                                                            />
                                                                        </div>

                                                                    </Col>
                                                                    <Col xs={{ span: 12, offset: 1 }} lg={{ span: 8, offset: 2 }} style={{ lineHeight: '20px' }}>
                                                                        <div style={{ fontSize: '16px', color: '#0f774a', display: 'flex', flexDirection: 'column' }}>
                                                                            <span style={{
                                                                                marginBottom: '5px',
                                                                                fontFamily: 'Sukhumvit Set Extra Bold',
                                                                                textTransform: data.type_score === 'cvd' ? 'uppercase' : 'capitalize'
                                                                            }}>
                                                                                {data.type_score === 'cvd' && 'หัวใจและหลอดเลือด'}
                                                                                {data.type_score === 'mobility' && 'กระดูกและข้อต่อ'}
                                                                                {data.type_score === 'gut' && 'ระบบขับถ่าย'}
                                                                                {data.type_score === 'weight' && 'น้ำหนักตัว'}
                                                                                {data.type_score === 'beauty' && 'beauty'}
                                                                            </span>
                                                                        </div>
                                                                        <div style={{ fontSize: '15px', fontFamily: 'Sukhumvit Set Extra Bold', color: '#0f774a' }}>
                                                                            <span>ระดับความเสี่ยง :</span>
                                                                            <span>{data.level ? data.level : 'ไม่มี'}</span>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs={{ span: 4, offset: 1 }} lg={{ span: 6, offset: 2 }} style={{ textAlign: 'center' }}>
                                                                        {showCardBody[index] ? <UpOutlined /> : <DownOutlined />}
                                                                    </Col>
                                                                </Row>
                                                            </Card.Header>
                                                            {showCardBody[index] && (
                                                                <Card.Body className="cardbody">
                                                                    <div style={{ maxHeight: '200px', overflow: 'hidden' }}>
                                                                        {data.content_suggestion}
                                                                    </div>
                                                                </Card.Body>
                                                            )}
                                                        </Card>
                                                    ))}


                                                </Card.Body>
                                            </Card>
                                        </div>
                                    }


                                </div>
                            </div>



                        </div>


                    </div>


                    {window.screen.width > 700 ?
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                <Card style={{ borderRadius: "25px", background: "rgba((255 255 255 / 61%)", width: '90vw', maxWidth: '400px', zIndex: 1, borderColor: '#F7FAFF', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', marginTop: '20px' }}>
                                    <Card.Body>
                                        <span style={{ marginTop: '10px', color: '#0F774A', fontFamily: 'Sukhumvit Set Extra Bold', fontSize: '19px' }}>Suggest</span>
                                        <div
                                            style={{
                                                fontSize: '13px',
                                                fontFamily: 'Sukhumvit Set Extra Medium',
                                                marginTop: '13px'
                                            }}
                                            dangerouslySetInnerHTML={{ __html: content_suggestion }}
                                        />
                                    </Card.Body>

                                </Card>



                            </div>

                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>

                                <Card style={{ borderRadius: "25px", minHeight: "20vh", background: "rgba(255 255 255)", maxWidth: '400px', zIndex: 1, borderColor: '#F7FAFF', overflowX: 'hidden' }}>
                                    <Card.Body>
                                        <span style={{ marginTop: '10px', fontFamily: 'Sukhumvit Set Extra Bold', fontSize: '20px' }}>Solution Suggest </span>
                                        <Slider {...settings}  >
                                            {listsolutiont?.map((data, key) => (
                                                <div key={key} className="card2" style={{ width: '200px' }}>

                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <span style={{ marginRight: '5px', fontFamily: 'Sukhumvit Set Extra Bold', color: '#707070' }}>{data.number}</span>
                                                        <AiFillHeart style={{ color: '#83C346' }} />
                                                    </div>


                                                    <div className="card-image" onClick={() => Clickbanner(data)}>
                                                        <img src={data.url} alt="Product" />
                                                    </div>
                                                    <div className="card-content">
                                                        <div style={{ textAlign: 'initial' }}><span>{data.content}</span></div>
                                                        <div style={{ textAlign: 'initial', marginTop: '10px', fontFamily: 'Sukhumvit Set Extra Bold', fontSize: '16px' }}><span> {data.price}</span></div>
                                                    </div>

                                                </div>


                                            ))}
                                        </Slider>

                                        <div >


                                            <img src="/images/assesment/Wath.jpg" style={{ width: '-webkit-fill-available', marginTop: '5px' }} alt="รูปภาพ1" />
                                        </div>

                                    </Card.Body>
                                </Card>

                            </div>

                        </div> : null

                    }
                </div>
                <div>
                    <div>
                        <div>
                            <div>
                                {window.screen.width < 700 ?

                                    <div >
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            {window.screen.width > 400 ?
                                                <Card style={{ borderRadius: "25px", background: "rgba(255, 255, 255, 18%)", width: '90vw', maxWidth: '400px', zIndex: 1, borderColor: '#F7FAFF', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', marginTop: '20px' }}>
                                                    <Card.Body>
                                                        <span style={{ marginTop: '10px', color: '#0F774A', fontFamily: 'Sukhumvit Set Extra Bold', fontSize: '19px' }}>Suggest</span>
                                                        <div
                                                            style={{
                                                                fontSize: '13px',
                                                                fontFamily: 'Sukhumvit Set Extra Medium',
                                                                marginTop: '13px'
                                                            }}
                                                            dangerouslySetInnerHTML={{ __html: content_suggestion }}
                                                        />
                                                    </Card.Body>
                                                </Card> : <Card style={{ borderRadius: "25px", minHeight: "20vh", background: "rgba(255, 255, 255, 18%)", width: '90vw', maxWidth: '400px', zIndex: 1, borderColor: '#F7FAFF', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', marginTop: '20px' }}>
                                                    <Card.Body>
                                                        <span style={{ marginTop: '10px', color: '#0F774A', fontFamily: 'Sukhumvit Set Extra Bold', fontSize: '17px' }}>Suggest</span>
                                                        <div
                                                            style={{
                                                                fontSize: '13px',
                                                                fontFamily: 'Sukhumvit Set Extra Medium',
                                                                marginTop: '13px'
                                                            }}
                                                            dangerouslySetInnerHTML={{ __html: content_suggestion }}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            }


                                        </div>
                                        <Card style={{ borderRadius: "25px", minHeight: "20vh", background: "rgba(255, 255, 255, 18%)", maxWidth: '400px', zIndex: 1, borderColor: '#F7FAFF', overflowX: 'hidden' }}>
                                            <Card.Body>
                                                <span style={{ marginTop: '10px', fontFamily: 'Sukhumvit Set Extra Bold', fontSize: '20px' }}>Solution Suggest </span>
                                                <Slider {...settings}  >
                                                    {listsolutiont?.map((data, key) => (
                                                        <div key={key} className="card2" style={{ width: '200px' }}>

                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <span style={{ marginRight: '5px', fontFamily: 'Sukhumvit Set Extra Bold', color: '#707070' }}>{data.number}</span>
                                                                <AiFillHeart style={{ color: '#83C346' }} />
                                                            </div>


                                                            <div className="card-image" onClick={() => Clickbanner(data)}>
                                                                <img src={data.url} alt="Product" />
                                                            </div>
                                                            <div className="card-content">
                                                                <div style={{ textAlign: 'initial' }}><span>{data.content}</span></div>
                                                                <div style={{ textAlign: 'initial', marginTop: '10px', fontFamily: 'Sukhumvit Set Extra Bold', fontSize: '16px' }}><span> {data.price}</span></div>
                                                            </div>

                                                        </div>


                                                    ))}
                                                </Slider>

                                                <div >


                                                    <img src="/images/assesment/Wath.jpg" style={{ width: '-webkit-fill-available', marginTop: '5px' }} alt="รูปภาพ1" />
                                                </div>

                                            </Card.Body>
                                        </Card>
                                    </div> :
                                    null}
                            </div>



                            <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                                <Row>
                                    <button style={{
                                        borderRadius: '36px',
                                        backgroundColor: 'rgb(15, 119, 74)',
                                        width: '40vh',
                                        marginTop: '2vh',
                                        marginBottom: '5vh',
                                        height: '6vh',
                                        fontFamily: 'Sukhumvit Set tadmai bold',
                                        borderColor: 'rgb(15, 119, 74)',
                                        fontSize: '18px'
                                    }} type="button" className="btn btn-primary btn-lg btn-block button" onClick={() => PageAssesment()}>Start Over</button>
                                </Row>

                            </div>

                            <div>
                                <ul
                                    style={{
                                        position: "absolute",
                                        bottom: "-110px",
                                        width: "100%",
                                        padding: "0",
                                        margin: "0",
                                        listStyle: "none",
                                        textAlign: "center"
                                    }}
                                >
                                    <li

                                        style={{
                                            display: "inline-block",
                                            margin: "0 4px",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            backgroundColor: "gray"
                                        }}
                                    />
                                    <li

                                        style={{
                                            display: "inline-block",
                                            margin: "0 4px",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            backgroundColor: "gray"
                                        }}
                                    />

                                    <li

                                        style={{
                                            display: "inline-block",
                                            margin: "0 4px",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            backgroundColor: "gray"
                                        }}
                                    />
                                    <li

                                        style={{
                                            display: "inline-block",
                                            margin: "0 4px",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            backgroundColor: "#83c346"
                                        }}
                                    />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <LoadingOverlay active={loading2} spinner text="Loading" styles={{ wrapper: { width: '100%', minHeight: '100%' } }} />
            </div >

        </>
    );
};

export default Finish;