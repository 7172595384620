import React from 'react'
import './foodCalories.scss'
const FoodCalories = ({ routeTo }) => {

    return (
        <div onClick={() => routeTo('pathFoodCalories')} className="find-nutritional-section">
            <img src="/images/healthAndWellness/food-calorie-card-image.svg" className="search-img" />
        </div>
    )
}

export default FoodCalories