import React, { useEffect, useState, useRef } from 'react';
import './FoodLogHome.scss';
import {
  FoodLogDrinks,
  FoodLogHeader,
  FoodLogMeal,
  FoodLogOverview,
} from './components';
import HnWSlideup from '../../../common/slideup/HnWSlideup';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFoodLogDetails,
  getHealthLogs,
  getWaterVolume,
} from '../../../thunk/foodLog.thunk';
import {
  getDateForAPI,
  getDataFromSession,
  isEmptyObject,
  isValidObject,
  calculateDefaultFoodGoal,
  calculateDefaultWaterGoal,
  getDatetimeStamp,
} from '../../../utill.func';
import { getHnwAllHealhGoalList } from '../../../actions/hnwGoalSettingActions';
import {
  HNW_GOAL_SETTING,
  H_W_VALIDATION,
  HEALTH_GOAL_CARD_CONTENT,
  HEALTH_GOAL_TYPES,
  HNW_FOOD_LOG,
} from '../../../common/commonConstant';
import {
  CalendarLoader,
  HnwHeightWeightInput,
  HnwInputModal,
} from '../../../common';
import { fetchBmiData, updateBmiData } from '../../../actions';
import HnwGoalCard from '../hnwGoals/components/HnwGoalCard/HnwGoalCard';
import { FOODTYPES } from '../../../constants';

export default function FoodLogHome({ routeTo }) {
  const dispatch = useDispatch();
  const bmiData = useSelector((state) => state.bmi);
  const { foodLogData, volumeData, foodChartData } = useSelector(
    (state) => state?.logList,
  );
  const { healthGoalList } = useSelector((state) => state?.goalSetting);
  const { _id: customerId } = getDataFromSession(
    HNW_GOAL_SETTING.CUSTOMER_SESSION_KEY,
  );

  const date = new Date();
  const startDate = getDateForAPI(date);
  const endDate = getDateForAPI(date);

  const [loading, setLoading] = useState(false);
  const [bmiUpdated, setBmiUpdated] = useState(false);
  const [healthGoalUpdated, setHealthGoalUpdated] = useState(false);
  const [foodGoalData, setFoodGoalData] = useState(null);
  const [waterGoalData, setWaterGoalData] = useState(null);
  const [supplementGoalData, setSupplementGoalData] = useState(null);
  const [isFoodGoalCalculated, setIsFoodGoalCalculated] = useState(false);
  const [isWatergoalCalculated, setIsWaterGoalCalculated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showNumpad, setShowNumpad] = useState(false);
  const [currentInputType, setCurrentInputType] = useState(null);
  const [userDetails, setUserDetails] = useState({
    weight: 0,
    height: 0,
  });

  const waterLogRef = useRef(null);

  const fetchBMIData = () => {
    if (!bmiData?.bmi) {
      setLoading(true);
      dispatch(fetchBmiData())
        .then(() => {
          setBmiUpdated(true);
        }).catch((err) => {
          setLoading(false);
          throw err;
        });
    } else {
      setBmiUpdated(true);
    }
  };

  const fetchHealthGoals = (customerId) => {
    if (
      !healthGoalList?.waterGoal ||
      !healthGoalList?.foodGoal ||
      !healthGoalList?.activityGoal
    ) {
      setLoading(true);
      dispatch(getHnwAllHealhGoalList(customerId))
        .then(() => {
          setHealthGoalUpdated(true);
        })
        .catch((err) => {
          setLoading(false);
          throw err;
        });
    } else {
      setHealthGoalUpdated(true);
    }
  };

  const fetchLogData = async () => {
    setLoading(true);
    const healthLogsPromises = [
      dispatch(
        getHealthLogs({
          startDate: getDatetimeStamp(startDate),
          endDate: getDatetimeStamp(endDate),
        }),
      ),
    ];
    let customerDataPromises = [];
    customerDataPromises = customerId
      ? [
        dispatch(
          getFoodLogDetails(customerId, {
            startDate: getDatetimeStamp(startDate),
            endDate: getDatetimeStamp(endDate),
          }),
        ),
        !isValidObject(volumeData) ? dispatch(getWaterVolume(customerId)) : null,
      ]
      : [];
    try {
      if (customerDataPromises.length) {
        await Promise.allSettled([
          ...healthLogsPromises,
          ...customerDataPromises,
        ]);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBMIData();
    fetchHealthGoals(customerId);
  }, []);

  useEffect(() => {
    if (
      bmiData.height &&
      bmiData.weight &&
      (!healthGoalList?.foodGoal || isEmptyObject(healthGoalList?.foodGoal))
    ) {
      const defaultFoodGoal = calculateDefaultFoodGoal(bmiData);
      setFoodGoalData(defaultFoodGoal);
      setIsFoodGoalCalculated(true);
    }
    if (
      bmiData.height &&
      bmiData.weight &&
      (!healthGoalList?.waterGoal || isEmptyObject(healthGoalList?.waterGoal))
    ) {
      const defaultWaterGoal = calculateDefaultWaterGoal(bmiData);
      setWaterGoalData(defaultWaterGoal);
      setIsWaterGoalCalculated(true);
    }
  }, [bmiData.height, bmiData.weight]);

  useEffect(() => {
    if (isValidObject(healthGoalList)) {
      const { foodGoal, waterGoal, supplementGoal } = healthGoalList;
      if (isValidObject(foodGoal)) {
        setFoodGoalData(healthGoalList?.foodGoal);
        setIsFoodGoalCalculated(false);
      }
      if (isValidObject(waterGoal)) {
        setWaterGoalData(healthGoalList?.waterGoal?.targetWater);
        setIsWaterGoalCalculated(false);
      }
      if (isValidObject(supplementGoal)) {
        setSupplementGoalData(healthGoalList?.supplementGoal);
      }
    }
  }, [healthGoalList]);

  useEffect(() => {
    if (bmiUpdated && healthGoalUpdated) {
      const { weight, height } = bmiData;

      if (weight || height) {
        setUserDetails((prevData) => ({
          ...prevData,
          ...(weight && { weight }),
          ...(height && { height }),
        }));
      }
      setCurrentInputType(weight && !height ? 'height' : 'weight');
      if (
        (!weight || !height) &&
        (!isValidObject(healthGoalList) ||
          !isValidObject(healthGoalList.foodGoal) ||
          !isValidObject(healthGoalList.waterGoal))
      ) {
        setShowNumpad(true);
        setShowModal(true);
      }
    }
  }, [bmiUpdated, healthGoalUpdated]);

  useEffect(() => {
    fetchLogData();
  }, [startDate, endDate, customerId]);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getHealthLogs({
        startDate: getDatetimeStamp(startDate),
        endDate: getDatetimeStamp(endDate),
      }),
    ).finally(() => setLoading(false));
  }, [volumeData]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const shouldScrollToWaterLog = params.get('scrollTo') === 'waterLog';

    if (shouldScrollToWaterLog) {
      setTimeout(() => {
        waterLogRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }, 1000);
    }
  }, []);

  const handleInputBoxClick = (name) => {
    setCurrentInputType(name);
    if (!showNumpad) {
      setShowNumpad(true);
    }
  };

  const handleNumpadValueChange = (val, type) => {
    const regex =
      currentInputType === 'weight'
        ? H_W_VALIDATION.weight.pattern
        : H_W_VALIDATION.height.pattern;
    const newValue =
      type !== 'delete' ? (userDetails[currentInputType] || '') + val : val;
    if (regex.test(newValue)) {
      setUserDetails((prevData) => ({
        ...prevData,
        [currentInputType]: newValue,
      }));
    }
  };

  const handleNumpadDelete = () => {
    const newValue = userDetails[currentInputType].toString().slice(0, -1);
    handleNumpadValueChange(newValue, 'delete');
  };

  const handleNumpadSubmit = () => {
    const { weight, height } = userDetails;
    if (weight && height) {
      const payload = Object.assign({}, userDetails, {
        height: Number(userDetails.height),
        weight: Number(userDetails.weight),
      });
      setLoading(true);
      setShowModal(false);
      dispatch(updateBmiData(payload)).finally(() => setLoading(false));
    }
    setShowNumpad(false);
    setCurrentInputType(null);
  };

  return (
    <div className="food-overview-page">
      <FoodLogHeader routeTo={routeTo} />
      <div className="food-overview-banner-container">
        <FoodLogOverview
          foodChartData={foodChartData}
          goalData={foodGoalData}
        />
      </div>
      <FoodLogMeal
        foodLogData={foodLogData}
        foodGoalData={foodGoalData}
        isFoodGoalCalculated={isFoodGoalCalculated}
        routeTo={routeTo}
      />
      <FoodLogDrinks
        ref={waterLogRef}
        customerId={customerId}
        healthData={foodChartData}
        waterGoalData={waterGoalData}
        isWatergoalCalculated={isWatergoalCalculated}
        volumeData={volumeData}
        setLoading={setLoading}
      />
      {supplementGoalData && (
        <div className="food-log-supplement-goal-container">
          <div className="food-log-supplement-goal-container__heading">
            {HNW_FOOD_LOG.labelMapping.supplementGoalCardHeader}
          </div>
          <HnwGoalCard
            content={HEALTH_GOAL_CARD_CONTENT[HEALTH_GOAL_TYPES.SUPPLEMENT]}
            healthGoal={supplementGoalData}
            routeTo={routeTo}
            type={HEALTH_GOAL_TYPES.SUPPLEMENT}
            showDeleteModal={false}
            routeParams={`referrerFrom=${HNW_FOOD_LOG.routeMapping.referrer}`}
          />
        </div>
      )}
      <HnWSlideup />
      <CalendarLoader showLoading={loading} />
      <HnwInputModal
        isOpen={showModal}
        onBackgroundClick={handleNumpadSubmit}
        onNumpadValueChange={handleNumpadValueChange}
        onNumpadDelete={handleNumpadDelete}
        showNumpad={showNumpad}
        onNumpadSubmit={handleNumpadSubmit}
      >
        <HnwHeightWeightInput
          userHeight={userDetails.height}
          userWeight={userDetails.weight}
          handleInputBoxClick={handleInputBoxClick}
        />
      </HnwInputModal>
    </div>
  );
}
