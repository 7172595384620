import React, { Fragment, useState, useRef, useEffect } from 'react';
import Measure from 'react-measure';
import { useCardRatio } from './hooks/use-card-ratio';
import { useOffsets } from './hooks/use-offsets';
import {
  Video,
  Canvas,
  Wrapper,
  Container,
  Root,
  GlobalStyle,
} from './styles';
import { useDispatch } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { uploadFoodImage } from '../../../../../actions';
import { commonConstant } from '../../../../../common';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import './AccessCamera.scss';

const USER_CAMERA = 'user';
const ENVIRONMENT_CAMERA = 'environment';

export default function AccessCamera() {
  const canvasRef = useRef(null);
  const videoRef = useRef(null);
  const uploadRef = useRef(null);

  const dispatch = useDispatch();

  const [container, setContainer] = useState({ width: 0, height: 0 });
  const [mediaStream, setMediaStream] = useState(null);
  const [cameraFacing, setCameraFacing] = useState(ENVIRONMENT_CAMERA);
  const [loading, setLoading] = useState(false);

  const [aspectRatio, calculateRatio] = useCardRatio(1.586);
  const offsets = useOffsets(
    videoRef.current && videoRef.current.videoWidth,
    videoRef.current && videoRef.current.videoHeight,
    container.width,
    container.height,
  );

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: {
          facingMode: cameraFacing,
        },
      })
      .then((stream) => {
        videoRef.current.srcObject = stream;
        videoRef.current.onloadedmetadata = (e) => {
          calculateRatio(
            videoRef.current.videoHeight,
            videoRef.current.videoWidth,
          );
          videoRef.current.play();
          setMediaStream(stream);
        };
      })
      .catch((error) => {
        console.error('camera API is not supported by your device');
        throw new Error(error);
      });
  }, [cameraFacing]);

  function handeFlipCamera() {
    new Promise((resolve, reject) => {
      try {
        mediaStream.getTracks().forEach((track) => track.stop());
        resolve();
      } catch (e) {
        reject(e);
      }
    }).then(() => {
      setCameraFacing(
        cameraFacing === USER_CAMERA ? ENVIRONMENT_CAMERA : USER_CAMERA,
      );
    });
  }

  function handleResize(contentRect) {
    setContainer({
      width: contentRect.bounds.width,
      height: Math.round(contentRect.bounds.width / aspectRatio),
    });
  }

  function handleCapture() {
    canvasRef.current
      .getContext('2d')
      .drawImage(
        videoRef.current,
        offsets.x,
        offsets.y,
        container.width,
        container.height,
        0,
        0,
        container.width,
        container.height,
      );

    canvasRef.current.toBlob(
      (blob) => uploadRef.current.upload.uploader.props.beforeUpload(blob),
      'image/jpeg',
      1,
    );
  }

  function handleCanPlay() {
    calculateRatio(videoRef.current.videoHeight, videoRef.current.videoWidth);
    videoRef.current.play();
  }

  async function onModalOk(file) {
    setLoading(true);
    if (file) {
      const formDataImage = new FormData();
      formDataImage.append('imageFile', file, "file.jpeg");
      const imageResponse = await dispatch(uploadFoodImage(formDataImage));
      const imagePath = imageResponse?.data?.data || '';
      setLoading(false);
      window.location.href = `https://${window.location.hostname}${commonConstant.pathAddMenu}?imagePath=${imagePath}`;
    }
  }

  return (
    <Fragment>
      <Root>
        <Measure bounds onResize={handleResize}>
          {({ measureRef }) => (
            <Wrapper>
              {loading ? (
                <LoadingOverlay
                  text="Loading"
                  active={loading}
                  spinner={true}
                />
              ) : null}
              <Container
                ref={measureRef}
                maxHeight={videoRef.current && videoRef.current.videoHeight}
                maxWidth={videoRef.current && videoRef.current.videoWidth}
                style={{
                  height: `${container.height}px`,
                }}
              >
                <Video
                  ref={videoRef}
                  onCanPlay={handleCanPlay}
                  autoPlay
                  playsInline
                  muted
                  poster="placeholder.png"
                  style={{
                    top: `-${offsets.y}px`,
                    left: `-${offsets.x}px`,
                  }}
                />
                <Canvas
                  ref={canvasRef}
                  width={container.width}
                  height={container.height}
                />
              </Container>
              <div className="custom-android-camera-container">
                <span></span>
                <img
                  src="/images/healthAndWellness/foodLog/camera-click.svg"
                  alt="camera-click.svg"
                  onClick={handleCapture}
                />
                <img
                  src="/images/healthAndWellness/foodLog/change-camera.svg"
                  alt="change-camera.svg"
                  onClick={handeFlipCamera}
                />
              </div>
              <ImgCrop onModalOk={onModalOk} quality={1}>
                <Upload listType="picture-card" ref={uploadRef} />
              </ImgCrop>
            </Wrapper>
          )}
        </Measure>
      </Root>
      <GlobalStyle />
    </Fragment>
  );
}
