export const PROFILEMENU = [
    {
        unit: "BMI",
        key: "bmi",
        value: "",
        placeholder: "เลือกวิธีชำระเงิน",
        image: "bmi.svg"
    },
    {
        unit: "กิโลกรัม",
        name: "น้ำหนัก",
        key: "weight",
        value: "-",
        image: "weight.svg"
    },
    {
        unit: "เซนติเมตร",
        name: "ส่วนสูง",
        key: "height",
        value: "-",
        image: "height.svg"
    },
    {
        unit: "นิ้ว",
        name: "เส้นรอบเอว",
        key: "waist",
        value: "-",
        image: "waist.svg"
        },
        {
            unit: "%",
            name: "ไขมัน",
            key: "fat",
            value: "-",
            image: "fat.svg"
        },
        {
            unit: "กิโลกรัม",
            name: "มวลกล้ามเนื้อ",
            key: "muscle",
            value: "-",
            image: "muscle.svg"
    }
]

export const ANSWER_PARAMETERS_LIST = [
    { api_key: 'HealthInfo', type: 'health' },
    { api_key: 'Activities', type: 'activity' },
    { api_key: 'Attitude', type: 'attitude' },
    { api_key: 'FoodHabits', type: 'food' },
    { api_key: 'Sleep', type: 'sleep' },
    { api_key: 'Stress', type: 'stress' },
];