import React from 'react';
import './HnwInputBox.scss';
import { INPUT_PLACEHOLDER } from '../commonConstant';

const HnwInputBox = ({
  name,
  label,
  iconImg,
  value,
  onClick,
  placeholder = INPUT_PLACEHOLDER,
  hasError,
}) => {
  return (
    <div className="hnw-input-box-wrapper">
      <div className="hnw-input-box-wrapper__group">
        {iconImg && (
          <img
            src={iconImg}
            className="hnw-input-box-wrapper__group-icon"
            alt="icon"
          />
        )}
        <div className="hnw-input-box-wrapper__group-label">{label}</div>
      </div>
      <div
        className={`hnw-input-box-wrapper__value${
          value && !hasError ? '' : ' --no-value'
        }`}
        onClick={() => onClick(name)}
      >
        {value || placeholder}
      </div>
    </div>
  );
};

export default HnwInputBox;
