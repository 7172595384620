import React, { useEffect, useState } from 'react';
import './hnwHome.scss';
import Profile from './hnwHomeProfile/hnwHomeProfile';
import Header from '../../../common/header/header';
import {
  CalendarLoader,
  commonConstant,
  HealthHomeCard,
  HnwSuccessOverlayModal,
} from '../../../common';
import Assessment from './assessment/assessment';
import FoodCalories from './foodCalories/foodCalories';
import {
  capitalizeFirstLetter,
  checkIsIOS,
  getDatetimeStamp,
} from '../../../utill.func';
import {
  API_DATE_FORMAT,
  BGIMG,
  FOOD_PARAMETERS_MAPPING,
  homePageSlideUpGap,
  LABEL_CALORIE,
  LABEL_ML,
  policyTitle,
  routePathKey,
  SEARCH_BAR_PLACEHOLDER,
} from '../../../common/commonConstant';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPolicies, fetchPolicyStatus, updateProfileImage } from '../../../thunk/hnwHome.thunk';
import { fetchExerciseCalendarData } from '../../../actions/hnwCalendarActions';
import { format } from 'date-fns';
import { calculateGroupedFoodWaterLogData } from '../hnwFoodLog/components/utility';
import { getHnwAllHealhGoalList } from '../../../actions/hnwGoalSettingActions';
import ChallengeHome from '../../challenge/challengeHome/ChallengeHome';
import { fetchBmiData } from '../../../actions';
import { getHealthLogs } from '../../../thunk/foodLog.thunk';
import { setChallengeType } from '../../../actions/hnwFooterAction';
import { SET_FOOTER_STATE_HOME } from '../../../actions/types';
import HnwFooter from '../../../common/hnwFooter/HnwFooter';
import CustomModal from '../../../common/customModal/CustomModal';
import FloatingWidget from '../../../common/challenge/floatingWidget/FloatingWidget';
import {
  getBodyKeyRecordAction,
  verifyBodyKeyIdAction,
} from '../../../actions/challenge/challengeBodyKeyActions';
import {
  AS_BODY_KEY_VERIFICATION_STEP,
  AS_OTP_VERIFICATION_STEP,
  BODY_KEY_APP_LINK,
  BODY_KEY_APPSTORE,
  BODY_KEY_LOADER_TITLE,
  BODY_KEY_PLAYSTORE,
  BODY_KEY_UNIVERSAL_LINK,
} from '../../../common/challengeConstants';
import moment from 'moment';
import HnWSlideup from '../../../common/slideup/HnWSlideup';
import AutoSyncVerification from '../../../common/challenge/autoSyncVerification/AutoSyncVerification';
import syncAnimation from '../../../json/sync-animation.json';

const Home = ({ routeTo }) => {
  const DefaultTotalValues = {
    TotalCarbs: 0,
    TotalProtein: 0,
    TotalFat: 0,
    TotalWater: 0,
    TotalCalories: 0,
    TotalExercise: 0,
  };
  const DefaultGoalValues = {
    foodGoal: null,
    waterGoal: null,
    activityGoal: null,
  };

  const dispatch = useDispatch();
  const bmiData = useSelector((state) => state.bmi);
  const { policyStatus, policies } = useSelector((state) => state.home);
  const { isSuccess, loading: bodyKeyLoader } = useSelector(
    (state) => state.challengeBodyKey,
  );

  const [loading, setLoading] = useState(true);
  const customerData = useSelector((state) => state.customer);
  const profileDetails = useSelector((state) => state.profile);
  const [bgImg, setBgImg] = useState(BGIMG.default);
  const [logData, setLogData] = useState(null);
  const foodWaterData = useSelector((state) => state?.logList?.foodChartData);
  const [triggerAutoSync, setTriggerAutoSync] = useState(false);
  const [bodyKeyId, setBodyKeyId] = useState('');
  const [validationFailed, setValidationFailed] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  // AS_OTP_VERIFICATION_STEP
  const [autoSyncStep, setAutoSyncStep] = useState(
    AS_BODY_KEY_VERIFICATION_STEP,
  );
  const exerciseData = useSelector((state) => state.hnwCalendar?.exercise);
  const healthGoal =
    useSelector((state) => state.goalSetting.healthGoalList) ??
    DefaultGoalValues;
  const foodExerciseData = [...foodWaterData, ...exerciseData];
  const {
    TotalCarbs,
    TotalProtein,
    TotalFat,
    TotalWater,
    TotalCalories,
    TotalExercise,
  } = foodExerciseData.reduce(
    calculateGroupedFoodWaterLogData,
    DefaultTotalValues,
  );
  const healthObj = {
    TotalCarbs,
    TotalProtein,
    TotalFat,
    TotalWater,
    TotalCalories,
    TotalExercise,
  };

  useEffect(() => {
    if (customerData?.amwayNumber) {
      const CURRENT_DATE = new Date();
      if (customerData._id && (!healthGoal.waterGoal || !healthGoal.foodGoal || !healthGoal.activityGoal)) {
        dispatch(getHnwAllHealhGoalList(customerData._id));
      }

      dispatch(
        getHealthLogs({
          startDate: getDatetimeStamp(format(CURRENT_DATE, API_DATE_FORMAT)),
          endDate: getDatetimeStamp(format(CURRENT_DATE, API_DATE_FORMAT)),
        }),
      );

      dispatch(
        fetchExerciseCalendarData({
          startDate: getDatetimeStamp(format(CURRENT_DATE, API_DATE_FORMAT)),
          endDate: getDatetimeStamp(format(CURRENT_DATE, API_DATE_FORMAT)),
        }),
      );
      checkForTnC();
    }
  }, [customerData, customerData.amwayNumber]);

  useEffect(() => {
    dispatch(setChallengeType(SET_FOOTER_STATE_HOME));
  }, []);

  useEffect(() => {
    if (customerData?.pictureUrl &&
      profileDetails?.pictureUrl &&
      customerData.pictureUrl !== profileDetails.pictureUrl) {
      dispatch(updateProfileImage({
        pictureUrl: profileDetails?.pictureUrl
      }))
    }
  },[customerData, profileDetails])

  const checkForTnC = async () => {
    try {
      setBgImage();
      if (!customerData.isTncAck) {
        routeTo('pathTermsConditions', '?path=pathHealthnWellness');
      } else {
        fetchData();
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (customerData?.bodyKeyId) {
      loadBodyKeyRecords(customerData?.bodyKeyId);
    }
  }, [customerData?.bodyKeyId]);

  const loadBodyKeyRecords = async (bkId) => {
    try {
      await dispatch(
        getBodyKeyRecordAction({
          startDate: moment().subtract(1, 'day').format('yyyy-MM-DD'),
          endDate: moment().format('yyyy-MM-DD'),
          bodyKeyId: bkId,
        }),
      );
    } catch (error) {
      console.error('Body Key Recod failed', error);
    }
  };

  const setBgImage = () => {
    if (customerData && customerData.gender) {
      if (customerData.gender.toLowerCase() === commonConstant.MALE) {
        setBgImg(BGIMG.male);
      } else if (customerData.gender.toLowerCase() === commonConstant.FEMALE) {
        setBgImg(BGIMG.female);
      }
    }
  };

  const fetchBMIDataOnCondition = (status = false) => {
    if (status) {
      if (!bmiData?.bmi) {
        setLoading(true);
        dispatch(fetchBmiData()).finally(() => setLoading(false));
      } else {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!policyStatus && policies.length) {
      dispatch(
        fetchPolicyStatus(
          policies.find(
            (el) => el.title.toLowerCase() === policyTitle.home_policy,
          ),
        ) ?? null,
      ).finally(() => setLoading(false));
    }
  }, [policies.length]);

  useEffect(() => {
    fetchBMIDataOnCondition(policyStatus);
  }, [policyStatus]);

  const fetchData = async () => {
    try {
      if (!policyStatus) {
        if (!customerData.hasOwnProperty('isPolicyAccepted')) {
          dispatch(fetchPolicies());
        } else {
          fetchBMIDataOnCondition(true);
        }
      } else {
        setLoading(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const checkRoute = async (pathKey, params = '') => {
    if (policyStatus && commonConstant[pathKey]) {
      routeTo(pathKey, params);
    } else {
      routeTo(routePathKey.policy);
    }
  };

  const handleSearchClick = () => {
    routeTo(routePathKey.searchHistory, '?openSearch=true');
  };

  const healthParamsValues = FOOD_PARAMETERS_MAPPING.reduce(
    (acc, item) => ({
      ...acc,
      [item.name]: {
        ...item,
        consumed: healthObj[`Total${capitalizeFirstLetter(item.name)}`],
        total:
          healthGoal.foodGoal &&
          healthGoal.foodGoal[`target${capitalizeFirstLetter(item.name)}`],
      },
    }),
    {},
  );

  useEffect(() => {
    const logData = {
      foodCard: {
        isEmpty: !healthGoal.foodGoal,
        onClick: () =>
          checkRoute(
            healthGoal.foodGoal ? 'pathFoodLogHome' : 'pathHnwGoalsHome',
          ),
        healthParamsValues,
        calorie: {
          total: healthGoal?.foodGoal?.targetCalories,
          consumed: TotalCalories,
          unit: LABEL_CALORIE,
        },
      },
      waterCard: {
        isEmpty: !healthGoal.waterGoal,
        onClick: () =>
          checkRoute(
            healthGoal.waterGoal ? 'pathFoodLogHome' : 'pathHnwGoalsHome',
            '?scrollTo=waterLog',
          ),
        waterLog: {
          total: healthGoal?.waterGoal?.targetWater,
          consumed: TotalWater,
          unit: LABEL_ML,
        },
      },
      excerciseCard: {
        isEmpty: !healthGoal.activityGoal,
        onClick: () =>
          checkRoute(
            healthGoal.activityGoal
              ? 'pathHnwExerciseLogHome'
              : 'pathHnwGoalsHome',
          ),
        exerciseLog: {
          total: healthGoal?.activityGoal?.targetCalories,
          consumed: TotalExercise,
          unit: LABEL_CALORIE,
        },
      },
    };
    setLogData(logData);
  }, [
    policyStatus,
    healthGoal.foodGoal,
    healthGoal.waterGoal,
    healthGoal.activityGoal,
    TotalCarbs,
    TotalProtein,
    TotalFat,
    TotalWater,
    TotalCalories,
    TotalExercise,
  ]);

  const onChangeHandler = (event) => {
    const value = event.target.value;
    let lengthFailed = false;
    if (value[0] === '+' && value[1] === '6' && value[2] === '6') {
      lengthFailed = value.length !== 12;
    } else if (value[0] === '6' && value[1] === '6') {
      lengthFailed = ![11, 12].includes(value.length);
    } else {
      lengthFailed = ![9, 10].includes(value.length);
    }
    const pattern = /((\+66|66|0?)(\d{9,10}))/gm;
    setValidationFailed(lengthFailed ? lengthFailed : !pattern.test(value));
    setBodyKeyId(value);
  };

  const verifyBodyKeyId = async () => {
    switch (autoSyncStep) {
      case AS_BODY_KEY_VERIFICATION_STEP:
        setAutoSyncStep(AS_OTP_VERIFICATION_STEP);
        setTriggerAutoSync(false);
        const response = await dispatch(verifyBodyKeyIdAction(bodyKeyId));
        if (response?.isSuccess) {
          setShowSuccess(true);
          setTimeout(() => {
            const bkId = bodyKeyId;
            setShowSuccess(false);
            setBodyKeyId('');
            loadBodyKeyRecords(bkId);
          }, 2000);
        } else {
          setTriggerAutoSync(true);
          setValidationFailed(true);
          setBodyKeyId(bodyKeyId);
        }
        break;
      case AS_OTP_VERIFICATION_STEP:
        // TODO - SEND OTP API
        break;
    }
  };

  const handleAutoSyncWidget = () => {
    if (isSuccess || customerData?.challengeAutoSync) {
      // NOTE: universal link & app link here
      if (checkIsIOS()) {
        try {
          window.location = new URL(BODY_KEY_UNIVERSAL_LINK);
        } catch (error) {
          window.location = new URL(BODY_KEY_APPSTORE);
        }
      } else {
        try {
          window.location = new URL(BODY_KEY_APP_LINK);
        } catch (error) {
          window.location = new URL(BODY_KEY_PLAYSTORE);
        }
      }
    } else {
      setTriggerAutoSync(!triggerAutoSync);
    }
  };

  return (
    <div className="home-header-container">
      <Header routeTo={checkRoute} customer={customerData} />
      <div className="container-fluid p-0 banner-container">
        <div className="search-input-wrapper">
          <div className="search-input-container">
            <img src="/images/icon-search.svg" className="search-img" />
            <input
              type="text"
              onClick={handleSearchClick}
              className="form-control search-input"
              placeholder={SEARCH_BAR_PLACEHOLDER}
            />
          </div>
        </div>
        <div className="banner-img-warpper">
          <img
            src={`/images/healthAndWellness/${bgImg}`}
            className="w-100 bg-lg"
          />
          <img
            src={`/images/healthAndWellness/sm/${bgImg}`}
            className="w-100 bg-sm"
          />
          <Profile bmiData={bmiData} routeTo={checkRoute} />
        </div>
      </div>
      <FloatingWidget onClick={handleAutoSyncWidget}>
        <button className="auto-sync-widget-btn">
          <img
            src={
              isSuccess || customerData?.challengeAutoSync
                ? '/images/challenge/floating-widget-green.svg'
                : '/images/challenge/floating-widget-white.png'
            }
            alt="floating-widget-auto-sync"
          />
        </button>
      </FloatingWidget>
      <HealthHomeCard logData={logData} />
      <Assessment routeTo={checkRoute} />
      <FoodCalories routeTo={checkRoute} />
      <ChallengeHome />
      <HnwFooter />
      <HnWSlideup scrollHide={true} bottomGap={homePageSlideUpGap} />
      <CalendarLoader showLoading={loading} />
      {bodyKeyLoader && (
        <HnwSuccessOverlayModal
          loop
          show={bodyKeyLoader}
          onTimeout={() => { }}
          animationPath={syncAnimation}
          renderDetail={() => (
            <div className="body-key-loader-details">
              {BODY_KEY_LOADER_TITLE}
            </div>
          )}
        />
      )}
      {showSuccess && (
        <CustomModal
          isOpen={showSuccess}
          className="default-modal-size-success"
        >
          <img
            src="/images/challenge/body-key-success.svg"
            alt="auto-sync-success"
          />
        </CustomModal>
      )}
      {triggerAutoSync && (
        <CustomModal
          isOpen={triggerAutoSync}
          className="default-modal-size-auto-sync"
        >
          <AutoSyncVerification
            autoSyncStep={autoSyncStep}
            bodyKeyId={bodyKeyId}
            validationFailed={validationFailed}
            onChangeHandler={onChangeHandler}
            onClose={() => {
              setAutoSyncStep(AS_BODY_KEY_VERIFICATION_STEP);
              setTriggerAutoSync(false);
              setBodyKeyId('');
            }}
            onVerify={verifyBodyKeyId}
          />
        </CustomModal>
      )}
    </div>
  );
};

export default Home;
