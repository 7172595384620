import { calculateUserPosition } from "../common/challenge/mapHelper";
import { calculateTeamDashboardStatus, getBmiRangeUserCount, getChallengeTodayDate, handleBmiBadges, handleRewardStatus, handleWeightFatMuscleBadges, sortLeaderUser, sortByDateKey, getSplitText, sortBadgeUserList} from "../utill.func";
import { CHALLENGE, CHALLENGE_BODY_KEY_MAPPING, CHALLENGE_STEPS_FOOD_KEY_MAPPING, LINE_REGISTER_STATUS, PERIOD_DATA_MAPPING, teamMaxSize, BADGES_MILESTONE, minContent, maxTeamSteps, USER_DEVICE_REGEX, WEIGHT_OUT_DIFF_MAPPING } from "../common/challengeConstants";



export class ChallengeTeamDashboard {
    constructor(data) {
        if (data) {
            this.rewardEndDate = data?.challengeData?.challengeRewardDate || '';
            this.teamDashboardStatus = calculateTeamDashboardStatus(data?.approve_date, data?.team_size, data?.complete60_date, data.end_challenge, data?.complete_team_in, data.weightout_date, this.rewardEndDate) || '';
            this.end_challenge = data.end_challenge || ''
            this.weightoutDate = data.weightout_date || '';
            this.weightoutStartDate = data.weightout_date?.start_date || '';
            this.weightoutEndDate = data.weightout_date?.end_date || '';
            this.teamId = data?.id || '';
            this.eventId = data?.event_id || '';
            this.challengeDay = data?.challenge_date || '-';
            this.completeTeamDate = data?.complete_team_in || '';
            this.approvalTeamdate = data?.approve_date || '';
            this.teamName = data?.name || '';
            this.teamSize = data?.team_size || 0;
            this.fullTeamRegistered = (parseInt(data?.team_size) == teamMaxSize) ? true : false;
            this.completedDate = data?.complete60_date || '';
            this.eventData = data?.event ? new Event(data?.event) : null;
            this.users = mapAndSortUsers(data?.users, data?.partyId) || [];
            const sortedLeaderUsers = sortRegisteredUser(data?.users);
            //Team Party Ids and User Data
            this.challengePartyIds = sortedLeaderUsers?.map(user => user?.party_id) || [];
            this.challengeUserData = sortedLeaderUsers?.map(user => new UserDataModel(user)) || [];

            if ((sortedLeaderUsers && sortedLeaderUsers.length > 0)) {
                //filter weightin and weightout data
                const registeredData = getUsersPeriodFilterData(sortedLeaderUsers, PERIOD_DATA_MAPPING.registerPeroid);
                const weightOutData = getUsersPeriodFilterData(sortedLeaderUsers, PERIOD_DATA_MAPPING.weightOutPeriod);

                const sortedUserData = getMissionSliderData(sortedLeaderUsers, data?.partyId);
                this.usersStepsList = sortedUserData?.steps?.userData?.sort((firstUserData, secondUserData) => secondUserData?.data?.value - firstUserData?.data?.value);
                // Swiper card mission data
                this.missionUserData = getMissionSliderData(sortedLeaderUsers, data?.partyId);
                // Swiper card body data
                this.bodyUserRegisteredData = getBodySliderData(sortedLeaderUsers, PERIOD_DATA_MAPPING.registerPeroid, data?.partyId)
                this.bodyUserWeightOutData = getBodySliderData(sortedLeaderUsers, PERIOD_DATA_MAPPING.weightOutPeriod, data?.partyId, 'get_diff');
                // Team Body composition card
                this.hasTeamWeighOut = weightOutData.filter(Boolean).length == teamMaxSize
                this.hasUserWeighOut = getPeriodFindData(sortedLeaderUsers.find(user => user?.party_id == data?.partyId), PERIOD_DATA_MAPPING.weightOutPeriod);
                this.dailyPeriodData = registeredData && new RegisteredTeamDailyDataModel(registeredData);
                this.weightOutPeriodData = weightOutData && new WeightOutTeamBodyDataModel(weightOutData);
                 // Team Body Avg card
                this.registeredPeriodData = registeredData && new RegisteredTeamBodyDataModel(registeredData);

            }
            // Team Body Avg card
            this.teamRegisteredAvgData = this.registeredPeriodData && new TeamAverageBodyDataModel(this.registeredPeriodData, this.teamSize);
            this.teamWeightOutAvgData = this.weightOutPeriodData && new TeamAverageBodyDataModel(this.weightOutPeriodData, this.teamSize);

            // Steps data for team
            this.temaStepsData = (data?.team_scores) ? new TeamSteps(data?.team_scores[0], this.challengeDay) : null;
            //default key for steps card
            this.defaultTeamSteps = (!this.fullTeamRegistered ? minContent : maxTeamSteps);
            // total steps badges
            this.totalStepBadges = getTotalBadges(sortedLeaderUsers) || 0;
            // get badge data for steps for mapping at map detail
            this.stepsBadgeData = getStepsBadgeData(sortedLeaderUsers, data?.partyId);
            // get badge data for other badges for mapping at badge detail
            this.otherBadgeData = getOtherBadgeData(sortedLeaderUsers, data?.partyId);
            // Team Rewards List
            this.teamRewards = sortedLeaderUsers?.reduce((acc, curr) => {
                filterRewards(curr?.user_rewards, 'team', acc);
                return acc;
            }, []);

        }
    }
}

export class Event {
    constructor(eventData) {
        this.eventId = eventData?.id || '';
        this.eventName = eventData?.name || '';
        this.startDate = eventData?.start_date || '';
        this.endDate = eventData?.end_date || '';
        this.eventType = eventData?.type || 'CAMPAIGN';
        this.eventSlug = eventData?.slug || '';
    }
}

export class TeamSteps {
    constructor(teamStepsData, challengeDay) {
        const avgVal = challengeDay > 0 ? challengeDay : 1;
        this.teamId = teamStepsData?.team_id || '';
        this.totalSteps = (teamStepsData?.score_details.length > 0) ? teamStepsData?.score_details[0]?.total_step : 0;
        this.avgSteps = Math.round(this.totalSteps / avgVal) || 0;
        this.totalDistance = Math.round(calculateDistanceOnTeamSteps(this.totalSteps)) || 0;
    }
}

export class Users {
    constructor(userData) {
        //Used for userInfo data
        this.challengeUserId = userData?.id || '';
        this.userName = userData?.name || '';
        this.aboId = userData?.abo_id || '';
        this.partyId = userData?.party_id || '';
        this.isLeader = userData?.is_leader || false;
        this.isNewMember = userData?.is_new_member && (userData?.business_nature).toLowerCase() == CHALLENGE.teamDashboard.businessMember || false;
        this.isNewAbo = userData?.is_new_member && (userData?.business_nature).toLowerCase() == CHALLENGE.teamDashboard.businessOwner || false;
        this.myRewards = filterRewards(userData?.user_rewards) || [];
        this.firstName = getSplitText(this.userName, 0, ' ');
        this.lastName = getSplitText(this.userName, 1, ' ');

        if (userData?.user_scores) {
            const userScores = userData?.user_scores;
            this.todaySteps = getTodaySteps(filterUserScoresWithMasterId(userScores, 'steps'), 'steps') || 0;
            this.userTotalSteps = filterUserScoresWithMasterId(userScores, 'steps')?.score_details[0]?.total_step || 0;
            this.userRank = 0;
        }

        if (userData?.user_periods) {
            this.userPeriods = userData?.user_periods || '';
            const registerPeriods = getPeriodFindData(userData, PERIOD_DATA_MAPPING.registerPeroid);
            const weightOutPeriods = getPeriodFindData(userData, PERIOD_DATA_MAPPING.weightOutPeriod);
            this.hasUserWeighOut = weightOutPeriods && weightOutPeriods?.params ? true: false;
            // Health data of user
            this.registerUserData = renderUserHealthData(registerPeriods) || '';
            this.dailyUserData = renderUserHealthData(registerPeriods, 'daily') || '';
            this.weightOutUserData = renderUserHealthData(weightOutPeriods) || '';

            this.phoneNumber = registerPeriods?.params?.period_phone || '';
            this.userDevice = (registerPeriods?.params?.device).replace(USER_DEVICE_REGEX, '') || '';

            if(this.hasUserWeighOut) {
                this.userFatChange = getValueFromParams(weightOutPeriods?.params, 'cal', 'fat_mass_change') || 0;
                this.userMuscleChange = getValueFromParams(weightOutPeriods?.params, 'cal', 'muscle_mass_change') || 0;
                this.userWeightChange = getValueFromParams(weightOutPeriods?.params, 'cal', 'weight_change') || 0;

            } else {
                this.userFatChange = getValueFromParams(registerPeriods?.params, 'daily', 'fat_change') || 0;
                this.userMuscleChange = getValueFromParams(registerPeriods?.params, 'daily', 'muscle_change') || 0;
                this.userWeightChange = getValueFromParams(registerPeriods?.params, 'daily', 'weight_change') || 0;
            }

            this.userWeightChangeIcon = this.userWeightChange ? handleWeightFatMuscleBadges(this.userWeightChange) : '';
            this.userMuscleChangeIcon = this.userMuscleChange ? handleWeightFatMuscleBadges(this.userMuscleChange, true) : '';
            this.userFatChangeIcon = this.userFatChange ? handleWeightFatMuscleBadges(this.userFatChange) : '';

            this.bmiBadge = handleBmiBadges(this.registerUserData.bmi) || '';

        }
    }
}

//only to set uername, partyIds
export class UserDataModel {
    constructor(userData) {
        this.userName = userData?.name || '';
        this.aboId = userData?.abo_id || '';
        this.partyId = userData?.party_id || '';
        this.firstName = getSplitText(this.userName, 0, ' ');
        this.lastName = getSplitText(this.userName, 1, ' ');
    }
}

export class UserDetailModel {
    constructor(userDetail) {
        const lineUsers = userDetail?.filter(detail => detail.registerStatus = LINE_REGISTER_STATUS);
        this.lineUserData = Array.from({ length: teamMaxSize }, (_, i) => {
            const lineUser = lineUsers[i];
            return {
                partyId: lineUser?.partyId || '',
                displayName: lineUser?.displayName || '',
                pictureUrl: lineUser?.pictureUrl || ''
            };
        });
    }
}

export class BadgeDetailCountModel {
    constructor(userBadgeData) {
        this.id = userBadgeData?._id || '';
        this.userId = userBadgeData?.userId || '';
        this.badgeViewedCount = userBadgeData?.badgeViewedCount || 0;
        this.createdDate = userBadgeData?.createdDate || '';
        this.updatedDate = userBadgeData?.updatedDate || '';
    }
}

export class UserRewardModel {
    constructor(userReward) {
        this.rewardId = userReward?.reward_id || '';
        this.rewardStatus = userReward?.status || '';
        this.rewardClaimDate = userReward?.claim_date || '';
        this.rewardUserId = userReward?.rewards?.id || '';
        this.rewardEventId = userReward?.reward?.event_id || '';
        this.rewardImage = userReward?.reward?.image_url || '';
        this.rewardName = userReward?.reward?.name || '';
        this.rewardPrice = userReward?.reward?.price || '';
        this.rewardShortName = userReward?.reward?.short_name || '';
        this.rewardSku = userReward?.reward?.sku || '';
        this.rewardImagesendLine = userReward?.reward?.image_send_line || '';
        this.rewardStatusIconAndTitle = this.rewardStatus ? handleRewardStatus(this.rewardStatus) : '';
        this.rewardDescription = userReward?.reward?.product_description || '';
        this.rewardActualName = userReward?.reward?.name_send_line || '';

    }
}

export class RegisteredTeamDailyDataModel {
    constructor(periodData) {
        //used for total team data
        this.totalTeamFat = accumulateData(getTeamBodyData(periodData, 'daily', 'fat')) || 0;
        this.totalTeamMuscle = accumulateData(getTeamBodyData(periodData, 'daily', 'muscle')) || 0;
        this.totalTeamWeight = accumulateData(getTeamBodyData(periodData, 'daily', 'weight')) || 0;
        this.bmiInRangeCount = getBmiRangeUserCount(getTeamBodyData(periodData, 'bmi', 'bmi')) || 0;

        this.teamFatChange = accumulateData(getTeamBodyData(periodData, 'daily', 'fat_change')) || 0;
        this.teamMuscleChange = accumulateData(getTeamBodyData(periodData, 'daily', 'muscle_change')) || 0;
        this.teamWeightChange = accumulateData(getTeamBodyData(periodData, 'daily', 'weight_change')) || 0;

        this.teamWeightChangeIcon = handleWeightFatMuscleBadges(this.teamWeightChange) || '';
        this.teamMuscleChangeIcon = handleWeightFatMuscleBadges(this.teamMuscleChange, true) || '';
        this.teamFatChangeIcon = handleWeightFatMuscleBadges(this.teamFatChange) || '';

    }
}
export class RegisteredTeamBodyDataModel {
    constructor(periodData) {
        //used for total team data
        this.totalTeamFat = accumulateData(getTeamBodyData(periodData, 'fat', 'kg_cal')) || 0;
        this.totalTeamMuscle = accumulateData(getTeamBodyData(periodData, 'muscle', 'kg')) || 0;
        this.totalTeamWeight = accumulateData(getTeamBodyData(periodData, 'weight', 'weight')) || 0;
        this.bmiInRangeCount = getBmiRangeUserCount(getTeamBodyData(periodData, 'bmi', 'bmi')) || 0;
    }
}

export class WeightOutTeamBodyDataModel {
    constructor(periodData) {
        //used for total team data
        this.totalTeamFat = accumulateData(getTeamBodyData(periodData, 'fat', 'kg_cal')) || 0;
        this.totalTeamMuscle = accumulateData(getTeamBodyData(periodData, 'muscle', 'kg')) || 0;
        this.totalTeamWeight = accumulateData(getTeamBodyData(periodData, 'weight', 'weight')) || 0;
        this.bmiInRangeCount = getBmiRangeUserCount(getTeamBodyData(periodData, 'bmi', 'bmi')) || 0;

        this.teamFatChange = accumulateData(getTeamBodyData(periodData, 'cal', 'fat_mass_change')) || 0;
        this.teamMuscleChange = accumulateData(getTeamBodyData(periodData, 'cal', 'muscle_mass_change')) || 0;
        this.teamWeightChange = accumulateData(getTeamBodyData(periodData, 'cal', 'weight_change')) || 0;

        this.teamWeightChangeIcon = handleWeightFatMuscleBadges(this.teamWeightChange) || '';
        this.teamMuscleChangeIcon = handleWeightFatMuscleBadges(this.teamMuscleChange, true) || '';
        this.teamFatChangeIcon = handleWeightFatMuscleBadges(this.teamFatChange) || '';
    }
}

export class TeamAverageBodyDataModel {
    constructor(data, avgVal) {
        this.teamAvgFat = calculateAvg(data?.totalTeamFat, avgVal) || 0;
        this.teamAvgMuscle = calculateAvg(data?.totalTeamMuscle, avgVal) || 0;
        this.teamAvgWeight = calculateAvg(data?.totalTeamWeight, avgVal) || 0;
    }
}

export const calculateAvg = (value, avgVal) => {
    return (value / avgVal);
}

export const calculateDistanceOnTeamSteps = (teamSteps) => {
    return CHALLENGE.teamDashboard.distanceConstant * teamSteps;
}


const filterUserScoresWithMasterId = (userScores, key) => {
    return userScores?.find((scoreId) => scoreId?.score_master?.id === CHALLENGE_STEPS_FOOD_KEY_MAPPING[key].id);
}

const getTeamRewardsData = (teamRewards) => {
    const totalTeamReward = teamRewards.reduce((acc, user) => acc + user, 0);
    return totalTeamReward;
}

// manage steps/vdo/food/calorie/quiz data of all users
const getMissionSliderData = (users, currentPartyId) => {
    const keys = Object.keys(CHALLENGE_STEPS_FOOD_KEY_MAPPING);

    const missionData = keys.reduce((acc, key) => {
        const title = CHALLENGE_STEPS_FOOD_KEY_MAPPING[key].title;
        const unit = CHALLENGE_STEPS_FOOD_KEY_MAPPING[key].unit;
        let currentUserData = {};
        const userData = Array.from({ length: teamMaxSize }, (_, i) => {
            const user = users[i];
            const scoreDetails = filterUserScoresWithMasterId(user?.user_scores, key);
            const badges_count = scoreDetails && scoreDetails?.score_details.length > 0 ? scoreDetails?.score_details[0]?.total_badge : scoreDetails?.score_details?.total_badge;
            let value = scoreDetails ? scoreDetails?.score_details?.[0][CHALLENGE_STEPS_FOOD_KEY_MAPPING[key].key_map] : 0;
            let score = scoreDetails ? scoreDetails?.score : 0;
            const daily_value = scoreDetails ? scoreDetails?.score_details?.[0][CHALLENGE_STEPS_FOOD_KEY_MAPPING[key].daily_key_map]?.find((data) => data?.date == getChallengeTodayDate())?.[CHALLENGE_STEPS_FOOD_KEY_MAPPING[key].daily_key_value] : 0;
            const output = {
                aboId: user?.abo_id || '',
                isLeader: user?.is_leader || false,
                partyId: user?.party_id || '',
                ...(key === 'steps' && { positionOnMap: calculateUserPosition(value) } ),
                data: {
                    badges_count,
                    value,
                    daily_value,
                    score
                }
            };
            if (user?.party_id === currentPartyId) {
                currentUserData = output;
            }
            return output;
        });
        acc[key] = {
            title,
            unit,
            userData,
            currentUserData
        };
        return acc;
    }, {});

    return missionData;
};

// manage weight/fat/muscle/bmi data of all users
const getBodySliderData = (users, mappingKey, currentPartyId, key='') => {
    let currentUserData = null;

    const bodyData = Object.keys(CHALLENGE_BODY_KEY_MAPPING).reduce((acc, metricKey) => {
        const { title, unit } = CHALLENGE_BODY_KEY_MAPPING[metricKey];
        acc[metricKey] = {
            title: title,
            unit: unit,
            currentUserData: null,
            userData: []
        };
        return acc;
    }, {});

    const defaultUserData = {
        badges_count: 0,
        value: 0,
        diffValue: 0
    };

    Array.from({ length: teamMaxSize }, (_, i) => {
        const user = users[i];
        if (user) {
            const registerPeriods = getPeriodFilterData(user, mappingKey);
            Object.keys(CHALLENGE_BODY_KEY_MAPPING).forEach(metricKey => {
                const keyMap = CHALLENGE_BODY_KEY_MAPPING[metricKey];
                const userData = registerPeriods.map(period => {
                    let value = getValueFromParams(period?.params, keyMap?.key, keyMap?.key_map) || 0;
                    let diffValue = 0;
                    if(key == 'get_diff') {
                        const diffKeyMap = WEIGHT_OUT_DIFF_MAPPING[metricKey];
                        diffValue = getValueFromParams(period?.params, diffKeyMap?.key, diffKeyMap?.key_map) || 0;
                    }
                    const result = {
                        aboId: user?.abo_id || '',
                        partyId: user?.party_id || '',
                        isLeader: user?.is_leader || false,
                        data: {
                            badges_count: defaultUserData.badges_count,
                            value: value,
                            diffValue: diffValue && parseFloat(diffValue).toFixed(1)
                        }

                    };
                    if (user?.party_id === currentPartyId) {
                        currentUserData = result;
                    }
                    return result;
                });
                bodyData[metricKey].userData.push(...userData);
            });
        } else {
            Object.keys(CHALLENGE_BODY_KEY_MAPPING).forEach(metricKey => {
                bodyData[metricKey].userData.push({
                    aboId: '',
                    partyId: '',
                    data: { ...defaultUserData },
                    currentUserData: {}
                });
            });
        }
    });

    Object.keys(bodyData).forEach(metricKey => {
        if (currentUserData) {
            bodyData[metricKey].currentUserData = {
                aboId: currentUserData.aboId,
                partyId: currentUserData.partyId,
                data: currentUserData.data
            };
        }
    });

    return bodyData;
};

const getValueFromParams = (params, key, keyMap) => {
    if (!params || typeof params !== 'object') {
        return null;
    }
    if (typeof params[key] === 'object' && params[key][keyMap] !== undefined) {
        return params[key][keyMap];
    } else {
        return params[keyMap];
    }
}

const getTodaySteps = (scoreDetails, key) => {
    const stepsData = scoreDetails?.score_details[0][CHALLENGE_STEPS_FOOD_KEY_MAPPING[key].daily_key_map]
    return stepsData?.find((data) => data?.date == getChallengeTodayDate())?.[CHALLENGE_STEPS_FOOD_KEY_MAPPING[key].daily_key_value];
}

const getTotalBadges = (users) => {
    return users.reduce((acc, user) => {
        const userTotalBadge = user?.user_scores.reduce((userAcc, userScores) => {
            const badgeTotal = userScores?.score_details?.[0]?.total_badge || 0;
            return userAcc + badgeTotal;
        }, 0);

        return acc + userTotalBadge;
    }, 0);
}

// Filter peroid data is weightin or weightout for weight/muscle/fat
const getPeriodFilterData = (userData, key) => {
    return userData?.user_periods?.filter(data => data?.period.type === key);
}

const getPeriodFindData = (userData, key) => {
    return userData?.user_periods?.find(data => data?.period.type === key);
}

const getUsersPeriodFilterData = (users, key) => {
    return users?.map((userData) => {
        return getPeriodFindData(userData, key);
    })
}

//map only step badges data of user for each milestone
const getStepsBadgeData = (users, currentPartyId) => {
    const getBadgeIndex = badgeKey => badgeKey.match(/\d+$/)[0];
    let result = Object.values(BADGES_MILESTONE).reduce((acc, category) => {
        acc[category] = {
            allUsersData: [],
            currentUserData: null
        };
        return acc;
    }, {});

    const userData = (user, badgeInfo, badgeKey, userScores) => ({
        aboId: user?.abo_id,
        partyId: user?.party_id,
        isLeader: user?.is_leader,
        userName: user?.name,
        badgeReceived: badgeInfo[badgeKey],
        receivedAt: badgeInfo['received_at'] || '-',
        pictureUrl: badgeInfo?.pictureUrl || '/images/challenge/default_challenge_profile.svg',
        total_badge: userScores?.total_badge || 0,
        total_steps: userScores?.total_step || 0,
        firstName: getSplitText(user?.name, 0, ' '),
        lastName: getSplitText(user?.name, 1, ' ')

    });

    users.map(user => {
        const userScores = filterUserScoresWithMasterId(user?.user_scores, 'steps');
        const badgesData = userScores?.score_details[0]?.badge_details;

        Object.entries(badgesData || {}).map(([badgeKey, badgeInfo]) => {
            const badgeIndex = getBadgeIndex(badgeKey);
            const category = BADGES_MILESTONE[`badge${badgeIndex}`];
            if (category) {
                result[category].allUsersData.push(userData(user, badgeInfo, badgeKey, userScores?.score_details[0]));
                if (user?.party_id === currentPartyId) {
                    result[category].currentUserData = userData(user, badgeInfo, badgeKey, userScores?.score_details[0]);
                }
            }
        });
    });

    result = sortBadgeUserList(result, 'badgeReceived','receivedAt', 'total_steps');
    return result;
}

// map to get other badges data of user quiz,vdo can have only one badge
const getOtherBadgeData = (users, currentPartyId) => {
    const keys = Object.keys(CHALLENGE_STEPS_FOOD_KEY_MAPPING).filter(key => key !== 'steps');
    const userData = (user, badgesData, scoresData, totalValue) => ({
        aboId: user?.abo_id,
        partyId: user?.party_id,
        isLeader: user?.is_leader,
        userName: user?.name,
        badgeReceived: badgesData?.badge1,
        receivedAt: badgesData?.received_at || null,
        pictureUrl: badgesData?.pictureUrl || '/images/challenge/default_challenge_profile.svg',
        totalBadge: scoresData?.total_badge || 0,
        totalValue: totalValue || 0,
        firstName:getSplitText(user?.name, 0, ' '),
        lastName: getSplitText(user?.name, 1, ' ')
    });

    let result = keys.reduce((acc, key) => {
        const { id, key_map } = CHALLENGE_STEPS_FOOD_KEY_MAPPING[key];
        const allUsersData = [];
        let currentUserData = null;
        users.forEach(user => {
            user.user_scores.forEach(userScores => {
                const scoreMasterId = userScores?.score_master?.id;
                const badgesData = userScores?.score_details[0]?.badge_details?.badge1;
                const scoresData = userScores?.score_details[0];
                const totalValue = (key == 'quiz') ? userScores?.score : scoresData?.[key_map];
                if (scoreMasterId === id) {
                    const data = userData(user, badgesData, scoresData, totalValue);
                    allUsersData.push(data);
                    if (user.party_id === currentPartyId) {
                        currentUserData = data;
                    }
                }
            });
        });
        acc[key] = {
            allUsersData,
            currentUserData
        };

        return acc;
    }, {});
    result = sortBadgeUserList(result, 'badgeReceived','receivedAt','totalValue', 'desc')

    return result;
}

const mapAndSortUsers = (users, currentPartyId) => {
    const userData = users.map(user => new Users(user, currentPartyId));
    const sortedBySteps = userData.sort((a, b) => b.userTotalSteps - a.userTotalSteps);
    sortedBySteps.forEach((user, index) => {
        user.userRank = index + 1;
    });

    const sortedAndPrioritized = sortedBySteps.sort((a, b) => {
        if (a.partyId === currentPartyId && b.partyId !== currentPartyId) return -1;
        if (a.partyId !== currentPartyId && b.partyId === currentPartyId) return 1;
        return 0;
    });
    return sortedAndPrioritized;
}

const accumulateData = (value) => {
    const data = value.map(Number);
    return data.reduce((acc, curr) => acc + curr, 0);
}
const accumulateRewardData = (value) => {
    return value.reduce((acc, curr) => acc + curr, 0);
}

const getTeamBodyData = (periodData, key , key_map= '') => {
    return periodData.map((data) => getValueFromParams(data?.params, key, key_map) || '0');
};

const renderUserHealthData = (data, dataSet = '') => {
    const bodyData = {}
    Object.keys(CHALLENGE_BODY_KEY_MAPPING).forEach(metricKey => {
        const keyMap = CHALLENGE_BODY_KEY_MAPPING[metricKey];
        const keySet = dataSet == 'daily' ? dataSet : keyMap?.key;
        const keySetMap = dataSet == 'daily' ? keyMap?.key : keyMap?.key_map;
        const userData = getValueFromParams(data?.params, keySet, keySetMap) || 0;
        return bodyData[metricKey] = userData;
    });
    return bodyData;
}

const filterRewards = (userRewards, key, acc) => {
    return userRewards?.filter(data=>data?.status===CHALLENGE.reward.waiting_to_received || data?.status===CHALLENGE.reward.not_qualified ||data?.status===CHALLENGE.reward.received)
        .map(reward => {
        if(key == 'team') {
                return acc.push(new UserRewardModel(reward));
            } else {
                return new UserRewardModel(reward);
            }
        });
}

export const sortRegisteredUser = (users) => {
    const sortedByDate = sortByDateKey(users, 'created_at');
    return sortLeaderUser(sortedByDate);
};
