import React from 'react';
import { HnwSetterCard } from '../../../../../common';
import {
  ERROR_TYPE,
  EXERCISE_LOG_DETAIL,
  HNW_GOAL_SETTING,
  SUCCESS_TYPE,
} from '../../../../../common/commonConstant';
import HnWLineInput from '../../../../../common/hnwLineInput/HnWLineInput';
import HnWActivityNotes from '../../../hnwGoals/components/hnwActivityNotes/HnWActivityNotes';
import ExerciseInputCard from '../exerciseInputCard/ExerciseInputCard';
import ExerciseSubDetails from '../exerciseSubDetails/ExerciseSubDetails';
import ExerciseMenuHeader from '../exerciseMenuHeader/ExerciseMenuHeader';

export default function ExerciseSuggestLogPage({
  exerciseLogDetailState,
  onChangeValue,
  exerciseMenu,
  exerciseLogError,
  startTimeValidationStatus,
  endTimeValidationStatus,
  handleKeypadOpen,
  handleScrollBottom
}) {
  const isSuggestedCalorie =
    parseInt(exerciseLogDetailState.excerciseTime) *
      parseInt(exerciseMenu.calorieBurnTime) ===
    parseInt(exerciseLogDetailState.caloriesBurn);
  const isSuggestedTime =
    parseInt(exerciseMenu.suggestionTime) ===
    parseInt(exerciseLogDetailState.excerciseTime);
  return (
    <>
      <ExerciseMenuHeader
        name={exerciseMenu?.name}
        image={exerciseMenu?.image}
        templateImg={exerciseMenu?.exerciseTemplateBannerImage}
      />
      <HnwSetterCard
        headerText={EXERCISE_LOG_DETAIL.LOG_DETAIL.BURN_CALORIE_HEADER}
        unit={
          isSuggestedCalorie
            ? EXERCISE_LOG_DETAIL.LOG_DETAIL.BURN_CALORIE_UNIT_RECOMMENDED
            : EXERCISE_LOG_DETAIL.LOG_DETAIL.BURN_CALORIE_UNIT
        }
        defaultValue={
          parseInt(exerciseLogDetailState.excerciseTime) *
          exerciseMenu.calorieBurnTime
        }
        resetEnabled={!isSuggestedCalorie}
        value={exerciseLogDetailState.caloriesBurn}
        onValueChange={(value) => onChangeValue('caloriesBurn', value)}
        maxValue={EXERCISE_LOG_DETAIL.LOG_DETAIL.BURN_CALORIE_MAX_VALUE}
        minValue={EXERCISE_LOG_DETAIL.LOG_DETAIL.BURN_CALORIE_MIN_VALUE}
        stepperVal={EXERCISE_LOG_DETAIL.LOG_DETAIL.BURN_CALORIE_STEPPER_VALUE}
        footerEnabled={false}
        footerTextArr={[]}
      />
      <HnwSetterCard
        headerText={EXERCISE_LOG_DETAIL.LOG_DETAIL.EXERCISE_TIME_HEADER}
        unit={
          isSuggestedTime
            ? EXERCISE_LOG_DETAIL.LOG_DETAIL.EXERCISE_TIME_UNIT_RECOMMENDED
            : EXERCISE_LOG_DETAIL.LOG_DETAIL.EXERCISE_TIME_UNIT
        }
        defaultValue={exerciseMenu.suggestionTime}
        resetEnabled={!isSuggestedTime}
        value={exerciseLogDetailState.excerciseTime}
        onValueChange={(value) => onChangeValue('excerciseTime', value)}
        maxValue={EXERCISE_LOG_DETAIL.LOG_DETAIL.EXERCISE_TIME_MAX_VALUE}
        minValue={EXERCISE_LOG_DETAIL.LOG_DETAIL.EXERCISE_TIME_MIN_VALUE}
        stepperVal={EXERCISE_LOG_DETAIL.LOG_DETAIL.EXERCISE_TIME_STEPPER_VALUE}
        footerEnabled={false}
        footerTextArr={[]}
        enableQuickAccessAction={true}
        quickAccessActionMultiplier={
          HNW_GOAL_SETTING.ACTIVITY.EXERCISE_QUICK_ACCESS_MULTIPLIER
        }
        quickAccessActionArr={
          HNW_GOAL_SETTING.ACTIVITY.EXERCISE_TIME_HOUR_OPTIONS
        }
        logStepperValueSuffix={
          HNW_GOAL_SETTING.ACTIVITY.EXERCISE_TIME_HOUR_UNIT_VALUE
        }
      />
      <ul className="excercise-details-list">
        <HnWActivityNotes />
      </ul>
      <ExerciseSubDetails />
      <ExerciseInputCard
        inputFields={[
          {
            fieldIcon:
              '/images/healthAndWellness/exercise-log/start-time-icon.svg',
            fieldName: EXERCISE_LOG_DETAIL.LOG_DETAIL.START_TIME,
            inputRenderProp: () => (
              <HnWLineInput
                name="startTime"
                value={exerciseLogDetailState.startTime}
                type="time"
                onChange={onChangeValue}
                validationStatus={startTimeValidationStatus}
                handleKeypadOpen={handleKeypadOpen}
                handleScrollBottom={handleScrollBottom}
              />
            ),
          },
          {
            fieldIcon:
              '/images/healthAndWellness/exercise-log/end-time-icon.svg',
            fieldName: EXERCISE_LOG_DETAIL.LOG_DETAIL.END_TIME,
            inputRenderProp: () => (
              <HnWLineInput
                name="endTime"
                value={exerciseLogDetailState.endTime}
                type="time"
                onChange={onChangeValue}
                validationStatus={endTimeValidationStatus}
                handleKeypadOpen={handleKeypadOpen}
                handleScrollBottom={handleScrollBottom}
              />
            ),
          },
        ]}
      />
    </>
  );
}
