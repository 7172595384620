import axios from 'axios';
import {
  getAccessToken,
  getFacadeAccessToken,
  liffGetProfile,
} from '../promotion/auth.service';
import { getCustomerFOA } from '../promotion/foa.service';
import {
  clearFacadeToken,
  getFacadeToken,
  isNotValidSessionABOMember,
  setFacadeToken,
} from './healthWellness.service';

const liff = window.liff;
const AxiosHealthWellness = axios.create();

AxiosHealthWellness.interceptors.request.use(async (config) => {
  const token = await getAccessToken();
  config.headers.authorization = `Bearer ${token}`;
  let facadeToken = await getFacadeToken();
  const liffProfile = await liffGetProfile();
  config.headers['x-api-key'] = liffProfile.userId;
  const customer = await getCustomerFOA(liffProfile.userId);

  if (isNotValidSessionABOMember(facadeToken, customer)) {
    clearFacadeToken();
    facadeToken = null;
  }

  if (!facadeToken) {
    try {
      const accessToken = await getFacadeAccessToken();
      if (
        (customer.type === 'member' ||
          customer.type === 'abo' ||
          customer.type === 'foa') &&
        accessToken
      ) {
        if (!accessToken) {
          alert('เกิดข้อพิดพลาด กรุณาลองใหม่ในภายหลัง');
          setTimeout(() => liff.closeWindow(), 2000);
          return config;
        }
        setFacadeToken(accessToken);
        config.headers['x-req-token'] = accessToken.access_token;
        config.headers.account = `${accessToken.account.accountId}`;
        config.headers.customer = `${accessToken.account.primaryParty.uid}`;
      } else {
        //case type public
        if (!customer) {
          alert('ไม่พบข้อมูลผู้ใช้ กรุณาลองใหม่ในภายหลัง');
          setTimeout(() => liff.closeWindow(), 2000);
          return config;
        }
        sessionStorage.setItem('customerFOAData', JSON.stringify(customer));
      }
    } catch (error) {
      console.error(error);
    }
  } else {
    config.headers['x-req-token'] = facadeToken.access_token;
    config.headers.account = `${facadeToken.account.accountId}`;
    config.headers.customer = `${facadeToken.account.primaryParty.uid}`;
  }
  return config;
});

AxiosHealthWellness.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    localStorage.removeItem('facadeToken');
    if (
      (error.response !== undefined && error.response.status === 401) ||
      error.response.status === 403
    ) {
      window.location.reload();
    }
    return Promise.reject(error);
  },
);

export default AxiosHealthWellness;
