import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './ChallengeTeamDashboard.scss';
import { CHALLENGE, CHALLENGE_DURATION, ChallengeTeamdashboardStatus, rectBadgeClassname, singleBadgeClassname, teamMaxSize } from '../../../common/challengeConstants';
import { ChallengeButton, RectBadge, commonConstant } from '../../../common';
import { getParamsString, getDataFromSession, calculateDaysLeft, checkAfterWeightPeriod } from '../../../utill.func';
import { useLocation, useHistory } from "react-router-dom";
import queryString from 'query-string';

import {
    DashboardFooter, DashboardHeader, TeamInfoCarousel, TeamStepsCard, TeamVdoBadgeCard,
    TeamBodyCard, TeamCompositionChange, TeamMissionSlider, TeamBodyDataSlider,
    TeamDashboardLoader,
    TeamDashboardStepsLoader,
    TeamDashboardVdoLoader,
    TeamDashboardCompositionLoader,
} from './index';

import { fetchChallengeTeamDetail, fetchUserDetailsData } from '../../../actions';
import HnWSlideup from '../../../common/slideup/HnWSlideup';

const ChallengeTeamDashboard = () => {
    const dispatch = useDispatch();
    const { teamData, userDetail, loading } = useSelector((state) => state.challengeTeamDashboard);
    const location = useLocation();
    const history = useHistory();
    const params = queryString.parse(location.search);
    const challengeId = params['challengeId'] || teamData?.eventId;
    const eventId = params['eventId'];
    const customer = getDataFromSession('customer');
    const challengeStatus = teamData?.teamDashboardStatus?.status;
    const challengeHeaderDate = teamData?.teamDashboardStatus?.header;
    const [isHeaderSticky, setIsHeaderSticky] = useState(false);
    const notificationBadge = teamData?.teamDashboardStatus?.notification;
    const headerBadge = teamData?.teamDashboardStatus?.titleBadge;
    const challengeNotStarted = challengeStatus == ChallengeTeamdashboardStatus.ready_to_start || challengeStatus == ChallengeTeamdashboardStatus.not_yet_started;
    const afterWeightOutPeriod = checkAfterWeightPeriod(challengeStatus);
    const hasChallengeDayPassed = teamData?.challengeDay > CHALLENGE_DURATION;
    sessionStorage.setItem('badgeScreen', JSON.stringify('team-dashboard'));
    const footerDisplay = teamData?.fullTeamRegistered && !challengeNotStarted;
    const [notiLoader, setNotiLoader] = useState(false);

    const [syncLoader, setSyncLoader] = useState(false);

    useEffect(() => {
        setSyncLoader(teamData?.teamName);
        const handleScroll = () => {
            const windowTop = window.scrollY;
            setIsHeaderSticky(windowTop > 30);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    useEffect(() => {
        console.log(customer?.partyId, 'customer?.partyId', customer?.partyId == undefined)
        let customerData = (customer?.partyId == undefined);
        setNotiLoader(customerData)
    }, [customer?.partyId]);

    const getChallengeTeamDashboardData = async () => {
        try {
            await dispatch(fetchChallengeTeamDetail({ amwayNumber: customer?.amwayNumber, partyId: customer?.partyId, challengeId }));
        } catch (error) {
            throw error;
        } finally {
            setSyncLoader(true);
        }
    }

    useEffect(() => {
        let intervalId;
        // calling api after every 2.5 minutes to sync data from challenge api
        getChallengeTeamDashboardData()
        intervalId = setInterval(getChallengeTeamDashboardData, CHALLENGE.teamDashboard.refreshInterval);
        return () => {
            if (intervalId) {
              clearInterval(intervalId);
              setSyncLoader(false);
            }
          };
       
    }, [customer?.partyId]);

    useEffect(() => {
        const didMount = () => {
            if (teamData?.challengePartyIds && userDetail.length == 0) {
                getChallengeUserProfileData();
            }
        }
        didMount();
    }, [teamData?.challengePartyIds]);

    const getChallengeUserProfileData = async () => {
        if (teamData?.challengePartyIds) {
            await dispatch(fetchUserDetailsData(teamData?.challengePartyIds));
        }
    }

    const handleBtnClick = () => {
        const params = {
            challengeId: challengeId,
            eventId: eventId,
            isRegistered: true
        }
        history.push(`${commonConstant.pathChallengeDetail}?${getParamsString(params)}`);
    }

    const handleVdoRedirection = () => {
        const currentUser = teamData?.users?.find(user => user?.partyId == customer?.partyId);
        const params = {
            challengeId: challengeId,
            eventId: eventId,
            userId: `${currentUser?.challengeUserId}`
        }
        history.push(`${commonConstant.pathChallengeQuiz}?${getParamsString(params)}`);
    }

    const handleMapRedirection = () => {
        const params = {
            challengeId: challengeId,
            eventId: eventId
        }
        history.push(`${commonConstant.pathChallengeMap}?${getParamsString(params)}`);
    }

    const handleMyProfileRedirection = (partyId) => {
        const params = {
            challengeId: challengeId,
            eventId: eventId,
            partyId: partyId
        }
        return history.push(`${commonConstant.pathChallengeMyDashboard}?${getParamsString(params)}`);
    }

    const challengeNotificationDate = challengeStatus === ChallengeTeamdashboardStatus.weight_end ? teamData?.weightoutEndDate : teamData?.completeTeamDate;

    const handleVdoNavigation = () => {
        !hasChallengeDayPassed && handleVdoRedirection();
    }

    const handleBadgeScreenRedirection = (isTeam = false) => {
        const currentUser = teamData?.users?.find(user => user?.partyId == customer?.partyId)
        const params = {
            challengeId: challengeId,
            eventId: eventId,
            partyId: `${currentUser?.partyId}`,
            isTeam: isTeam
        }
        return history.push(`${commonConstant.pathChallengeBadgeScreen}?${getParamsString(params)}`);
    }

    const handleCompositionData = () => {
        return (afterWeightOutPeriod && teamData?.hasTeamWeighOut) ? teamData?.weightOutPeriodData : teamData?.dailyPeriodData;
    }

    return (
        <>
            <div className='challenge-team-dashboard-container'>

                {challengeStatus && teamData?.eventData && <DashboardHeader challengeDate={challengeHeaderDate} badgeClassname={rectBadgeClassname[challengeStatus]} isHeaderSticky={isHeaderSticky} />}

                <div className='challenge-team-dashboard-body'>
                    {notificationBadge && <div className='challenge-team-dashboard-error'>
                        <RectBadge badgeClassname={singleBadgeClassname[challengeStatus]}>
                            <div className="rect-badge-description">
                                <div className='rect-badge-left-section'>
                                    <img className='rect-badge-icon' src={singleBadgeClassname[challengeStatus]?.icon} />
                                </div>
                                <div className='rect-badge-right-section'>
                                    <div className='rect-badge-title'> {`${singleBadgeClassname[challengeStatus]?.title} ${calculateDaysLeft(challengeNotificationDate)} ${singleBadgeClassname[challengeStatus]?.subtitle}`}</div>
                                </div>
                            </div>
                        </RectBadge>
                    </div>}
                    {(loading || notiLoader) && !syncLoader ? <TeamDashboardStepsLoader /> : <TeamStepsCard
                        teamDashboardHeaderBadge={headerBadge}
                        challengeDay={teamData?.challengeDay}
                        teamName={teamData?.teamName}
                        totalTeamSteps={teamData?.temaStepsData?.totalSteps}
                        teamSize={teamData?.teamSize}
                        totalDistance={teamData?.temaStepsData?.totalDistance}
                        defaultTeamSteps={teamData?.temaStepsData?.avgSteps}
                        teamDashboardStatus={challengeStatus}
                    />}
                    {(loading || notiLoader) && !syncLoader ? <TeamDashboardVdoLoader /> : <TeamVdoBadgeCard
                        fullTeamRegistered={teamData?.fullTeamRegistered}
                        totalStepBadges={teamData?.totalStepBadges}
                        teamDashboardStatus={challengeStatus}
                        onVdoBtnClick={handleVdoNavigation}
                        onBadgeBtnClick={handleVdoNavigation}
                        badgeScreenRedirect={handleBadgeScreenRedirection}
                    />}
                    {(loading || notiLoader) && !syncLoader ? (<TeamDashboardLoader />) :
                        <TeamInfoCarousel
                            teamData={teamData?.users}
                            teamDashboardStatus={challengeStatus}
                            badgeClassname={singleBadgeClassname[challengeStatus]}
                            teamSize={teamData?.teamSize}
                            redirectionMyDashboard={handleMyProfileRedirection} 
                            challengeUserData={userDetail}/>
                    }
                    {(loading || notiLoader) && !syncLoader ? (<TeamDashboardCompositionLoader />) : <>
                    {teamData?.missionUserData && <TeamMissionSlider
                        challengeUserData={userDetail}
                        missionUserData={teamData?.missionUserData}
                        teamSize={teamMaxSize} />}
                    {teamData?.bodyUserRegisteredData && <TeamBodyDataSlider
                        challengeUserData={userDetail}
                        bodyUserData={teamData?.bodyUserRegisteredData}
                        bodyWeightOutUserData={teamData?.bodyUserWeightOutData}
                        teamSize={teamMaxSize}
                        teamDashboardStatus={challengeStatus}
                        afterWeightOutPeriod={afterWeightOutPeriod}
                        hasTeamWeighOut={teamData?.hasTeamWeighOut} />}

                    {<TeamBodyCard
                        teamDashboardStatus={challengeStatus}
                        teamRegisteredAvgData={teamData?.teamRegisteredAvgData}
                        teamWeightOutAvgData={teamData?.teamWeightOutAvgData}
                        afterWeightOutPeriod={afterWeightOutPeriod}
                        hasTeamWeighOut={teamData?.hasTeamWeighOut}
                    />}
                    {teamData?.teamSize == CHALLENGE.teamDashboard.maxTeamMembers && !challengeNotStarted &&
                        <TeamCompositionChange
                            compositionData = {handleCompositionData()}
                            teamDashboardStatus={challengeStatus}
                            hasTeamWeighOut={teamData?.hasTeamWeighOut}
                            showWeightOutTitle={afterWeightOutPeriod && teamData?.hasTeamWeighOut}
                        />
                    }
                    <div className='challenge-team-dashboard-container-details'>
                        <ChallengeButton
                            btnTitle={CHALLENGE.teamDashboard.detailBtnTitle}
                            detailBtn={true}
                            onBtnClick={() => handleBtnClick()}
                        />
                    </div>
                    </>
                }
                </div>
                {/* //TODO: need to update condition later */}
                {footerDisplay && <DashboardFooter firstButtonTitle={CHALLENGE.teamDashboard.dashboardFooterLeftBtnTitle}
                    secondButtonTitle={CHALLENGE.teamDashboard.dashboardFooterRightBtnTitle}
                    onSecondBtnClick={handleMapRedirection}
                    onFirstBtnClick={handleBadgeScreenRedirection}
                />}
               <HnWSlideup bottomGap={footerDisplay ? '92px' : '0px'} scrollHide={true}/>
            </div>
        </>
    )
}

export default ChallengeTeamDashboard