import React, { forwardRef, useEffect, useState } from 'react';
import FoodLogDrinksWrapper from './foodLogDrinksWrapper/FoodLogDrinksWrapper';
import FoodLogBeverages from './foodLogBeverages/FoodLogBeverages';
import { Drinks } from '../../../../../model';
import {
  DRINK_LIMIT,
  DRINK_NAME,
  DRINK_STATUS,
  DEFAULT_DRINK_INTAKE,
  DRINKS_PAYLOAD_MAPPER,
  SOFT_DRINK_VOLUME_LIST,
  WATER_VOLUME_LIST,
} from '../../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeWaterLogs,
  deleteLog,
  saveWaterLogs,
  saveWaterVolume,
  updateHealthData,
  updateVolumeAfterLog,
  updateWaterVolume,
} from '../../../../../thunk/foodLog.thunk';
import { HealthHomeProgressBar, MobilePicker } from '../../../../../common';
import { HNW_FOOD_LOG } from '../../../../../common/commonConstant';
import { isValidObject } from '../../../../../utill.func';

const FoodLogDrinks = forwardRef(
  (
    {
      customerId,
      healthData = [],
      volumeData,
      setLoading,
      waterGoalData,
      isWatergoalCalculated,
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const volumeObj = isValidObject(volumeData)
      ? volumeData
      : DEFAULT_DRINK_INTAKE;
    const { waterVolume, coffeeVolume, softDrinkVolume } = volumeObj;
    const [openModal, setOpenModal] = useState(false);
    const [water, setWater] = useState([]);
    const [coffee, setCoffee] = useState([]);
    const [softDrink, setSoda] = useState([]);
    const [defaultValue, setDefaultValue] = useState(null);
    const [selections, setSelections] = useState(null);
    const [currentWaterIntake, setCurrentWaterIntake] = useState(0);

    useEffect(() => {
      setCurrentWaterIntake(getCurrentWaterIntake());
    }, [water, coffee, softDrink, volumeData]);

    useEffect(() => {
      createDrinksData();
      setLoading(false);
    }, [healthData]);

    const filterData = (data, fieldName) =>
      data
        .filter((el) => el.keyName === fieldName)
        .sort((a, b) => (a.status < b.status ? -1 : 1));
    const createDrinksData = () => {
      const updatedHealthData = healthData.filter(
        (el) => el.waterIntake || el.coffeeIntake || el.softDrinkIntake,
      );
      const waterRec = filterData(updatedHealthData, DRINK_NAME.water);
      const coffeeRec = filterData(updatedHealthData, DRINK_NAME.coffee);
      const sodaRec = filterData(updatedHealthData, DRINK_NAME.softDrink);
      const currentWaterIntake =
        waterRec.reduce((acc, curr) => acc + curr.waterIntake, 0) +
        coffeeRec.reduce((acc, curr) => acc + curr.coffeeIntake, 0) +
        sodaRec.reduce((acc, curr) => acc + curr.softDrinkIntake, 0);
      const waterLength =
        waterRec.length >= DRINK_LIMIT.water.initial
          ? DRINK_LIMIT.water.max - waterRec.length
          : DRINK_LIMIT.water.initial - waterRec.length;
      const coffeeLength =
        coffeeRec.length >= DRINK_LIMIT.coffee.initial
          ? DRINK_LIMIT.coffee.max - coffeeRec.length
          : DRINK_LIMIT.coffee.initial - coffeeRec.length;
      const sodaLength =
        sodaRec.length >= DRINK_LIMIT.softDrink.initial
          ? DRINK_LIMIT.softDrink.max - sodaRec.length
          : DRINK_LIMIT.softDrink.initial - sodaRec.length;
      waterRec.push(...pushArr(waterLength, 'water', 'waterVolume'));
      coffeeRec.push(...pushArr(coffeeLength, 'coffee', 'coffeeVolume'));
      sodaRec.push(...pushArr(sodaLength, 'softDrink', 'softDrinkVolume'));
      setWater(waterRec);
      setCoffee(coffeeRec);
      setSoda(sodaRec);
      setCurrentWaterIntake(currentWaterIntake);
    };

    const pushArr = (len, nameKey, volumeKey) => {
      return Array.apply(null, Array(len)).map((el, i) => {
        el = new Drinks({
          name: DRINK_NAME[nameKey],
          volume:
            volumeData && volumeData[volumeKey]
              ? volumeData[volumeKey]
              : DEFAULT_DRINK_INTAKE[volumeKey],
        });
        if (i === 0) {
          el.status = DRINK_STATUS.add;
        }
        return el;
      });
    };

    const handleHalfCase = (data, halfRecIndex, elem) => {
      data.splice(halfRecIndex, 1);
      data.push(new Drinks({ name: elem.keyName }));
      data[halfRecIndex].status = DRINK_STATUS.add;
      if (halfRecIndex === DRINK_LIMIT[elem.keyName].max / 2 - 1) {
        data.splice(halfRecIndex + 1, data.length);
      }
    };

    const getDrinkDataAndMethod = (elem) => {
      const data =
        elem.keyName === DRINK_NAME.water
          ? water
          : elem.keyName === DRINK_NAME.coffee
            ? coffee
            : softDrink;
      const setData =
        elem.keyName === DRINK_NAME.water
          ? setWater
          : elem.keyName === DRINK_NAME.coffee
            ? setCoffee
            : setSoda;
      return { data, setData };
    };

    /**
     * function to handle drinks click event to change status full/half/empty/add
     * @param {*} elem
     * @param {*} index
     */
    const handleDrinkClick = (elem, index) => {
      const { data, setData } = getDrinkDataAndMethod(elem);
      let payload = {};
      let isDelete = false;
      let id = '';
      const intakeName = DRINKS_PAYLOAD_MAPPER[elem.keyName].intake;
      const levelName = DRINKS_PAYLOAD_MAPPER[elem.keyName].level;
      const volumeName = DRINKS_PAYLOAD_MAPPER[elem.keyName].volume;
      const volume =
        volumeData && volumeData[volumeName]
          ? volumeData[volumeName]
          : DEFAULT_DRINK_INTAKE[volumeName];

      if (elem.status !== DRINK_STATUS.empty) {
        const halfRecIndex = data.findIndex(
          (el) => el.status === DRINK_STATUS.half,
        );
        if (index < data.length - 1) {
          if (elem.status === DRINK_STATUS.add) {
            if (halfRecIndex > -1) {
              id = data[halfRecIndex].id;
              data.splice(data.length - 1, 1);
              data.splice(
                halfRecIndex,
                0,
                new Drinks({
                  name: elem.keyName,
                  status: DRINK_STATUS.full,
                  [intakeName]: volume,
                  [levelName]: DRINK_STATUS.full,
                }),
              );
              payload = {
                [levelName]: DRINK_STATUS.full,
                [intakeName]: volume,
              };
            } else {
              elem.status = DRINK_STATUS.full;
              elem[intakeName] = volume;
            }
            data[index + 1].status = DRINK_STATUS.add;
          } else if (elem.status === DRINK_STATUS.full) {
            if (halfRecIndex > -1) {
              id = data[halfRecIndex].id;
              handleHalfCase(data, halfRecIndex, elem);
              isDelete = true;
            } else {
              const fullRec = data.filter(
                (el) => el.status === DRINK_STATUS.full,
              );
              data[fullRec.length - 1].status = DRINK_STATUS.half;
              data[fullRec.length - 1][intakeName] = volume / 2;

              payload = {
                [intakeName]: volume / 2,
                [levelName]: DRINK_STATUS.half,
              };
              id = data[fullRec.length - 1].id;
            }
          } else if (elem.status === DRINK_STATUS.half) {
            id = data[halfRecIndex].id;
            handleHalfCase(data, halfRecIndex, elem);
            isDelete = true;
          }
        } else {
          if (data.length === DRINK_LIMIT[elem.keyName].initial) {
            if (elem.status === DRINK_STATUS.add) {
              if (halfRecIndex > -1) {
                id = data[halfRecIndex].id;
                data.splice(data.length - 1, 1);
                data.splice(
                  halfRecIndex,
                  0,
                  new Drinks({
                    name: elem.keyName,
                    status: DRINK_STATUS.full,
                    [intakeName]: volume,
                    [levelName]: DRINK_STATUS.full,
                  }),
                );
                payload = {
                  [levelName]: DRINK_STATUS.full,
                  [intakeName]: volume,
                };
              } else {
                elem.status = DRINK_STATUS.full;
                elem[intakeName] = volume;
              }
              pushData(data, elem, DRINK_LIMIT[elem.keyName].initial);
            }
          } else {
            if (elem.status === DRINK_STATUS.add) {
              if (halfRecIndex > -1) {
                id = data[halfRecIndex].id;
                data.splice(data.length - 1, 1);
                data.splice(
                  halfRecIndex,
                  0,
                  new Drinks({
                    name: elem.keyName,
                    status: DRINK_STATUS.full,
                    [intakeName]: volume,
                    [levelName]: DRINK_STATUS.full,
                  }),
                );
                payload = {
                  [levelName]: DRINK_STATUS.full,
                  [intakeName]: volume,
                };
              } else {
                elem.status = DRINK_STATUS.full;
                elem[intakeName] = volume;
              }
            } else if (data.length === DRINK_LIMIT[elem.keyName].max) {
              if (elem.status === DRINK_STATUS.full) {
                elem.status = DRINK_STATUS.half;
                elem[intakeName] = volume / 2;
                payload = {
                  [intakeName]: volume / 2,
                  [levelName]: DRINK_STATUS.half,
                };
                id = elem.id;
              } else {
                elem.status = DRINK_STATUS.add;
                id = elem.id;
                isDelete = true;
              }
            }
          }
        }
      }

      setLoading(true);
      let elIndex = -1;
      const healthDataCopy = JSON.parse(JSON.stringify(healthData));
      if (healthDataCopy.length && id) {
        elIndex = healthDataCopy.map(el => el.id).indexOf(id);
      }
      if (isDelete) {
        dispatch(deleteLog(id)).then(() => {
          if (elIndex > -1) {
            healthDataCopy.splice(elIndex, 1);
          }
          dispatch(updateHealthData(healthDataCopy));
        }).finally(() => setLoading(false));
        setData([...data]);
      } else if (payload[levelName] === DRINK_STATUS.half) {
        dispatch(changeWaterLogs(id, payload)).then((res) => {
          if (elIndex > -1) {
            healthDataCopy[elIndex] = { ...healthDataCopy[elIndex], ...res };
          }
          dispatch(updateHealthData(healthDataCopy));
        }).finally(() => setLoading(false));
        setData([...data]);
      } else {
        payload = {
          [intakeName]: volume,
        };
        if (isWatergoalCalculated && waterGoalData) {
          payload = {
            ...payload,
            suggestedWater: Math.round(waterGoalData),
          };
        }
        dispatch(saveWaterLogs(payload)).then(res => {
          healthDataCopy.push({ ...res });
          dispatch(updateHealthData(healthDataCopy));
        }).finally(() => setLoading(false));
      }
    };

    const pushData = (arr, elem, length) => {
      arr.push(
        ...Array.apply(arr, Array(length)).map((el, i) => {
          el = new Drinks({ name: elem.keyName });
          if (i === 0) {
            el.status = DRINK_STATUS.add;
          }
          return el;
        }),
      );
    };

    const getCurrentWaterIntake = () => {
      return (
        water.reduce((acc, curr) => acc + curr.waterIntake, 0) +
        coffee.reduce((acc, curr) => acc + curr.coffeeIntake, 0) +
        softDrink.reduce((acc, curr) => acc + curr.softDrinkIntake, 0)
      );
    };

    const handleVolumeEdit = (drinkName) => {
      let defaultValue = {};
      let selections = {};
      if (drinkName === DRINK_NAME.water || drinkName === DRINK_NAME.coffee) {
        defaultValue = {
          [drinkName]:
            volumeData && volumeData[DRINKS_PAYLOAD_MAPPER[drinkName].volume],
        };

        selections[drinkName] = WATER_VOLUME_LIST;
      } else {
        defaultValue = { [drinkName]: volumeData.softDrinkIntake };
        selections[drinkName] = SOFT_DRINK_VOLUME_LIST;
      }
      setSelections(selections);
      setDefaultValue(defaultValue);
      setOpenModal(true);
    };

    const handleOKClick = (selectedValue) => {
      const selectedKey = Object.keys(selectedValue)[0];
      let payload = {
        [DRINKS_PAYLOAD_MAPPER[selectedKey].volume]:
          selectedValue[selectedKey],
      };
      setLoading(true);
      if (volumeData?.id) {
        dispatch(
          updateWaterVolume(
            customerId,
            volumeData.id,
            selectedKey,
            payload,
          ),
        ).finally(() => setLoading(false));
      } else {
        dispatch(saveWaterVolume(payload)).finally(() => setLoading(false));
      }
      setOpenModal(false);
    };

    return (
      <div
        ref={ref}
        className={`food-overview-drink-container${openModal ? ' --no_scroll' : ''
          }`}
      >
        <div className="food-overview-drink-heading-container">
          <div className="food-overview-drink-heading-left">การดื่มน้ำ</div>
          <div className="food-overview-drink-heading-right">
            <span className="food-overview-drink-heading-consume">
              {currentWaterIntake ? currentWaterIntake.toLocaleString() : '-'}
            </span>
            <span className="food-overview-drink-heading-separtor">/</span>
            <span className="food-overview-drink-heading-overall-value">
              {waterGoalData ? waterGoalData.toLocaleString() : '-'}
            </span>
            <span className="food-overview-drink-heading-unit">
              {' '}
              {HNW_FOOD_LOG.labelMapping['waterQuantityUnit']}
            </span>
          </div>
        </div>
        <div
          className="food-overview-drink-progress"
          role="progressbar"
          aria-valuenow="0"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <HealthHomeProgressBar
            type="water"
            value={currentWaterIntake}
            maxValue={waterGoalData}
          />
        </div>
        <div className="food-overview-drink-card-container">
          <FoodLogDrinksWrapper
            data={water}
            header={HNW_FOOD_LOG.labelMapping['waterCardHeader']}
            volumeData={waterVolume}
            label={HNW_FOOD_LOG.labelMapping['waterQuantityUnit']}
            drinkName={DRINK_NAME.water}
            handleVolumeEdit={handleVolumeEdit}
          >
            <FoodLogBeverages
              handleDrinkClick={handleDrinkClick}
              data={water}
              drinkName={DRINK_NAME.water}
            />
          </FoodLogDrinksWrapper>
          <FoodLogDrinksWrapper
            data={coffee}
            header={HNW_FOOD_LOG.labelMapping['coffeeCardHeader']}
            volumeData={coffeeVolume}
            label={HNW_FOOD_LOG.labelMapping['waterQuantityUnit']}
            drinkName={DRINK_NAME.coffee}
            cautionMsg={'ไม่ควรดื่มเกิน 3 แก้ว / วัน'}
            handleVolumeEdit={handleVolumeEdit}
          >
            <FoodLogBeverages
              handleDrinkClick={handleDrinkClick}
              data={coffee}
              drinkName={DRINK_NAME.coffee}
            />
          </FoodLogDrinksWrapper>
          <FoodLogDrinksWrapper
            data={softDrink}
            header={HNW_FOOD_LOG.labelMapping['sodaCardHeader']}
            volumeData={softDrinkVolume}
            label={HNW_FOOD_LOG.labelMapping['waterQuantityUnit']}
            drinkName={DRINK_NAME.softDrink}
            cautionMsg={'ไม่ควรดื่มเกิน 3 กระป๋อง / วัน'}
            handleVolumeEdit={handleVolumeEdit}
          >
            <FoodLogBeverages
              handleDrinkClick={handleDrinkClick}
              data={softDrink}
              drinkName={DRINK_NAME.softDrink}
            />
          </FoodLogDrinksWrapper>
        </div>

        {openModal && (
          <MobilePicker
            isOpen={openModal}
            defaultValue={defaultValue}
            selections={selections}
            onOKClick={handleOKClick}
          />
        )}
      </div>
    );
  },
);

export default FoodLogDrinks;
