// multi-bar.js
import React, { useRef } from 'react';
import Chart from 'react-apexcharts';
import { CALENDAR_DATA_LABELS, GRAPH_TYPE, LABEL_EN_DAY_CALENDAR_TAB, LABEL_EN_WEEK_CALENDAR_TAB } from '../../../../common/commonConstant';
import variables from '../../../../common/commonConstant.scss';
import '../GraphStyle.scss';

const MultiBar = ({
  graphType,
  type,
  xAxisFormatter,
  min,
  max,
  xAxisCategories,
  yAxisCategories,
  onMouseMove = () => { },
  highlightedLabel,
}) => {

  const highlightedLabelRef = useRef(highlightedLabel);
  highlightedLabelRef.current = highlightedLabel;

  const handleChartEvent = (event, chartContext, config) => {
    if (config?.dataPointIndex > -1) {
      const series = chartContext.w.config.series.map(item => ({ [item.name]: item.data }));
      const data = series.reduce((acc, value) => ({ ...acc, ...value }), {});
      onMouseMove(data, config?.dataPointIndex);
      const labels = chartContext.el.querySelectorAll('.apexcharts-xaxis-texts-g text');
      labels.forEach((label) => {
        if (label.lastChild.innerHTML === highlightedLabelRef.current) {
          label.style.fill = variables.themePrimary;
        }
        else {
          label.style.fill = variables.homegrey;
        }
      });
    }
  };

  const options = {
    chart: {
      id: 'amway-food-exercise-water-multi-bar-graph',
      foreColor: '#cbd2e0',
      type: graphType,
      toolbar: {
        autoSelected: 'pan',
        show: false,
      },
      events: {
        mouseMove: handleChartEvent,
        markerClick: handleChartEvent,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: type === LABEL_EN_WEEK_CALENDAR_TAB ? 6 : 7,
        endingShape: 'rounded',
        columnWidth: yAxisCategories.length === 1 ? '35%' : '70%',
      },
    },
    stroke: {
      width: graphType === GRAPH_TYPE.LINE ? 5 : 1,
      lineCap: 'round',
    },
    grid: {
      borderColor: variables.themePrimary,
      strokeDashArray: 4, // Length of dashes in the vertical grid lines
      position: 'back', // Position of the grid lines
      xaxis: {
        lines: {
          show: true, // Show vertical grid lines on the X-axis
        },
      },
      yaxis: {
        lines: {
          show: false, // Hide the horizontal grid lines on the Y-axis
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 5,
      colors: [variables.markerbg],
      strokeColor: variables.themePrimary,
      strokeWidth: 3,
    },
    tooltip: {
      x: {
        show: true, // Hide default tooltip value
      },
      y: {
        title: {
          formatter: () => '',
        },
      },
      custom: function () {
        return '';
      },
    },
    fill: {
      opacity: 1,
      type: "gradient",
      gradient: {
        type: graphType === GRAPH_TYPE.LINE ? 'horizontal' : "vertical",
        gradientToColors: yAxisCategories.map(({ color2 }) => color2),
      }
    },
    xaxis: {
      categories: xAxisCategories,
      axisBorder: {
        show: false, // Hide the x-axis line
      },
      crosshairs: {
        show: true,
        width: 1,
        position: 'front',
        opacity: 0.9,
        stroke: {
          width: 2,
          dashArray: 3,
          color: variables.graphgreen,
        },
      },
      tooltip: {
        enabled: true,
        offsetY: -135,
        formatter: () => ' ',
      },
      tickPlacement: 'between', // Place ticks on the axis,
      offsetX: type === LABEL_EN_WEEK_CALENDAR_TAB ? -12 : -16,
      offsetY: -10,
      labels: {
        style: {
          colors: '#A0ABC0',
          lineHeight: '15px',
          fontSize: '12px',
          fontFamily: 'Sukhumvit Tadmai',
          fontWeight: 700,
        },
      },
    },
    yaxis: {
      min: 0,
      labels: {
        style: {
          colors: '#A0ABC0',
          lineHeight: '15px',
          fontSize: '12px',
          fontFamily: 'Sukhumvit Tadmai',
          fontWeight: 700,
        },
        formatter: (value) => `${parseInt(value)}`,
      },
      tickAmount: 3,
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: true,
        color: '#80D26C',
      },
      opposite: true,
    },
    legend: {
      show: false,
    },
  };

  let singleDataClass = '';
  if (yAxisCategories.length === 1) {
    const dataLength = yAxisCategories[0].data.filter((dataItem) => dataItem.y !== null).length;
    singleDataClass = dataLength === 1 ? ' single-data-list' : '';
  }

  const seriesCountClass = yAxisCategories.length===2?' --two-series':'';

  return (
    <div className={`graph-style${singleDataClass}${seriesCountClass}`}>
      <Chart options={options} series={yAxisCategories} height={200} width="95%" />
    </div>
  );
};
export default MultiBar;
