import React from 'react';
import './AutoSyncOtpVerification.scss';
import { AUTO_SYNC_MODAL_THAI } from '../../challengeConstants';

export default function AutoSyncOtpVerification({
  bodyKeyId,
  bodyKeyOtp,
  onChangeHandler,
  onClose,
  onVerify,
  validationFailed,
}) {
  return (
    <div className="auto-sync-body">
      <div className="auto-sync-body-title">
        {AUTO_SYNC_MODAL_THAI.OTP_TITLE}
      </div>
      <div className="auto-sync-body-info">
        {AUTO_SYNC_MODAL_THAI.OTP_DETAIL(bodyKeyId).map((support) => (
          <span key={support}>
            {support}
            <br />
          </span>
        ))}
      </div>
      <div className="auto-sync-body-input-field">
        <input
          className={`auto-sync-input-box`}
          value={bodyKeyOtp}
          onChange={onChangeHandler}
          name="bodyKeyOtp"
          inputMode="tel"
          type="text"
        />
      </div>
      <div className="auto-sync-body-action-box">
        <button className="btn btn-outline-black" onClick={onClose}>
          {AUTO_SYNC_MODAL_THAI.OTP_CANCEL_BTN}
        </button>
        <button
          className="btn btn-green btn-green-linear"
          disabled={!bodyKeyId || validationFailed}
          onClick={() => {
            if (!validationFailed) {
              onVerify();
            }
          }}
        >
          <span className="text-color-white">
            {AUTO_SYNC_MODAL_THAI.SUCCESS_BTN}
          </span>
        </button>
      </div>
    </div>
  );
}
