import {
  CREATE_EXERCISE_LOG_TYPE,
  UDPATE_EXERCISE_LOG_TYPE,
  GET_EXERCISE_CATEGORY_LIST_TYPE,
  GET_EXERCISE_MENU_LIST_TYPE,
  GET_EXERCISE_TIMELOG_LIST_TYPE,
  GET_EXERCISE_LOG_BY_ID_TYPE,
  GET_PREV_EXERCISE_LOG_BY_ID_TYPE,
  DELETE_EXERCISE_LOG_TYPE,
  GET_EXERCISE_MENU_AND_TEMPLATES_TYPE,
  GET_SUGGESTED_EXERCISE_MENU_LIST_TYPE,
  GET_USER_ACTIVITY_GOAL,
} from '../actions/types';
import { ExerciseLog, ExerciseLogMenu } from '../model/ExerciseLog.model';

const INITIAL_STATE = {
  isLoading: false,
  exerciseLogData: new ExerciseLog(),
  exerciseTimeLogList: [],
  exerciseCategoryList: [],
  exerciseMenuList: [],
  suggestedExerciseMenuList: [],
  exerciseTemplateList: [],
  exerciseMenu: new ExerciseLogMenu(),
  userActivities: {
    exerciseCategory: [],
    exerciseTime: null,
    id: '',
    targetCalories: null,
  },
  error: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_EXERCISE_LOG_TYPE:
      return { ...state, exerciseLogData: action.payload };
    case UDPATE_EXERCISE_LOG_TYPE:
      return { ...state, exerciseLogData: action.payload };
    case GET_EXERCISE_CATEGORY_LIST_TYPE:
      return { ...state, exerciseCategoryList: action.payload };
    case GET_EXERCISE_MENU_LIST_TYPE:
      return { ...state, exerciseMenuList: action.payload };
    case GET_SUGGESTED_EXERCISE_MENU_LIST_TYPE:
      return { ...state, suggestedExerciseMenuList: action.payload };
    case GET_EXERCISE_MENU_AND_TEMPLATES_TYPE:
      return {
        ...state,
        exerciseMenu: action?.payload || new ExerciseLogMenu(),
      };
    case GET_EXERCISE_TIMELOG_LIST_TYPE:
      return { ...state, exerciseTimeLogList: action.payload };
    case GET_USER_ACTIVITY_GOAL:
      return { ...state, userActivities: action.payload };
    case GET_EXERCISE_LOG_BY_ID_TYPE:
      return { ...state, exerciseLogData: action.payload };
    case GET_PREV_EXERCISE_LOG_BY_ID_TYPE:
      return state;
    case DELETE_EXERCISE_LOG_TYPE:
      return state;
    default:
      return state;
  }
}
