import React from 'react';
import './hnwHomeProfile.scss';
import { routesMap } from '../../../../common/commonConstant';
import { PROFILEMENU } from '../../constants/home-profile-menu.constant';
import { bmiClassification } from '../../../../services';

const Profile = ({ bmiData, routeTo }) => {
  const activeClassWrapper = {
    1: 'blue-text',
    2: 'green-text',
    3: 'yellow-text',
    4: 'orange-text',
    5: 'red-text'
  }

  const activeClass = bmiData && bmiData.bmi ? bmiClassification(bmiData.bmi, activeClassWrapper) : '';

  return (
    <div className="row profile-container">
      <div className="col-12">
        <div className="container-fluid">
          <h6 className="banner-title">สุขภาพของคุณล่าสุด</h6>
        </div>
      </div>
      <div className="col-6">
        <div className="container-fluid">
          <div className="health-summary-menu-container">
            {
              PROFILEMENU && PROFILEMENU.length ? PROFILEMENU.map((item, index) => (
                <div key={`profile${index}`} onClick={() => routeTo(routesMap[item.key])} className="health-summary-menu-input-wrapper">
                  <img src={`/images/healthAndWellness/icon/${item.image}`} />
                  <div className="health-summary-menu-section">
                    <div className="health-summary-menu-input-container">
                      {
                        bmiData && bmiData[item.key] ?
                          item.key === 'bmi' ?
                            <span className={`input-label ${activeClass}`}>
                              {bmiData[item.key]}
                            </span> :
                            <span className='input-label'>
                              {bmiData[item.key]}
                            </span>
                          : item.key !== 'bmi' ? <div className='input-label'>-</div> : ''
                      }
                      {(!bmiData || !bmiData[item.key]) && item.key === 'bmi' ? <div className='input-label bmi-label'>
                        กรุณาระบุน้ำหนัก
                      </div> :
                        <div className="health-summary-menu-label">
                          {item.unit}
                        </div>}
                    </div>
                    {
                      item.name ? <div className="health-summary-menu-subtitle">{item.name}</div> : null
                    }
                  </div>
                  <div className="health-summary-menu-arrow-section">
                    {
                      routesMap[item.key] ?
                        <a className="arrow-right-icon">
                          <img src="/images/healthAndWellness/icon/arrow-right.svg" />
                        </a> : null
                    }
                  </div>
                </div>
              ),
              ) : null
            }
          </div>
        </div>
      </div>
      <div className="col-6" />
    </div>
  );
};

export default Profile;
