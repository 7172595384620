import config from '../../config';
import { ChallengeBodyKeyRecord, ChallengeBodyKeyStatus } from '../../model/ChallengeBodyKey.model';
import AxiosInterceptor from '../axiosInterceptor.service';

export const verifyBodyKeyId = async (bodyKeyId) => {
  try {
    const response = await AxiosInterceptor.get(
      `${config.API_URL}/challenge-external/body-data/app-settings?bodyKeyId=${bodyKeyId}`,
    );
    return new ChallengeBodyKeyStatus(response?.data);
  } catch (error) {
    throw error;
  }
};

export const getBodyKeyRecord = async ({ startDate, endDate, bodyKeyId }) => {
  try {
    const response = await AxiosInterceptor.get(
      `${config.API_URL}/challenge-external/body-data/exercise?startDate=${startDate}&endDate=${endDate}&bodyKeyId=${bodyKeyId}`,
    );
    const bodyKeyRecord = new ChallengeBodyKeyRecord(response?.data)
    sessionStorage.setItem("stepCount", JSON.stringify(bodyKeyRecord?.data[0]?.stepCount || 0))
    return bodyKeyRecord;
  } catch (error) {
    throw error;
  }
};
